import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';

@Pipe({
    name: 'minutes'
})
export class MinutesPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): string {
        return Utils.minutes(value);
    }

}
