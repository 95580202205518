<div class="controls">
    <div class="header">
        <app-header (clicked)="setCenterDate()"
                    [mainTitle]="environment.isCodalt ? 'Planning Codalt' : 'Planning DvdS'"
                    [subTitle]="environment.isCodalt ? 'Overzicht' : 'Frees- en transport'">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector flex-column d-flex" *ngIf="!print && !mobile">
            <app-planning-filter (selectedTeams)="selectedTeamsChange($event)"></app-planning-filter>
        </div>
        <div class="show-in-use" *ngIf="!print">
            <mat-checkbox [formControl]="fcShowSmallMaterials" class="mr-3">Pers/mat</mat-checkbox>
            <mat-checkbox [formControl]="fcShowInUse" class="mr-3">Bezetting</mat-checkbox>
            <mat-checkbox [formControl]="fcShowComments">Kladblok</mat-checkbox>
        </div>
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [print]="print"
                            [singleDate]="currentDate">
        </app-date-indicator>
    </div>
    <div class="print-pdf" *ngIf="!print">
        <button (click)="pdf()" color="primary" mat-icon-button>
            <i class="fa-light fa-file-pdf" *ngIf="!downloadingPdf"></i>
            <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
        </button>
    </div>
    <div class="actions" *ngIf="!print">
        <app-settings-menu (selectedTeams)="selectedTeamsChange($event)"></app-settings-menu>
    </div>
    <div class="actions" *ngIf="print">
        PDF datum: {{now | date: 'd MMMM yyyy HH:mm'}}
    </div>
</div>
<div class="text-container">
    <div class="overview-and-side" [class.show-in-use]="fcShowInUse.value" cdkDropListGroup>

        <div class="overview">
            <div class="legend">
                <div *ngIf="!planningForms" class="loading">
                    Laden...
                </div>
                <div *ngIf="planningForms && !dayIsFixed" class="loading">
                    Deze dag is nog niet vastgezet in de weekplanning
                </div>
                <div *ngIf="dayIsFixed && planningForms?.length === 0" class="loading">
                    Er zijn geen defintieve planningen voor deze dag
                </div>
            </div>

            <ng-container *ngFor="let main of planningForms">
                <table class="table table-center" [class.table-add-row]="!print" *ngIf="dayIsFixed"
                       #myDropList
                       cdkDropList
                       cdkDropListLockAxis="y"
                       cdkDropListOrientation="vertical"
                       (cdkDropListDropped)="onDrop($event, main)">
                    <tr class="invisible">
                        <td [style.width.px]="30"></td>
                        <td [style.width.px]="200"></td>
                        <td [style.width.px]="100"></td>
                        <td [style.width.px]="100"></td>
                        <td colspan="5"></td>
                        <td [style.width.px]="150"></td>
                        <td [style.width.px]="150"></td>
                        <td colspan="2"></td>
                    </tr>
                    <tr [class.red]="main.planning.status_id !== PlanningStatus.definitief">
                        <th colspan="14"
                            class="team"
                            *ngIf="main.planning.is_transport">
                            TRANSPORT
                            <small>diversen</small>
                        </th>
                        <ng-container *ngIf="!main.planning.is_transport">
                            <th colspan="2"
                                class="project-id">
                                {{main.planning.afas_project_id}}
                            </th>
                            <th colspan="5"
                                class="team"
                                *ngIf="main.hasSets || (!main.planning.performer && !main.planning.asphalt_performer)"
                                (dblclick)="openPlanning(main.planning)">
                                {{EntityTypeMap.get(main.mainPlanning.entitytype)?.name}}
                                {{main.mainPlanning?.entity?.name}}
                                <small>{{(executors | byId: main.planning.asphalt_performer)?.name}}
                                    / {{(executors | byId: main.planning.performer)?.name}}</small>
                            </th>
                            <th colspan="4"
                                *ngIf="!main.hasSets && (main.planning.performer || main.planning.asphalt_performer)"
                                (dblclick)="openPlanning(main.planning)">
                                {{(executors | byId: main.planning.performer)?.name}}
                                <small>{{(executors | byId: main.planning.asphalt_performer)?.name}}</small>
                            </th>
                            <th *ngIf="main.hasSets || true">
                                {{main.mainPlanning?.begindate | date : 'EEE HH:mm'}}
                            </th>
                            <th *ngIf="main.hasSets || true">
                                {{main.mainPlanning?.enddate | date : 'HH:mm'}}
                            </th>
                            <th colspan="6" class="main-comment" [matTooltip]="main.planning.description">
                                {{main.planning.description}}
                            </th>
                        </ng-container>
                        <th width="300" *ngIf="main.planning.is_transport || fcShowSmallMaterials.value"></th>
                    </tr>
                    <tr *ngIf="main.planning.status_id == PlanningStatus.definitief">
                        <th [style.width.px]="50" colspan="1"></th>
                        <th [style.width.px]="300" colspan="1">Chauffeur</th>
                        <th [style.width.px]="150" colspan="2">Materieel</th>
                        <th [style.width.px]="100">Auto</th>
                        <th [style.width.px]="100">Dieplader</th>
                        <th [style.width.px]="100">Aanvang</th>
                        <th [style.width.px]="100">Tot</th>
                        <th [style.width.px]="40">Start</th>
                        <th [style.width.px]="300">Van</th>
                        <th [style.width.px]="300">Naar</th>
                        <th [style.width.px]="40">Stop</th>
                        <th [style.min-width.px]="300">Werkzaamheden</th>
                        <th [style.min-width.px]="100">Uitvoerder</th>
                        <th [style.width.px]="200" *ngIf="main.planning.is_transport">Project</th>
                        <td [style.min-width.px]="250" [attr.rowspan]="main.entities | entitiesRows" class="employees-equipment"
                            *ngIf="!main.planning.is_transport && this.fcShowSmallMaterials.value">
                            <div>
                                <div (click)="openEmployees(main.planning)" class="curpoint">
                                    <b>Personeel</b><br>
                                    <div *ngFor="let userPlanning of main.planning.user_planning" class="d-flex">
                                        <div class="ellipsis">
                                            {{userPlanning?.user?.name}}
                                        </div>
                                        <div class="col-auto pl-1 pr-0">
                                            <ng-container *ngIf="userPlanning?.begindate | isOtherDate : this.currentDate">
                                                {{userPlanning?.begindate | date : 'EE d MMM'}}
                                            </ng-container>
                                            {{userPlanning?.begindate | date : 'H:mm'}} {{userPlanning?.enddate | date : 'HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                                <div (click)="openEquipment(main.planning)" class="curpoint">
                                    <b>Klein materieel</b><br>
                                    <div *ngFor="let phe of main.planning.planning_has | entityType : 'visible_small' : true" class="d-flex">
                                        <div class="ellipsis">
                                            {{phe?.entity?.name}}
                                        </div>
                                        <div class="col-auto pl-1 pr-0">
                                            <ng-container *ngIf="phe?.begindate | isOtherDate : this.currentDate">
                                                {{phe?.begindate | date : 'EE d MMM'}}
                                            </ng-container>
                                            {{phe?.begindate | date : 'H:mm'}} {{phe?.enddate | date : 'HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>


                    <ng-container *ngFor="let pln of main.entities; let i = index;">
                        <tr app-transport-planning-row
                            [pln]="pln.planning"
                            [fg]="pln.fg"
                            [main]="main"
                            cdkDrag
                            cdkDragPreviewClass="cdk-drag-preview"
                            cdkDragLockAxis="y"
                            [cdkDragData]="pln"
                            [class.highlight]="pln.highlight"
                            [class.selected]="pln.selected"
                            class="contents"
                            [highlight]="false"
                            [i]="i"
                            [drivers]="drivers"
                            [lowLoaders]="lowLoaders"
                            [employees]="employees"
                            [executors]="executors"
                            [startTimesEntity]="startTimesEntity"
                            (addChooseEntity)="addChooseEntity($event)"
                            (deletePlanningEmitter)="deletePlanning($event)"
                            (duplicatePlanningEmitter)="duplicatePlanning($event)"
                            [endTimesEntity]="endTimesEntity"
                            [entitiesMap]="entitiesMap"
                            [planning]="planning"
                            [currentDate]="currentDate"
                            [entityTypes]="entityTypes"
                            [hiringMap]="hiringMap"
                            [trucks]="trucks"
                        ></tr>
                    </ng-container>

                    <tr class="add-row" *ngIf="!print">
                        <td colspan="15" *ngIf="main.planning.status_id !== PlanningStatus.definitief">
                            <button class="w-100" color="secondary" mat-button (click)="openPlanning(main.planning)">
                                Deze planning is nog niet definitief
                            </button>
                        </td>
                        <ng-container *ngIf="main.planning.status_id === PlanningStatus.definitief">
                            <td colspan="8" *ngIf="main.cutterTrucks+main.asphaltTrucks > 0">
                                <button (click)="addTrucks(main)" class="w-100" color="primary" mat-button>
                                    {{main.cutterTrucks + main.asphaltTrucks}} vrachtwagens toevoegen <i class="fas fa-plus"></i>
                                </button>
                            </td>
                            <td [attr.colspan]="main.cutterTrucks+main.asphaltTrucks > 0 ? 7 : 14">
                                <button (click)="addChooseType(main)" class="w-100" color="primary" mat-button>
                                    Regel toevoegen <i class="fas fa-plus"></i>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </table>
            </ng-container>

        </div>
        <app-transport-in-use [currentDate]="currentDate"></app-transport-in-use>
    </div>
    <app-transport-planning-text *ngIf="fcShowComments.value"></app-transport-planning-text>
</div>

