<div class="modal-inner">
    <div class="modal-inner-inner w-100">
        <h1 class="mb-0">{{data.day | date : 'EEEE d MMMM'}}</h1>
        <h2>
            {{data.user?.name}}
        </h2>
        <div class="plannings">
            <ng-container *ngIf="data.leave">
                <b>Verlof</b>
                <div class="d-flex align-items-center">
                    <div class="flex-fill">
                        <i class="{{hourtypeIconMap.get(data.leave.hourtype)}}"></i>
                        {{data.leave.begindate | date : 'HH:mm'}} t/m {{data.leave.enddate | date : 'HH:mm'}}
                        {{data.leave.hourtype | hourtype}} {{data.leave.comment}}
                    </div>
                    <div>
                        <button mat-icon-button color="warn" (click)="deleteLeave(data.leave)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data.afasLeave">
                <b>Verlof (Afas)</b>
                <div>
                    <i class="fa-regular {{data.afasLeave.icon}}"></i> {{data.afasLeave.description}}
                    Vanaf: {{data.afasLeave.startDate | date : 'd MMMM HH:mm'}}{{data.afasLeave.endDate ? ' t/m ' : ''}}{{data.afasLeave.endDate | date : 'd MMMM HH:mm'}}
                </div>
            </ng-container>
            <b *ngIf="data.userPlanning?.length || data.planningHas?.length">Planning</b>
            <div *ngFor="let planning of data.userPlanning" class="d-flex">
                <div>
                    <i class="fa-light"
                       [class.fa-user-helmet-safety]="planning.afas_project_id"
                       [class.fa-users]="!planning.afas_project_id"></i>
                    {{planning.begindate | date : 'HH:mm'}} t/m {{planning.enddate | date : 'HH:mm'}}
                    - {{planning.afas_project_id ?? planning?.planning?.afas_project_id}}
                    ({{planning.planning?.project?.name ?? planning?.project?.name}})
                    <a (click)="planningDetailDialogService.openPlanning(planning.planning)"
                       *ngIf="!planning.afas_project_id" target="_blank">(asfaltplanning)</a>
                    <span *ngIf="planning.afas_project_id">(personeelsplanning)</span>
                </div>
                <div>
                    <button mat-icon-button color="warn" (click)="deleteUserPlanning(planning)">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>
            <div *ngFor="let planning of data.planningHas" class="d-flex">
                <div>
                    <i class="fa-light fa-truck"></i>
                    {{planning.begindate | date : 'HH:mm'}} t/m {{planning.enddate | date : 'HH:mm'}}
                    - {{planning.afas_project_id ?? planning?.planning?.afas_project_id}}
                    ({{planning.planning?.project?.name ?? planning?.project?.name}})
                    <a href="/transport-planning/{{planning.begindate | date : 'yyyy-MM-dd'}}" target="_blank">(transportplanning)</a>
                </div>
                <div>
                    <button mat-icon-button color="warn" (click)="deletePlanningHas(planning)">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons mt-3">
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Sluiten</button>
    </div>
</div>
