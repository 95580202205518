<div *ngIf="firstDate">

</div>
<div *ngFor="let ton of tons?.tons"
     [class.small]="ton.delivery < 500"
     [class.normal]="ton.delivery >= 500 && ton.delivery <= 800"
     [class.high]="ton.delivery > 800 && ton.delivery <= 1000"
     [class.overflow]="ton.delivery > 1000"
     [class.print]="print"
     [style.height.%]="100">

        {{ton.delivery | number:'1.0-0'}}

</div>
<div *ngIf="lastDate">

</div>
