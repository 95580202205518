import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Settlement} from '../classes/settlement.class';
import {Hourtype} from '../afas-classes/hourtype';
import {formatDate} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SettlementsService {

    nonTimeHourTypes = ['CRW'];

    constructor(protected apiService: ApiService) {

    }

    getUserSettlements(beginDate: Date, endDate: Date, userId: number) {
        const params = {
            beginDate: new Date(beginDate).toISOString(),
            endDate: new Date(endDate).toISOString()
        } as any;
        return this.apiService.getCall$<Settlement[]>(`settlement/period/${userId}`, params);
    }

    getSettlementsTill(endDate: Date) {
        const params = {
            endDate: new Date(endDate).toISOString()
        } as any;
        return this.apiService.getCall$<Settlement[]>(`settlement/till`, params);
    }

    save(settlement: Settlement) {
        return this.apiService.postCall$('settlement', settlement);
    }

    saveMulti(settlements: Settlement[], bookdate: Date, userId: number) {
        let total = 0;
        settlements.forEach(s => {
            if (!this.nonTimeHourTypes.includes(s.hourtype_code)) {
                const decimal = Math.ceil((s.minutes * 100) / 60) / 100;
                s.amount = `${decimal}`;
                total += decimal;
            } else {
                s.amount = `${s.minutes}`;
            }
        });
        const normalSettlements = settlements.filter(s => ['GU', 'RV', 'V', 'Z', 'Vorst'].includes(s.hourtype_code));
        const totalDecimal = normalSettlements.reduce((sum, current) => sum + (+current.amount), 0)
        const mod = (totalDecimal * 100) % 25;
        if (mod > 0) {
            const largestSettlement = normalSettlements.filter(s => !this.nonTimeHourTypes.includes(s.hourtype_code)).sort((a, b) => b.minutes - a.minutes)[0];
            largestSettlement.amount = `${(+largestSettlement.amount) - (((totalDecimal * 100) - ((totalDecimal * 100) - mod)) / 100)}`;
        }
        return this.apiService.postCall$<Settlement[]>('settlement/multi', {
            settlements,
            bookdate,
            userId
        });
    }

    deleteDay(bookdate: Date, userId: number) {
        return this.apiService.deleteCall$<boolean>('settlement', {
            bookdate: formatDate(bookdate, 'yyyy-MM-dd', 'nl'),
            userId
        });
    }

    periodSettlementAllPdf(year: number, period: number) {
        return this.apiService.getCall$<string>(`settlement/periodSettlementAllPdf/${year}/${period}`);
    }

    periodSettlementSinglePdf(year: number, period: number, userId: number) {
        return this.apiService.getCall$<string>(`settlement/periodSettlementSinglePdf/${year}/${period}/${userId}`);
    }

    sendSingle(settlement: Settlement) {
        return this.apiService.postCall$('settlement/send', settlement);
    }

    getHourtypes(leave_selectable?: boolean) {
        let params = null;
        if (leave_selectable) {
            params = {leave_selectable: true}
        }
        return this.apiService.getCall$<Hourtype[]>('settlement/hourtypes', params);
    }

}
