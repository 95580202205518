import {Group} from './group.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class User implements RealtimeInterface {
    id?: number;
    name: string;
    email: string;
    updated_at?: Date;
    deleted_at?: Date;
    groups?: Group[];
    group?: string;
    function?: string;
    employment_type?: string;
    afas_employee_id?: string;
    zipcode?: string;
    town?: string;
    street?: string;
    housenumber?: string;
    phone?: string;
    traveltime_workday: 'Geen' | 'Staffel' | 'Werkelijk';
    default_stand: 'Thuis' | 'Floridadreef';
    traveltime_weekend: 'Geen' | 'Werkelijk';
    enddate?: Date;
    contract_hours?: number;

    firstname: string;
    insertion: string;
    lastname: string;

    overtime: boolean;
}

export enum TraveltimeWorkday {
    Geen = 'Geen', Staffel = 'Staffel', Werkelijk = 'Werkelijk'
}

export enum TraveltimeWeekend {
    Geen = 'Geen', Werkelijk = 'Werkelijk'
}

export enum DefaultStand {
    Home = 'Thuis', Floridadreef = 'Floridadreef'
}
