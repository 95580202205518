import {Pipe, PipeTransform} from '@angular/core';
import {RealisationHourtype} from '../classes/realisation';
import {RealisationService} from '../services/realisation.service';

@Pipe({
    name: 'hourtypeIcon'
})
export class HourtypeIconPipe implements PipeTransform {

    transform(type: RealisationHourtype): unknown {
        return RealisationService.hourtypeIconMapDuotone.get(type) ?? 'fa-question';
    }

}
