import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {FormGroup} from '@angular/forms';
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {EntityWithAvailable} from '../../classes/entity-with-available.class';
import {Utils} from '../../utils.class';
import {EntitiesService} from '../../services/entities/entities.service';
import {combineLatest, of, Subscription} from 'rxjs';
import {EntityUnavailableService} from '../../services/entities/entity-unavailable.service';
import {PlanningService} from '../../services/planning/planning.service';
import {Entity} from '../../classes/entity.class';
import {Planning} from '../../classes/planning.class';
import {EntityUnavailable} from '../../classes/entityunavailable.class';
import {Settings} from '../../settings.class';
import {DayTimeOptions} from '../../classes/day-time-options';
import {PlanningStatus} from '../../planning-status.enum';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {TimeOption} from '../../classes/time-option';
import {HiringService} from '../../services/hiring.service';
import {Hiring} from '../../classes/hiring';
import {disabledWhenFinal} from './planning-detail-dialog.required-rules';
import {UserType} from '../../services/user/user.service';

@Directive()
export abstract class EntitiesListAbstractComponent<T extends PlanningHasEntity | PlanningCutter> implements OnInit, OnDestroy {

    UserType = UserType;

    public planningEntitiesForm: Map<T, FormGroup>;

    @Input()
    public formsDisabled: boolean;

    @Input()
    public mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;

    @Input()
    public planning: Planning;

    @Input() fixedDate: Date;

    @Input()
    public planningEntitiesList: T[];

    public entities: EntityWithAvailable[];
    public entitiesListForForm: Map<FormGroup, EntityWithAvailable>;

    public addButtonDisabled = false;
    public warnIconLightUp = false;

    hiringList: Hiring[];

    public startTimesEntity: Map<T, DayTimeOptions[]> = new Map<T, DayTimeOptions[]>();
    public endTimesEntity: Map<T, DayTimeOptions[]> = new Map<T, DayTimeOptions[]>();
    protected planningList: Planning[];
    protected unavailableList: EntityUnavailable[];
    protected entitiesMap: Map<number, Entity>;
    protected subscriptions = new Subscription();
    private avCheckSubscriptions: Subscription;

    initialStatus: number;

    minDatePicker;

    protected constructor(private entityClassType: new () => T,
                          private entityType: EntityTypeCode,
                          protected entityUnavailableService: EntityUnavailableService,
                          protected entitiesService: EntitiesService,
                          protected hiringService: HiringService,
                          protected planningService: PlanningService) {
    }

    ngOnInit() {
        if (typeof this.planningEntitiesList === 'undefined') {
            this.planningEntitiesList = [];
        }
        this.planningEntitiesForm = new Map<T, FormGroup>([]);
        this.entitiesListForForm = new Map<FormGroup, EntityWithAvailable>([]);
        this.planningEntitiesList.forEach(planningEntity => {
            this.planningEntitiesForm.set(planningEntity, this.createForm(planningEntity));
        });
        this.initialStatus = this.planning.status_id;
        this.updateStatus(this.planning.status_id);
        this.setSubscriptions();
    }

    setSubscriptions() {
        if (this.avCheckSubscriptions) {
            this.avCheckSubscriptions.unsubscribe();
        }

        let planning$ = of([]);
        let unavailable$ = of([]);
        if (this.mainPlanning.begindate) {
            const fromDate = new Date(this.mainPlanning.begindate);
            Utils.setTime(fromDate, 0, 0);
            const toDate = new Date(this.mainPlanning.enddate);

            fromDate.setDate(fromDate.getDate() - 14);
            planning$ = this.planningService.getFilteredList(fromDate, toDate);
            unavailable$ = this.entityUnavailableService.getFilteredList(fromDate, toDate);
        }
        const hiring$ = this.hiringService.getByType(this.entityType);
        const entities$ = this.entitiesService.getByType(this.entityType);
        const entitiesMap$ = this.entitiesService.getMap();
        this.avCheckSubscriptions = new Subscription();
        this.avCheckSubscriptions.add(combineLatest(entities$, entitiesMap$, unavailable$, planning$, hiring$)
            .subscribe(([entities, entitiesMap, unavailableList, planningList, hiring]) => {
                this.unavailableList = unavailableList;
                this.planningList = planningList;
                this.entitiesMap = entitiesMap;
                this.entities = entities as EntityWithAvailable[];
                this.hiringList = hiring;
                if (this.mainPlanning.begindate) {
                    this.entities = this.entities.filter(entity => {
                        return new Date(entity.begindate).getTime() < new Date(this.mainPlanning.begindate).getTime() &&
                            (!entity.enddate || new Date(entity.enddate).getTime() > new Date(this.mainPlanning.enddate).getTime());
                    });
                    this.entities.forEach(entity => {
                        entity.notAvailable = false;
                    });
                    this.unavailableList.forEach(unavailable => {
                        const entity = this.entities.find(e => e.id === unavailable.entity_id);
                        if (entity && !entity.notAvailable) {
                            entity.notAvailable = (new Date(unavailable.begindate).getTime() < new Date(this.mainPlanning.begindate).getTime() &&
                                new Date(unavailable.enddate).getTime() > new Date(this.mainPlanning.enddate).getTime());
                        }
                    });
                }
                this.planningEntitiesList.forEach(planningEntity => {
                    this.genStartTimesMap(planningEntity);
                    if (planningEntity.enddate) {
                        this.genEndTimesMap(planningEntity);
                    }
                });
            }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.avCheckSubscriptions.unsubscribe();
    }

    getNew(): T {
        return new this.entityClassType();
    }

    public addEntityPlanning() {
        if (!this.planningEntitiesList) {
            this.planningEntitiesList = [];
        }
        const planningEntity = this.getNew();
        if (this.mainPlanning.begindate) {
            planningEntity.begindate = new Date(this.mainPlanning.begindate);
            planningEntity.enddate = new Date(this.mainPlanning.enddate);
        }
        planningEntity.planning_id = this.mainPlanning.planning_id;
        this.planningEntitiesList.push(planningEntity);
        this.planningEntitiesForm.set(planningEntity, this.createForm(planningEntity));
        const planningProperty = new Map<EntityTypeCode, string>([
            [EntityTypeCode.Cutter, 'planning_cutters'],
            [EntityTypeCode.Wipetruck, 'planning_wipetrucks']
        ]);
        if (!this.planning[planningProperty.get(this.entityType)]) {
            this.planning[planningProperty.get(this.entityType)] = [];
            this.planning[planningProperty.get(this.entityType)].push(planningEntity);
        }
        this.updateStatus(this.planning.status_id);
        this.checkAddPossibility();
        return planningEntity;
    }

    public removeEntityPlanning(planning: T) {
        this.planningEntitiesForm.delete(planning);
        this.planningEntitiesList.splice(this.planningEntitiesList.indexOf(planning), 1);
        this.checkAddPossibility();
    }

    public reValidateFields(daysDiff = 0, date?: Date) {
        [...this.planningEntitiesForm.keys()]
            .sort((a, b) => {
                // We need to sort to fix overlapping items
                if (daysDiff > 0) {
                    return Utils.getTimeOrNull(b.begindate) - Utils.getTimeOrNull(a.begindate);
                }
                return Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate);
            })
            .forEach(planningEntity => {
                const formGroup = this.planningEntitiesForm.get(planningEntity);
                // Descending sort for moving
                Utils.triggerValidation(formGroup);
                const newBeginDate = new Date(planningEntity.begindate);
                if (daysDiff) {
                    newBeginDate.setDate(newBeginDate.getDate() + daysDiff);
                    const newEndDate = new Date(planningEntity.enddate);
                    newEndDate.setDate(newEndDate.getDate() + daysDiff);
                    planningEntity.begindate = newBeginDate;
                    planningEntity.enddate = newEndDate;
                    formGroup.get('date').get('date').setValue(Utils.newDate(newBeginDate));
                    formGroup.get('date').get('begintime').setValue(newBeginDate.getTime());
                    formGroup.get('date').get('endtime').setValue(newEndDate.getTime());
                    this.genStartTimesMap(planningEntity);
                    this.genEndTimesMap(planningEntity);
                }
            });
        setTimeout(() => {
            this.setSubscriptions();
        });
    }

    public mainItemBeginTimeChanged(oldBegin: Date, oldEnd: Date, newBegin: number) {
        this.planningEntitiesForm.forEach((formGroup: FormGroup, planningEntity: T) => {
            const planningBegin = new Date(planningEntity.begindate);
            const planningEnd = new Date(planningEntity.enddate);
            if (planningEntity.begindate === null || (planningBegin.getTime() === oldBegin.getTime() && planningEnd.getTime() === oldEnd.getTime())) {
                planningBegin.setTime(newBegin);
                formGroup.get('date').get('begintime').setValue(planningBegin.getTime());
            }
            this.genStartTimesMap(planningEntity);
        });
    }

    public mainItemEndTimeChanged(oldBegin: Date, oldEnd: Date, newEnd: number) {
        this.planningEntitiesForm.forEach((formGroup: FormGroup, planningEntity: T) => {
            const planningBegin = new Date(planningEntity.begindate);
            const planningEnd = new Date(planningEntity.enddate);
            if (planningEntity.enddate === null || (planningBegin.getTime() === oldBegin.getTime() && planningEnd.getTime() === oldEnd.getTime())) {
                planningEnd.setTime(newEnd);
                formGroup.get('date').get('endtime').setValue(planningEnd.getTime());
                planningEntity.enddate = planningEnd;
            }
            if (planningEntity.enddate) {
                this.genEndTimesMap(planningEntity);
            }
        });

        this.minDatePicker = new Date(newEnd);
        this.minDatePicker.setDate(this.minDatePicker.getDate() - Settings.DAYS_SUBTRACT_SUBPLANNING);
    }

    public checkAddPossibility() {
        if (this.entities && this.planningEntitiesList) {
            const availableEntityCount = this.entities.filter(entity => {
                return !entity.notAvailable && this.planningEntitiesList.map(planningEntity => {
                    return planningEntity.entity_id;
                }).indexOf(entity.id) === -1;
            }).length;
            const emptyRows = this.planningEntitiesList.filter(planningEntity => {
                return !planningEntity.entity_id;
            }).length;
            this.addButtonDisabled = (availableEntityCount === 0 || emptyRows === availableEntityCount);
            this.warnIconLightUp = this.addButtonDisabled && this.planningEntitiesList.length === 0;
        }
    }

    public validateForms(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Promise.all([
                ...Array.from(this.planningEntitiesForm.values()).map(pef => Utils.triggerValidationP(pef))
            ]).then(() => {
                resolve(true);
            }, () => {
                this.planningEntitiesForm.forEach((formGroup: FormGroup) => {
                    if (!formGroup.valid) {
                        // Keep console log for debugging in prod
                        console.error('Invalid formGroup: ', formGroup);
                        for (const name of Object.keys(formGroup.controls)) {
                            Utils.triggerValidation(formGroup);
                        }
                    }
                });
                reject();
            });
        });

    }

    public checkDirty(): boolean {
        let dirty = false;
        this.planningEntitiesForm.forEach((formGroup: FormGroup) => {
            if (!dirty) {
                dirty = formGroup.dirty;
            }
        });
        return dirty;
    }

    public disableForms() {
        this.formsDisabled = true;
        Array.from(this.planningEntitiesForm.values()).forEach(form => {
            form.disable({emitEvent: false});
            form.get('date').disable({emitEvent: false});
        });
    }

    public enableForms() {
        Array.from(this.planningEntitiesForm.values()).forEach(form => {
            form.enable();
            form.get('date').enable({emitEvent: false});
        });
    }

    public updateStatus(status_id: number) {
        this.planningEntitiesForm.forEach((formGroup, entity) => {
            const dateControls = [
                formGroup.get('date').get('begintime'),
                formGroup.get('date').get('endtime')
            ];

            // When PlanningStatus.bakvanwessel, date should be empty
            if (status_id === PlanningStatus.bakvanwessel) {
                entity.begindate = null;
                entity.enddate = null;
                dateControls.forEach(control => {
                    control.setValue(null);
                    control.disable();
                });
            } else {
                // When previous state was PlanningStatus.bakvanwessel, refill date with today
                if (dateControls[0].disabled) {
                    dateControls.forEach(control => {
                        if (!disabledWhenFinal(this)) {
                            control.enable();
                        }
                    });
                }
            }
        });
    }

    protected genStartTimesMap(planningEntity: T) {;
        if (planningEntity.begindate) {
            const begin = new Date(planningEntity.begindate);
            begin.setHours(0);
            const end = new Date(Math.min(new Date(this.mainPlanning.enddate).getTime(), Utils.setTime(new Date(begin), 24, 0).getTime()));
            if (this.entitiesMap) {
                const otherItems = this.planningEntitiesList.filter(p => p !== planningEntity && p.entity_id === planningEntity.entity_id);
                const timeOptions = Utils.genTimesMapOneEntity(
                    begin,
                    end,
                    planningEntity as PlanningHasEntity,
                    this.mainPlanning.planning_id,
                    this.planningList,
                    this.unavailableList,
                    false,
                    otherItems
                );
                this.startTimesEntity.set(planningEntity, timeOptions);
                if (planningEntity.begindate) {
                    const allOptions = ([] as TimeOption[]).concat(...timeOptions.map(p => p.options));
                    const planningCutterTime = (new Date(planningEntity.begindate)).getTime();
                    const option = allOptions.find(b => b.datetime.getTime() === planningCutterTime);
                    if (!option || (option && option.notAvailable)) {
                        planningEntity.begindate = null;
                        planningEntity.enddate = null;
                        this.planningEntitiesForm.get(planningEntity as T).get('date').get('begintime').setValue(null);
                        this.planningEntitiesForm.get(planningEntity as T).get('date').get('endtime').setValue(null);
                    }
                }
            }
        }
    }

    protected genEndTimesMap(planningEntity: T) {
        if (planningEntity.begindate && this.mainPlanning.enddate) {
            const begin = new Date(planningEntity.begindate);
            begin.setUTCHours(begin.getUTCHours() + 1);
            const end = new Date(begin);
            end.setUTCHours(end.getUTCHours() + Settings.MAX_TIMESPAN_HOURS);
            const diff = Math.round((end.getTime() - this.mainPlanning.enddate.getTime()) / 36e5) - 1;
            if (diff > 0) {
                end.setUTCHours((end.getUTCHours() - diff));
            }
            if (this.entitiesMap) {
                const entity = this.entitiesMap.get(planningEntity.entity_id);
                const otherItems = this.planningEntitiesList.filter(p => p !== planningEntity && p.entity_id === planningEntity.entity_id);
                const timeOptions = Utils.genTimesMapOneEntity(
                    begin,
                    end,
                    planningEntity as PlanningHasEntity,
                    this.mainPlanning.planning_id,
                    this.planningList,
                    this.unavailableList,
                    true,
                    otherItems
                );
                this.endTimesEntity.set(planningEntity, timeOptions);
                if (planningEntity.enddate) {
                    const planningCutterTime = (new Date(planningEntity.enddate)).getTime();
                    const allOptions = ([] as TimeOption[]).concat(...timeOptions.map(p => p.options));
                    const option = allOptions.find(b => b.datetime.getTime() === planningCutterTime);
                    if (!option || (option && option.notAvailable)) {
                        planningEntity.enddate = null;
                        this.planningEntitiesForm.get(planningEntity as T).get('date').get('endtime').setValue(null);
                    }
                }
            }
        }
    }

    protected abstract createForm(entityPlanning: T): FormGroup;

    protected convertFormDate(setToObject, dateData: any) {
        setToObject.begindate = Utils.newDate(dateData.begintime);
        setToObject.enddate = Utils.newDate(dateData.endtime);
    }
}
