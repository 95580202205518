<div class="modal-inner">
    <div class="modal-inner-inner w-100">
        <h1>Verlof opmerking</h1>
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>Opmerking</mat-label>
            <input matInput
                   [formControl]="form.controls.comment"
                   type="text"
                   placeholder="Opmerking">
        </mat-form-field>
        <div *ngIf="data.currentHourType === RealisationHourtype.sleep">
            <b>Project</b>
            <input class="search" placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
            <div class="results">
                <div class="result text-center" *ngIf="searching">
                    aan het zoeken...
                </div>
                <div class="result"
                     (click)="setProject(project)"
                     *ngFor="let project of projects">
                    <strong>{{project.afas_project_id}}</strong> {{project.name}}
                </div>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button
                class="planning-button confirm"
                color="primary"
                [disabled]="form.invalid"
                [mat-dialog-close]="form.value">
            Toevoegen
        </button>
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
