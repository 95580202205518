import {Entity} from './entity.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class EntityUnavailable implements RealtimeInterface {
    id: number;
    entity_id: number;
    description: string;
    begindate: Date;
    enddate: Date;
    entity: Entity;
}
