<div class="overlay" [class.hide]="isFocussed">
    <img *ngIf="icon === 'dvds'" class="dvds-icon" src="/assets/images/logo-small.png">
    <i *ngIf="icon === 'own'" class="fa-duotone fa-house-user"></i>
    <i *ngIf="icon === 'next'" class="fa-duotone fa-arrow-right"></i>
    <i *ngIf="icon === 'none'" class="fa-duotone fa-xmark"></i>
    <i *ngIf="icon === 'work'" class="fa-duotone fa-person-digging"></i>
    <span *ngIf="addressVisible">{{fc.value}}</span>
</div>
<input type="text" matInput
       [class.visible]="isFocussed"
       [matAutocomplete]="autoComplete"
       [placeholder]="placeholder"
       [formControl]="fcSearch"
       (focus)="isFocussed=true"
       (blur)="isFocussed=false">
<mat-autocomplete #autoComplete="matAutocomplete"
                  autoActiveFirstOption
                  class="address-autocomplete"
                  (optionSelected)="optionSelected($event)"
                  (opened)="updateAddresses()">
    <mat-option *ngIf="fc.value" [value]="fc.value"><b>Huidig:</b> {{fc.value}}</mat-option>
    <ng-container *ngIf="!fcSearch.value">
        <mat-option [value]="main.planning.location" *ngIf="main.planning.location?.length > 0">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <i class="fa-duotone fa-person-digging"></i>
                </div>
                <div class="col ellipsis">
                    {{main.planning.location}}
                </div>
            </div>
        </mat-option>
        <mat-option [value]="driverAddress" *ngIf="driverAddress">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <i class="fa-duotone fa-house-user"></i>
                </div>
                <div class="col ellipsis">
                    {{driverAddress}}
                </div>
            </div>
        </mat-option>
        <mat-option [value]="worksiteAddress" *ngIf="worksiteAddress">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <i class="fa-duotone fa-person-digging"></i>
                </div>
                <div class="col ellipsis">
                    {{worksiteAddress}}
                </div>
            </div>
        </mat-option>
        <mat-option [value]="dvdsAddress">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <img class="dvds-icon" src="/assets/images/logo-small.png">
                </div>
                <div class="col ellipsis">
                    Floridadreef 19, Utrecht
                </div>
            </div>
        </mat-option>
        <mat-option value="none">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <i class="fa-duotone fa-xmark"></i>
                </div>
                <div class="col ellipsis">
                    Geen
                </div>
            </div>
        </mat-option>
        <mat-option value="next">
            <div class="row flex-nowrap">
                <div class="col-auto d-flex align-items-center pr-0">
                    <i class="fa-duotone fa-arrow-right"></i>
                </div>
                <div class="col ellipsis">
                    {{isOrigin ? 'Vorige' : 'Volgende'}} transportregel
                </div>
            </div>
        </mat-option>
    </ng-container>
    <mat-option *ngFor="let option of addresses" [value]="option">
        {{option}}
    </mat-option>
</mat-autocomplete>
