import {User} from './user.class';

export class ProjectComment {
    id?: number;
    afas_project_id: string;
    user_id?: number;
    user?: User;
    date: Date;
    comment: string;
    content: {
        customs?: {
            user_id: CustomGroup[],
            entity_id: CustomGroup[],
        }
    };
    saved_by_id?: number;
    week: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    changed_by_count?: number;
}

export class CustomGroup {
    group: string;
    rows: {
        name: string;
        mon: number;
        tue: number;
        wed: number;
        thur: number;
        fri: number;
        sat: number;
        sun: number;
    }[];
}
