import {Injectable} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {Utils} from '../utils.class';
import {HourEditDialogComponent} from '../hour-registration/hour-edit-dialog/hour-edit-dialog.component';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class TimePickerService {

    constructor(private dialog: MatDialog) {
    }

    editRealisationTime(realisation: Realisation,
                        field: 'begindate' | 'enddate',
                        formGroup?: FormGroup<ControlsOf<{
                            begindate: FormControl<Date>,
                            enddate: FormControl<Date>,
                        }>>,
                        addToCurrentTime = 0,
                        isFirstRealisationOfBookdate = false) {
        let hideNowButton = true;
        let minDate = new Date(realisation.planning_has?.begindate ?? realisation.user_planning?.begindate ?? realisation.begindate);
        minDate = Utils.setTime(minDate, 0, 0);
        if (field === 'enddate') {
            minDate = new Date(formGroup.get('begindate').value);
            do {
                minDate.setMinutes(minDate.getMinutes() + 1);
            } while (minDate.getMinutes() % 3 !== 0);
        } else if (isFirstRealisationOfBookdate) {
            minDate = Utils.setTime(new Date(realisation.bookdate), -3, 0);
        }
        let maxDate = null;
        if (field === 'begindate') {
            if (realisation.planning_has?.begindate || realisation.user_planning?.begindate) {
                const planningBeginDate = new Date(realisation.planning_has?.enddate ?? realisation.user_planning?.enddate);
                maxDate = new Date(planningBeginDate);
            } else {
                maxDate = new Date(formGroup.get('enddate').value);
            }
            maxDate.setHours(maxDate.getHours() + 20);
            if (isFirstRealisationOfBookdate && !Utils.matchesDate(maxDate, realisation.bookdate)) {
                maxDate.setHours(maxDate.getHours() - (1 + maxDate.getHours()));
            }
        }

        const ref = this.dialog.open(HourEditDialogComponent, {
            maxWidth: '500px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'time-edit-dialog',
            data: {
                date: formGroup.get(field).value,
                minDate,
                maxDate,
                addToCurrentTime,
                hideNowButton
            }
        });

        return ref.afterClosed();
    }
}
