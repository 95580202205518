import {Injectable} from '@angular/core';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RealtimeInterface} from '../realtime/realtime-interface';

export class PlanningAutocomplete implements RealtimeInterface {
    id: string;
    value: string;
    type: string;
    latest: number;
}

export enum AutocompleteType {
    contractors = 'contractors',
    pouring_off = 'pouring_off',
    cutterDriver = 'cutterDriver',
    wipetruckDriver = 'wipetruckDriver',
    truckDriver = 'truckDriver'
}


@Injectable({
    providedIn: 'root'
})
export class AutocompleteService extends RealtimeService<PlanningAutocomplete> {

    constructor(protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.autocomplete);
    }

    itemInDaterange(planning: PlanningAutocomplete, fromDate: Date, toDate: Date) {
        throw new Error('Method not implemented.');
    }

    public getByType(type: AutocompleteType): Observable<string[]> {
        return super.getList()
                    .pipe(map(autoComplete => {
                        return autoComplete.filter(autoCompleteValue => autoCompleteValue.type === type)
                                           .sort((l, r) => r.latest - l.latest)
                                           .map(autoCompleteValue => autoCompleteValue.value);
                    }))
                    .pipe(map(values => values.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])));
    }
}
