import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {Utils} from '../utils.class';

@Pipe({
    name: 'doesNotMatchDriver'
})
export class DoesNotMatchDriverPipe implements PipeTransform {

    transform(value: Realisation, realisations: Realisation[], check: 'begindate' | 'enddate', ...triggerPipeOnChange: any[]): boolean {
        return Utils.doesNotMatchDriver(value, realisations, check);
    }

}
