import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {ProjectComment} from '../../../classes/project-comment';
import {CodaltComponent} from '../../../codalt.component';
import {ProjectService} from '../../../services/project.service';
import {Utils} from '../../../utils.class';

@Component({
    selector: 'app-edit-week-comment-dialog',
    templateUrl: './edit-week-comment-dialog.component.html',
    styleUrls: ['./edit-week-comment-dialog.component.scss']
})
export class EditWeekCommentDialogComponent extends CodaltComponent implements OnInit {

    form: FormGroup<ControlsOf<{ comment: string }>>;
    comment: ProjectComment;
    commentId: number;

    constructor(public dialogRef: MatDialogRef<EditWeekCommentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: {
                    comment: ProjectComment
                },
                private projectService: ProjectService) {
        super();
        this.commentId = data.comment.id;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.projectService.getProjectComment(this.commentId).subscribe(result => {
            this.comment = result.data;
            this.form = new FormGroup({
                comment: new FormControl(this.comment.comment)
            });
        }));
    }

    save() {
        Utils.triggerValidationP(this.form).then(() => {
            Object.assign(this.comment, this.form.value);
            this.projectService.saveProjectComment(this.comment).subscribe(result => {
                Object.assign(this.comment, result.data);
                this.dialogRef.close(this.comment);
            }, () => {

            });
        });
    }

}
