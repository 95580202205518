import {User} from './user.class';
import {Planning} from './planning.class';
import {Project} from './project.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class UserPlanning implements RealtimeInterface {
    id?: number;
    afas_project_id?: string;
    project?: Project;
    dayOff?: boolean;
    begindate: Date;
    enddate: Date;
    user_id: number;
    user?: User;
    planning_id: number;
    planning?: Planning;
    origin?: string;
    destination?: string;
    work_begin?: string;
    work_end?: string;
    comment: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}
