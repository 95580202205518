import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {validTime} from './validators/valid-time.validator';
import {DateAdapter} from '@angular/material/core';
import {DateSmallAdapter} from './date-adapters/date-small-adapter';
import {FormControl} from '@ngneat/reactive-forms';
import {MinutesPipe} from './pipes/minutes.pipe';

@Component({
    selector: 'app-minutes-input',
    template: `
        <input
            [formControl]="fcTime"
            (click)="timeInput.select()"
            #timeInput
            [placeholder]="placeholder"
            type="text">
        <small *ngIf="correction" [class.negative]="correction < 1">{{correction | minutes}}</small>
    `,
    styles: [`
        input {
            border: 0;
        }

        input:first-child {
            padding-left: 0.5rem;
        }

        input:focus-visible {
            outline: 0;
        }

        small {
            margin-top: -6px;
            margin-bottom: -4px;

            &.negative {
                margin-left: 6px;
            }
        }
    `],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MinutesInputComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => MinutesInputComponent),
        multi: true
    }, {
        provide: DateAdapter, useClass: DateSmallAdapter
    }]
})
export class MinutesInputComponent implements ControlValueAccessor, OnDestroy, OnInit {

    @HostBinding('class.ng-invalid') invalid = false;
    @HostBinding('class.ng-touched') touched = false;


    static nextId = 0;
    fcTime: FormControl<string>;
    @HostBinding() id = `datetime-input-${MinutesInputComponent.nextId++}`;
    readonly autofilled: boolean;
    readonly controlType: string;
    readonly disabled: boolean;
    readonly empty: boolean;
    readonly errorState: boolean;
    readonly focused: boolean;
    readonly required: boolean;
    readonly stateChanges = new Subject<void>();
    @Input() appearance: MatFormFieldAppearance;
    @Input() label = '0:00';
    @Input() hint;
    @Input() min;
    @Input() hideDate = false;
    @Input() defaultDate: Date;
    @Input() timeClass: string;
    @Input() placeholder = '';
    @Input() limitHours = 24;
    @Input() correction = null;
    @ViewChild('timeInput') timeInput: ElementRef;
    private subscriptions = new Subscription();

    constructor() {
    }


    ngOnInit(): void {
        this.fcTime = new FormControl<string>(
            null,
            {
                validators: [
                    validTime(this.limitHours)
                ],
                updateOn: 'blur'
            }
        );

        setTimeout(() => {

            this.fcTime.invalid$.subscribe(invalid => {
                this.invalid = invalid;
            });
            this.fcTime.touch$.subscribe(touch => {
                this.touched = touch;
            });

            this.subscriptions.add(this.fcTime.valueChanges.subscribe(
                value => this.handleTimeValueChange(value, this.fcTime, this.limitHours)
            ));

            this.subscriptions.add(this.fcTime.valueChanges.subscribe(value => {
                this.handleTimeValueChange(value, this.fcTime, this.limitHours);
                this.value = this.formattedToMinutes();
            }));
        });
    }

    @Input()
    get value(): number | null {
        return this.formattedToMinutes();
    }

    set value(date: number) {
        this.fcTime.setValue((date !== 0 && date !== null && date !== undefined) ? (new MinutesPipe()).transform(date) : null, {emitEvent: false});
        this.onChange(date);
        this.onTouch(date);
        this.stateChanges.next();
    }

    formattedToMinutes() {
        const time = this.fcTime.value.split(':');
        if (time.length === 2) {
            const hours = +time[0];
            const minutes = +time[1];
            return minutes + (hours * 60);
        }
        return 0;
    }

    setDisabledState() {
        this.fcTime.disable();
    }

    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.stateChanges.complete();
    }

    writeValue(obj: number) {
        this.value = obj;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    validate({value}: FormControl<any>) {
        if (!this.fcTime.valid) {
            return {
                invalid: true
            };
        }
        return null;
    }

    handleTimeValueChange = (value: string, formControl: FormControl<any>, limitHours = 24) => {
        if (value) {
            let newValue = value.replace('.', ':');
            const splitted = newValue.split(':'), hour = +splitted[0], minute = +splitted[1];
            const minuteText = splitted[1];
            if (splitted.length === 2 && hour >= 0 && hour < limitHours && minute >= 0 && minute <= 59) {
                newValue = `${hour}:${(minuteText.length > 1 && minute < 10 ? '0' : '')}${minute}${minuteText.length < 2 ? '0' : ''}`;
            } else {
                if (value.match(/^[0-9]*$/)) {
                    newValue = newValue + ':00';
                } else if (newValue.length > 2) {
                    for (let place = 2; place >= 1; place--) {
                        const tempValue = [newValue.slice(0, place), newValue.slice(place)].join(':');
                        const tempSplitted = tempValue.split(':'), tempHour = +tempSplitted[0], tempMinute = +tempSplitted[1];
                        if (tempSplitted.length === 2 && tempHour >= 0 && tempHour < limitHours && tempMinute >= 0 && tempMinute <= 59) {
                            newValue = tempValue;
                            break;
                        }
                    }

                }
            }
            if (value !== newValue) {
                formControl.setValue(newValue);
            }
        }
    }
}
