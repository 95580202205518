import {Component, Inject, OnInit} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LocalStorage} from '../../storage.class';

@Component({
    selector: 'app-tiny-insert-image',
    templateUrl: './tiny-insert-image.component.html',
    styleUrls: ['./tiny-insert-image.component.scss']
})
export class TinyInsertImageComponent implements OnInit {

    public form: FormGroup<ControlsOf<{
        image: string,
        image_thumb: string,
        image_offsetX: number,
        image_offsetY: number,
        maintainAspectRatio: boolean,
        noOverScale: boolean
    }>>;

    constructor(public dialogRef: MatDialogRef<TinyInsertImageComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            image: new FormControl(),
            image_thumb: new FormControl(null),
            image_offsetX: new FormControl(null),
            image_offsetY: new FormControl(0),
            maintainAspectRatio: new FormControl(true),
            noOverScale: new FormControl(true)
        });
    }

    insert() {
        this.dialogRef.close(`<img src="${this.form.controls.image.value}?access_token=${LocalStorage.getUserToken()}">`);
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
}
