<div cdkMenuBar *ngIf="!mobile">
    <button mat-icon-button [cdkMenuTriggerFor]="personMenu" cdkMenuItem>
        <i class="fa-regular fa-user"></i>
    </button>
    <button mat-icon-button [cdkMenuTriggerFor]="manageMenu" cdkMenuItem
            *ngIf="viewModel.planningTransport || viewModel.users || viewModel.entities || viewModel.asphalt || viewModel.hiringCompanies || viewModel.settlements || viewModel.planningEmployee">
        <i class="fa-regular fa-gear"></i>
    </button>
    <button mat-icon-button [cdkMenuTriggerFor]="exportMenu" cdkMenuItem
            *ngIf="viewModel.asphalt || viewModel.planningpdf || viewModel.hirePeople || viewModel.hiringCompanies || viewModel.projectmanagerReport">
        <i class="fa-regular fa-bars"></i>
    </button>
</div>

<div cdkMenuBar *ngIf="mobile">
    <button mat-icon-button [cdkMenuTriggerFor]="mobileMenu" cdkMenuItem>
        <i class="fa-regular fa-bars"></i>
    </button>
</div>

<ng-template #manageMenu>
    <div class="menu" cdkMenu>
        <ng-container *ngTemplateOutlet="manageMenuContent"></ng-container>
    </div>
</ng-template>
<ng-template #manageMenuContent>
    <div *ngIf="viewModel.planningTransport || viewModel.checkHours || viewModel.asphalt || viewModel.settlements || viewModel.planningEmployee">
        Beheer
    </div>
    <a *ngIf="viewModel.planningTransport && location.pathname !== '/'" routerLink="/">Weekplanning</a>
    <a *ngIf="viewModel.planningTransport && location.pathname !== '/transport-planning'"
       routerLink="/transport-planning">Transportplanning</a>
    <a *ngIf="viewModel.dayCheckRealisations" routerLink="/dagcontrole">Dagcontrole</a>
    <a *ngIf="viewModel.dayCheckRealisations" routerLink="/weekcontrole">Weekcontrole</a>
    <a *ngIf="viewModel.planningEmployee && location.pathname !== '/personeelsplanning'" routerLink="/personeelsplanning">Personeelsplanning</a>
    <a *ngIf="viewModel.checkHours" routerLink="/check-hours">Urencontrole</a>
    <a *ngIf="viewModel.settlements" routerLink="/periode/verrekening">Periodeverrekening</a>
    <a *ngIf="viewModel.settlements" routerLink="/periode/boekingsregels">Boekingsregels</a>
    <a *ngIf="viewModel.settlements" routerLink="/boekingsregels-versturen">Alle boekingsregels</a>
    <a *ngIf="viewModel.users" (click)="users()">Gebruikers</a>
    <a *ngIf="viewModel.entities" (click)="entities()">Materieel</a>
    <a *ngIf="viewModel.asphalt" (click)="asphalt()">Asfalt</a>
    <a *ngIf="viewModel.hiringCompanies" (click)="hiringCompanies()">Inhuur bedrijven</a>
    <a *ngIf="viewModel.leave" routerLink="/leave">Verlof</a>
    <a *ngIf="viewModel.toolbox" (click)="toolbox()">Toolboxen beheren</a>
</ng-template>
<ng-template #exportMenu>
    <div class="menu" cdkMenu>
        <ng-component *ngTemplateOutlet="exportMenuContent"></ng-component>
    </div>
</ng-template>
<ng-template #exportMenuContent>
    <div *ngIf="viewModel.planningpdf || viewModel.asphalt || viewModel.hirePeople">
        Exporteren
    </div>
    <a *ngIf="viewModel.projectWeekReport" routerLink="/project-rapport">Weekrapporten</a>
    <a *ngIf="viewModel.asphalt" (click)="exportAsphalt()">Bestellijst asfalt</a>
    <a *ngIf="viewModel.planningpdf" (click)="exportPlanningPdf()">Planning PDF</a>
    <a *ngIf="viewModel.hirePeople" (click)="hirePeople()">Inhuur mensen</a>
    <a *ngIf="viewModel.hiringCompanies" (click)="hiredCompanies()">Ingehuurd materieel</a>
    <a *ngIf="viewModel.projectmanagerReport" (click)="projectmanagerReport()">Projectleider rapport</a>
</ng-template>
<ng-template #personMenu>
    <div class="menu" cdkMenu>
        <ng-container *ngTemplateOutlet="personMenuContent"></ng-container>
    </div>
</ng-template>
<ng-template #personMenuContent>
    <div>
        Mijn account
    </div>
    <a class="user" routerLink="/profiel">
        <i class="fa-regular fa-user"></i> {{authenticatedUser?.name}}<br>
        <i class="fa-regular fa-envelope"></i> {{authenticatedUser?.email}}<br>
    </a>
    <a (click)="logout()">Uitloggen</a>
    <div>
        Planning
    </div>
    <a routerLink="/weekschedule">Persoonlijke planning</a>
    <a *ngIf="viewModel.checkHours" routerLink="/check-hours">Urencontrole</a>
    <a (click)="showToolbox()">Toolbox</a>
</ng-template>
<ng-template #mobileMenu>
    <div class="menu mobile-menu" cdkMenu>
        <app-planning-filter (selectedTeams)="selectedTeams.emit($event)" [mobile]="true"></app-planning-filter>
        <ng-container *ngTemplateOutlet="manageMenuContent"></ng-container>
        <ng-container *ngTemplateOutlet="exportMenuContent"></ng-container>
        <ng-container *ngTemplateOutlet="personMenuContent"></ng-container>
    </div>
</ng-template>
