import {Component, OnInit} from '@angular/core';
import {RealisationService} from '../../services/realisation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CodaltComponent} from '../../codalt.component';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {formatDate} from '@angular/common';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-day-detail',
    templateUrl: './day-detail.component.html',
    styleUrls: ['./day-detail.component.scss']
})
export class DayDetailComponent extends CodaltComponent implements OnInit {

    maxDate: Date;
    date: Date;
    nextDate: Date;
    dateString: string;
    realisations: Realisation[];
    notWorkRealisations: Realisation[];

    projectmanager: string;
    performer: string;
    asphalt_performer: string;

    showWorkWith = false;
    showWorkWithFrom: Date;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private realisationService: RealisationService) {
        super();
        this.maxDate = this.realisationService.calcMaxDate();

    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string }) => {
            this.date = new Date(params.date);
            this.dateString = Utils.dateString(this.date);
            this.nextDate = new Date(this.date);
            this.nextDate.setDate(this.nextDate.getDate() + 1);
            if (this.date < this.maxDate) {
                this.realisationService.getUserDaySchedule(this.date).subscribe(realisations => {
                    this.realisations = realisations.data.realisations.filter(r => r.hourtype === RealisationHourtype.worktime);
                    this.notWorkRealisations = realisations.data.realisations.filter(r => [
                        RealisationHourtype.day_off,
                        RealisationHourtype.education,
                        RealisationHourtype.bank_holiday,
                        RealisationHourtype.frost,
                        RealisationHourtype.sleep,
                        RealisationHourtype.illness
                    ].includes(r.hourtype));
                });
                this.showWorkWithFrom = new Date(this.date);
                do {
                    this.showWorkWithFrom.setDate(this.showWorkWithFrom.getDate() - 1);
                } while (!Utils.isWorkingday(this.showWorkWithFrom));
                this.showWorkWith = this.showWorkWithFrom.getTime() <= (new Date()).getTime();
            }

            window.scroll(0, 0);
        }));
    }

    next() {
        this.date.setDate(this.date.getDate() + 1);
        this.router.navigateByUrl(`weekschedule/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        this.router.navigateByUrl(`weekschedule/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }
}
