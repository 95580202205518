export enum RealtimeType {
    planning = 'planning',
    planningHas = 'planning_has',
    userPlanning = 'user_planning',
    entityunavailable = 'entityunavailable',
    entities = 'entities',
    planningfixed = 'planningfixed',
    users = 'users',
    autocomplete = 'autocomplete',
    hiring = 'hiring',
    project = 'project',
    leave = 'leave',
    planningText = 'planningtext'
}
