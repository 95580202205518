<table [class.disabled]="formsDisabled" [class.table-last-col]="!formsDisabled" *ngIf="!formsDisabled" class="table table-center table-add-row">
    <tr>
        <th width="70">Molen</th>
        <th [matTooltip]="'asphalttype' | tt">Type asfalt</th>
        <th [matTooltip]="'asphaltcode' | tt" width="130">Code centrale</th>
        <th class="number-input">Ton</th>
        <th class="number-input">Dikte <small>mm</small></th>
        <th [matTooltip]="'asphalt-trucks-time' | tt">Tijdstip</th>
        <th [matTooltip]="'trucks-count' | tt" class="number-input">Vrachtwagens</th>
        <th class="comment">Opmerking</th>
        <th *ngIf="!formsDisabled"></th>
    </tr>
    <tr (delete)="removeAsphaltPlanning(planningAsphalt)"
        (reOrder)="reorder()"
        (updateTrucks)="trucks()"
        *ngFor="let planningAsphalt of planningAsphaltList"
        [asphaltMills]="asphaltMills"
        [availableTrucks]="availableTrucks.get(planningAsphalt)"
        [formsDisabled]="formsDisabled"
        [mainPlanning]="mainPlanning"
        [maxUsedTrucks]="maxUsedTrucks.get(planningAsphalt)"
        [planningAsphalt]="planningAsphalt"
        [planning]="planning"
        [timeList]="timeList"
        app-asphalt-list-item>
    </tr>
    <tr [hidden]="formsDisabled">
        <td colspan="8">
            <button (click)="addAsphaltPlanning()" class="w-100" color="primary" mat-button>
                Toevoegen <i class="fas fa-plus"></i>
            </button>
        </td>
        <td></td>
    </tr>
</table>
<table class="table table-center" *ngIf="formsDisabled">
    <tr>
        <th>Molen</th>
        <th>Type asfalt</th>
        <th width="130">Code centrale</th>
        <th class="number-input">Ton</th>
        <th class="number-input">Dikte <small>mm</small></th>
        <th>Tijdstip</th>
        <th class="number-input">Vrachtwagens</th>
        <th width="250">Opmerking</th>
    </tr>
    <tr *ngFor="let planningAsphalt of planning.asphalt_list">
        <td *ngIf="asphaltMills">
            {{(asphaltMills | byId:planningAsphalt.asphaltmill_id)?.name}}
        </td>
        <td>{{planningAsphalt.asphaltname}}</td>
        <td>{{planningAsphalt.asphaltcode}}</td>
        <td>{{planningAsphalt.tons}}</td>
        <td>{{planningAsphalt.thickness}}</td>
        <td>{{planningAsphalt.time | date:'EEE H:mm'}}</td>
        <td>{{planningAsphalt.trucks}}</td>
        <td>{{planningAsphalt.comment}}</td>
    </tr>
</table>
