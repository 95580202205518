import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DayTimeOptions} from '../../../../classes/day-time-options';
import {Asphaltmill} from '../../../../classes/asphaltmill.class';
import {PlanningAsphalt} from '../../../../classes/planningasphalt.class';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utils} from '../../../../utils.class';
import {RequiredRule} from './asphalt-list-component.required-rules';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {Planning} from '../../../../classes/planning.class';
import {Observable, of} from 'rxjs';
import {Settings} from '../../../../settings.class';
import {PlanningAsfaltteam} from '../../../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../../../classes/planning-has-entity.class';
import {PlanningStatus} from '../../../../planning-status.enum';
import {AsphaltmillAsphalt} from '../../../../classes/asphaltmillasphalt.class';

@Component({
    selector: 'tr[app-asphalt-list-item]',
    templateUrl: './asphalt-list-item.component.html',
    styleUrls: ['./asphalt-list-item.component.scss']
})
export class AsphaltListItemComponent implements OnInit, OnChanges {

    @Input() timeList: DayTimeOptions[] = [];
    @Input() asphaltMills: Asphaltmill[];
    @Input() planningAsphalt: PlanningAsphalt;
    @Input() formsDisabled: boolean;
    @Input() maxUsedTrucks: number;
    @Input() availableTrucks: number;
    @Input() mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;
    @Input() planning: Planning;

    @Output() reOrder = new EventEmitter();
    @Output() updateTrucks = new EventEmitter();
    @Output() delete = new EventEmitter();

    @ViewChild("millInput")
    millInput: ElementRef;

    @ViewChild("asphaltInput")
    asphaltInput: ElementRef;

    @ViewChild("tonsInput")
    tonsInput: ElementRef;

    selectedMill: Asphaltmill;
    selectedAsphalt: AsphaltmillAsphalt;
    filteredMills: Observable<Asphaltmill[]>;
    filteredAsphalt: Observable<AsphaltmillAsphalt[]>;
    form: FormGroup;
    asphaltText = "";

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['timeList'] && this.form) {
            if (this.planningAsphalt.time) {
                const begin = new Date(this.mainPlanning.begindate);
                begin.setUTCHours(begin.getUTCHours() - Settings.ASPHALT_SUBSTRACT_HOURS);
                const paTime = new Date(this.planningAsphalt.time).getTime();
                if (paTime < begin.getTime() || paTime > this.mainPlanning.enddate.getTime()) {
                    this.planningAsphalt.time = null;
                    this.form.get('time').setValue(null);
                }
            }
        }
        if (this.asphaltMills) {
            this.doInit();
        }
    }

    public validateForms(daysDiff: number = 0): Promise<boolean> {
        if (daysDiff) {
            const newBeginDate = new Date(this.planningAsphalt.time);
            newBeginDate.setDate(newBeginDate.getDate() + daysDiff);
            this.planningAsphalt.time = newBeginDate;
            this.form.get('time').setValue(newBeginDate.getTime());
        }
        return Utils.triggerValidationP(this.form);
    }

    ngOnInit() {

    }

    doInit() {
        this.createForm();
        this.millSelection(this.planningAsphalt.asphaltmill_id);
        this.asphaltSelection(this.planningAsphalt.asphaltmill_asphalt_id);

        this.filteredMills = this.form.valueChanges
            .pipe(startWith(null))
            .pipe(map(val => {
                const millText = val ? val.mill : '';
                return this.asphaltMills.filter((asphaltMill) => new RegExp(millText, 'gi').test(asphaltMill.name))
            }));
        this.filteredAsphalt = this.form.valueChanges
            .pipe(startWith(null))
            .pipe(map(val => {
                let asphaltText = val ? val.asphaltname : null;
                if (this.selectedAsphalt && val && val.asphaltname === this.selectedAsphalt.asphalt.name) {
                    asphaltText = null;
                }
                if (!asphaltText) {
                    return this.selectedMill.asphalt;
                }
                return this.selectedMill.asphalt.filter((asphalt) =>
                    (!asphalt.deleted_at || Utils.getTimeOrNull(asphalt.deleted_at) > Utils.getTimeOrNull(this.mainPlanning.begindate)) && new RegExp(asphaltText, 'gi').test(asphalt.asphalt.name + ' ' + asphalt.code))
            }));
        if (!this.planningAsphalt.asphaltmill_id) {
            setTimeout(() => {
                this.millInput?.nativeElement.focus();
            });
        }

        this.updateStatus(this.planning.status_id);
    }

    asphaltTextChanged(event) {
        this.asphaltText = event.target.value;
        if (this.selectedAsphalt && event.target.value.length === 0) {
            this.form.get('asphaltname').setValue(this.asphaltText = this.selectedAsphalt.asphalt.name);
        } else {
            this.form.get('asphaltcode').setValue('');
        }
    }

    millSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.millSelection(event.option.value['id']);
        setTimeout(() => {
            this.asphaltInput.nativeElement.focus();
        });
    }

    asphaltSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.asphaltSelection(event.option.value['id']);
        if (this.selectedAsphalt) {
            this.form.get('asphaltname').setValue(this.asphaltText = this.selectedAsphalt.asphalt.name);
            this.form.get('asphaltcode').setValue(this.selectedAsphalt.code);
            this.tonsInput.nativeElement.focus();
        }
    }

    millSelection(asphaltMillId) {
        this.selectedMill = this.asphaltMills.find(mill => mill.id === asphaltMillId);
        if (this.selectedMill) {
            this.form.get('asphaltmill_id').setValue(this.selectedMill.id);
            this.filteredAsphalt = of(this.selectedMill.asphalt.filter((asphalt) =>
                (!asphalt.deleted_at || Utils.getTimeOrNull(asphalt.deleted_at) > Utils.getTimeOrNull(this.mainPlanning.begindate))));
        }
    }

    asphaltSelection(asphaltId) {
        if (asphaltId && this.selectedMill) {
            this.selectedAsphalt = this.selectedMill.asphalt.find(asphalt => asphalt.id === asphaltId);
            if (this.selectedAsphalt) {
                this.form.get('asphaltmill_asphalt_id').setValue(this.selectedAsphalt.id);
            } else {
                this.form.get('asphaltmill_asphalt_id').setValue(null);
            }
        }
    }


    emptystring() {
        return '';
    }

    public removeAsphaltPlanning() {
        this.delete.emit();
    }

    public updateStatus(status_id: number) {
        const timeControl = this.form.get('time');
        // When PlanningStatus.bakvanwessel, date should be empty
        if (status_id === PlanningStatus.bakvanwessel) {
            this.planningAsphalt.time = null;
            timeControl.setValue(null);
            timeControl.disable();
        } else {
            // When previous state was PlanningStatus.bakvanwessel
            timeControl.enable();
        }
    }

    private createForm(): FormGroup {
        this.form = new FormGroup({
            asphaltmill_id: new FormControl(this.planningAsphalt.asphaltmill_id, Validators.required),
            asphaltname: new FormControl(this.planningAsphalt.asphaltname, Validators.required),
            asphaltmill_asphalt_id: new FormControl(this.planningAsphalt.asphaltmill_asphalt_id),
            asphaltcode: new FormControl(this.planningAsphalt.asphaltcode, Validators.required),
            tons: new FormControl(this.planningAsphalt.tons, Validators.required),
            thickness: new FormControl(this.planningAsphalt.thickness, Validators.required),
            time: new FormControl(Utils.getTimeOrNull(this.planningAsphalt.time), RequiredRule(this)),
            trucks: new FormControl(this.planningAsphalt.trucks, Validators.required),
            comment: new FormControl(this.planningAsphalt.comment, Validators.maxLength(300)),
        });
        this.asphaltText = this.planningAsphalt.asphaltname;
        this.form.valueChanges.pipe(debounceTime(25)).subscribe(formValues => {
            for (const [key, value] of Object.entries(formValues)) {
                if (value && key === 'time') {
                    if (typeof value === 'number') {
                        this.planningAsphalt[key] = new Date(value);
                    } else {
                        this.planningAsphalt[key] = null;
                    }
                    this.reOrder.emit();
                } else {
                    this.planningAsphalt[key] = value;
                }
            }
            this.updateTrucks.emit();
        });
        if (this.formsDisabled) {
            setTimeout(() => {
                this.form.disable();
            });
        }
        return this.form;
    }

}
