<table [class.disabled]="formsDisabled" [class.table-last-col]="!formsDisabled" *ngIf="!formsDisabled" class="table table-center table-add-row table-last-col">
    <tr>
        <th class="wiper-selection" colspan="3">Materieel</th>
        <th class="type-vzw">Type vzw</th>
        <th *ngIf="UserType.WORKPLANNER | hasUserGroup">Machinist</th>
        <th class="tabledate">Datum</th>
        <th class="tabletime">Tijdsblok</th>
        <th class="comment">Wegdekreiniger</th>
        <th class="comment">Opmerking</th>
        <th *ngIf="!formsDisabled"></th>
    </tr>
    <tr *ngFor="let planning of planningEntitiesList" [formGroup]="planningEntitiesForm.get(planning)">
            <td [attr.colspan]="!planning.entity || planning.entity.use_once ? '3': '1'"
                [class.use-multiple]="planning.entity && !planning.entity.use_once"
                [matTooltip]="planning?.entity?.name"
                class="wiper-selection">
                <div class="d-flex">
                    <mat-select formControlName="entity_id" placeholder="veeg/zuigwagen">
                        <mat-option *ngFor="let wipetruck of entities"
                                    [disabled]="wipetruck.notAvailable || (wipetruck.use_once && wipetruck.usedHere && wipetruck.id !== planningEntitiesForm.get(planning).get('entity_id').value)"
                                    [value]="wipetruck.id">
                            {{wipetruck.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </td>
            <td *ngIf="planning.entity && !planning.entity.use_once" class="wiper-selection">
                <div class="d-flex">
                    <mat-select formControlName="hiring_id" placeholder="Inhuur">
                        <mat-option *ngFor="let hiring of hiringList" [value]="hiring.id">
                            {{hiring.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </td>
            <td *ngIf="planning.entity && !planning.entity.use_once" class="text-input hire-name">
                <input autocomplete="off" formControlName="name" matInput placeholder="Naam" type="text">
            </td>
        <td class="type-vzw">
            <input type="text"
                   placeholder="Type veeg- zuigwagen"
                   aria-label="Type veeg- zuigwagen"
                   matInput
                   formControlName="type"
                   [matAutocomplete]="typeAutocomplete">
            <mat-autocomplete autoActiveFirstOption #typeAutocomplete="matAutocomplete">
                <mat-option value="RAVO">RAVO</mat-option>
                <mat-option value="RAVO 3e borstel">RAVO 3e borstel</mat-option>
                <mat-option value="RAVO rolbezem">RAVO rolbezem</mat-option>
                <mat-option value="RAVO reiniger">RAVO reiniger</mat-option>
                <mat-option value="VZW klein">VZW klein</mat-option>
                <mat-option value="VZW rolbezem">VZW rolbezem</mat-option>
                <mat-option value="VZW groot rolbezem">VZW groot rolbezem</mat-option>
                <mat-option value="VZW-WDR">VZW-WDR</mat-option>
                <mat-option value="VZW-WDR rolbezem">VZW-WDR rolbezem</mat-option>
            </mat-autocomplete>
        </td>
        <td *ngIf="UserType.WORKPLANNER | hasUserGroup"
            [matTooltip]="planning.driver_user_id ? (drivers | byId: planning.driver_user_id)?.name : planning?.driver"
            class="select driver">
            <ng-select
                placeholder="Chauffeur"
                formControlName="driver_user_id">
                <ng-option [value]="user.id" *ngFor="let user of drivers" [innerHTML]="user.name"></ng-option>
            </ng-select>
        </td>
        <ng-container formGroupName="date">
            <td>
                <input (focus)="pickerStart.open()"
                       (click)="pickerStart.open()"
                       [matDatepicker]="pickerStart"
                       formControlName="date"
                       matInput
                       [max]="mainPlanning.enddate"
                       [min]="minDatePicker"
                       placeholder="Datum">
                <mat-datepicker #pickerStart></mat-datepicker>
            </td>
            <td [class.error-background]="planningEntitiesForm.get(planning).errors && planningEntitiesForm.get(planning).errors.notAvailable"
                [matTooltip]="'wipetrucktime' + (planningEntitiesForm.get(planning).get('entity_id').value ? '' : '-na') | tt" class="tabletime">
                <div class="timepicker">
                    <mat-select formControlName="begintime" placeholder="van">
                        <mat-select-trigger>
                            <small>{{planning.begindate | date : 'EEE'}}</small> {{planning.begindate | date : 'HH:mm'}}
                        </mat-select-trigger>
                        <mat-optgroup
                            *ngFor="let day of startTimesEntity.get(planning)"
                            label="{{day.datetime | date : 'EEEE d MMM'}}">
                            <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                    <span>-</span>
                    <mat-select formControlName="endtime" placeholder="tot">
                        <mat-select-trigger>
                            <small>{{planning.enddate | date : 'EEE'}}</small> {{planning.enddate | date : 'HH:mm'}}
                        </mat-select-trigger>
                        <ng-container *ngIf="endTimesEntity.has(planning)">
                            <mat-optgroup
                                *ngFor="let day of endTimesEntity.get(planning)"
                                label="{{day.datetime | date : 'EEEE d MMM'}}">
                                <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                    {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                </div>
            </td>
        </ng-container>
        <td [matTooltip]="planning?.wdr" class="text-input">
            <input autocomplete="off" formControlName="wdr" matInput maxlength="300" placeholder="Wegdekreiniger" type="text">
        </td>
        <td [matTooltip]="planning?.comment" class="text-input">
            <input autocomplete="off" formControlName="comment" matInput maxlength="300" placeholder="Opmerking" type="text">
        </td>
        <td *ngIf="!formsDisabled">
            <button (click)="removeEntityPlanning(planning)"
                    class="delete-button"
                    color="warn"
                    mat-icon-button
                    matTooltip="Verwijder"
                    matTooltipClass="warn-background"
                    matTooltipPosition="right"
                    tabindex="-1">
                <i class="fas fa-trash"></i>
            </button>
        </td>
    </tr>
    <tr [hidden]="formsDisabled">
        <td [colSpan]="(UserType.WORKPLANNER | hasUserGroup) ? 9 : 8">
            <button (click)="addEntityPlanning()" *ngIf="!addButtonDisabled" class="w-100"
                    color="primary" mat-button>
                Veeg-/zuigwagen toevoegen <i class="fas fa-plus"></i>
            </button>
            <div *ngIf="addButtonDisabled" [class.warn-color]="warnIconLightUp" class="p-2">
                <i class="fas fa-exclamation-triangle"></i> Er zijn geen veeg-/zuigwagens beschikbaar
            </div>
        </td>
        <td *ngIf="!formsDisabled">

        </td>
    </tr>
</table>
<table class="table table-center mb-0" *ngIf="formsDisabled">
    <tr>
        <th class="wiper-selection">Type</th>
        <th width="100">Datum</th>
        <th class="tabletime">Tijdsblok</th>
        <th>Wegdekreiniger</th>
        <th>Opmerking</th>
    </tr>
    <tr *ngFor="let wipetruck of planning.planning_wipetrucks">
        <td *ngIf="(entities | byId:wipetruck.entity_id)?.use_once">
            {{(entities | byId:wipetruck.entity_id)?.name}}
        </td>
        <td *ngIf="!(entities | byId:wipetruck.entity_id)?.use_once">
            {{wipetruck.name}}
        </td>
        <td>{{wipetruck.begindate | date:'EEE dd MMM'}}</td>
        <td>{{wipetruck.begindate | date:'H:mm'}} - {{wipetruck.enddate | date:'H:mm'}}</td>
        <td>{{wipetruck.wdr}}</td>
        <td>{{wipetruck.comment}}</td>
    </tr>
</table>
