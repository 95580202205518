<div (click)="setFixed.emit(day)"
     (mouseenter)="mouseEnter(day)"
     (mouseleave)="mouseLeave(day)"
     [class.fixed-right]="viewModel.lockPlanning"
     [class.fixed]="day.fixed"
     [class.print]="print"
     [class.nonfocussedweek]="isNonFocussedWeek"
     class="date">
    {{day.date | date : 'EEEE'}}
    <span class="datenumber">
        {{day.date | date : 'd MMM'}}
    </span>
</div>
<app-entity
    (openPlanning)="open($event)"
    *ngFor="let entity of day.entities; let i = index"
    [class.no-plannings]="!entityIdPlanningCount.get(entity.entity.id)"
    [class.big]="entity.entity.entitytypes[0]?.is_team"
    [class.force-wide]="entity.entity.entitytypes[0]?.force_wide"
    [class.curpoint]="!isFixed"
    [class.divider]="entity.entity.entitytypes[0]?.id !== day.entities[i+1]?.entity.entitytypes[0]?.id"
    [date]="day.date"
    [isFixed]="isFixed"
    [item]="entity"
    [viewModel]="viewModel"></app-entity>
<div class="bottom">
    <app-trucks [print]="print" [date]="day.date" [fromDate]="fromDate" [matTooltip]="'trucks' | tt" [toDate]="toDate"></app-trucks>
    <div class="tons">
        <app-tons [print]="print" [date]="day.date" [fromDate]="fromDate" [matTooltip]="'tons' | tt" [toDate]="toDate"></app-tons>
    </div>
</div>
