import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationService} from '../services/realisation.service';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../utils.class';
import {TimePickerService} from '../services/time-picker.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {RealisationHourtype} from '../classes/realisation';
import {tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {AfasService} from '../services/afas.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {FormControl} from '@ngneat/reactive-forms';

@Component({
    selector: 'app-day-check-realisations',
    templateUrl: './day-check-realisations.component.html',
    styleUrls: ['./day-check-realisations.component.scss']
})
export class DayCheckRealisationsComponent extends CodaltComponent implements OnInit {

    date: Date;
    userRealisationMap = new Map<number, UserRealisations>();
    users: User[];
    show = true;
    fcDate = new FormControl();

    constructor(private activatedRoute: ActivatedRoute,
                private timePickerService: TimePickerService,
                private dialog: MatDialog,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private planningService: PlanningService,
                private afasService: AfasService,
                private userService: UserService,
                private title: Title,
                private realisationService: RealisationService) {
        super();
        this.title.setTitle('Dagcontrole' + environment.titleAppend);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string }) => {
            const today = new Date();
            const paramDate = params.date;
            this.date = paramDate ? new Date(paramDate) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));
            this.fcDate.patchValue(this.date);

            this.getData();
        }));

        this.subscriptions.add(this.fcDate.valueChanges.subscribe(value => {
            if (value && Utils.dateString(value as any) !== Utils.dateString(this.date)) {
                this.router.navigateByUrl(`dagcontrole/${formatDate(value as any, 'yyyy-MM-dd', 'nl')}`);
            }
        }));
    }

    private getUserRealisation(userId: number) {
        const user = this.users.find(u => u.id === userId);
        if (user) {
            let ur = this.userRealisationMap.get(userId);
            if (!ur) {
                ur = {
                    worktime: 0,
                    break: 0,
                    traveltime: 0,
                    drivingtime: 0,
                    leavetime: 0,
                    sleep: 0,
                    illnesstime: 0,
                    projects: []
                } as UserRealisations;
                this.userRealisationMap.set(userId, ur);
            }
            return ur;
        }
    }

    private getData() {
        const users$ = this.userService.getList(false, false, false).pipe(tap(users => {
            this.users = users.filter(user => !!user.afas_employee_id);
        }));
        const scheduleDay$ = this.realisationService.getDaySchedule(this.date);
        const afasSchedule$ = this.afasService.getAanAfwezigheid(this.date, this.date);
        this.subscriptions.add(combineLatest([users$, scheduleDay$, afasSchedule$]).subscribe(([users, realisations, aanAfwezigheid]) => {
            this.userRealisationMap = new Map<number, UserRealisations>();

            aanAfwezigheid.data?.forEach(aanAfw => {
                const user = this.users.find(usr => usr.afas_employee_id === aanAfw.employeeId);
                if (user) {
                    const ur = this.getUserRealisation(user.id);
                    if (!!aanAfw.typeverzuim) {
                        ur.illnesstime += aanAfw.hours;
                    } else {
                        ur.leavetime += aanAfw.hours;
                    }
                }
            });

            realisations.data.filter(r => !r.removed).forEach(rea => {
                const user = this.users.find(u => u.id === rea.user_id);
                if (user) {
                    const ur = this.getUserRealisation(rea.user_id);

                    if (rea.hourtype === RealisationHourtype.worktime) {
                        ur.worktime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if ([RealisationHourtype.bank_holiday, RealisationHourtype.day_off, RealisationHourtype.education, RealisationHourtype.frost, RealisationHourtype.paid_leave].includes(rea.hourtype)) {
                        ur.leavetime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if ([RealisationHourtype.illness, RealisationHourtype.short_absence].includes(rea.hourtype)) {
                        ur.illnesstime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (rea.hourtype === RealisationHourtype.driving_to || rea.hourtype === RealisationHourtype.driving_back) {
                        ur.drivingtime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (rea.hourtype === RealisationHourtype.travel_to || rea.hourtype === RealisationHourtype.travel_back) {
                        ur.traveltime += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    if (rea.hourtype === RealisationHourtype.sleep) {
                        ur.sleep += Utils.minuteDuration(rea.enddate, rea.begindate);
                    }
                    const name = Utils.realisationAfasProjectId(rea) ?? rea.leave?.comment;
                    if (ur.projects.indexOf(name) === -1) {
                        ur.projects.push(name);
                    }

                    ur.break += rea.pause;
                    ur.pause_adjusted = ur.pause_adjusted || rea.pause_adjusted;
                    ur.allSubmitted = ur.allSubmitted || !!rea.submitted;
                    ur.allApproved = ur.allApproved || !!rea.approved;
                    const back24h = new Date();
                    back24h.setDate(back24h.getDate() - 1);
                    ur.adjustable = ur.adjustable || UserService.userHasRights(UserType.HOUR_REGISTER, user);
                    ur.adjustable_time = ur.adjustable_time || (back24h.getTime() < Utils.getTimeOrNull(rea.begindate));
                }
            });

        }));
    }

    next() {
        this.date.setDate(this.date.getDate() + 1);
        this.router.navigateByUrl(`dagcontrole/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        this.router.navigateByUrl(`dagcontrole/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    openHours(date: Date, user: User) {
        this.router.navigate([
            `/hours/${formatDate(date, 'yyyy-MM-dd', 'nl')}/${user.id}`,
            {backTo: `/dagcontrole/${formatDate(date, 'yyyy-MM-dd', 'nl')}`}
        ]);
    }

}

interface UserRealisations {
    worktime: number;
    break: number;
    traveltime: number;
    sleep: number;
    drivingtime: number;
    leavetime: number;
    illnesstime: number;
    pause_adjusted: boolean;
    projects: string[];
    allSubmitted: boolean;
    allApproved: boolean;
    adjustable: boolean;
    adjustable_time: boolean;
}
