<div class="schedule-navbar">
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content">
        <p class="large">Week {{beginDate | date : 'yyyy - w'}}</p>
        <p class="small">{{beginDate | date : 'dd-MMM'}} - {{endDate | date : 'dd-MMM'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="hours-check first d-print-none">
    <div class="header">
        <div class="container-xl">
            <div class="content row">
                <div class="col d-flex justify-content-center">
                    <mat-form-field appearance="outline" *ngIf="userFunctions">
                        <mat-label>Filter op functie</mat-label>
                        <mat-select multiple [formControl]="fcUserFunction">
                            <mat-option *ngFor="let userFunction of userFunctions" [value]="userFunction">
                                {{userFunction}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col d-flex justify-content-center">
                    <mat-form-field appearance="outline" *ngIf="userFunctions">
                        <mat-label>Sortering</mat-label>
                        <mat-select [formControl]="fcSort">
                            <mat-option value="name">
                                Naam
                            </mat-option>
                            <mat-option value="hours">
                                Uren
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hours-check first last">
    <div class="list" *ngIf="show">
        <div *ngFor="let user of users; let even = even"
             [class.warn]="!even"
             class="list-item">
            <div class="container-fluid">
                <div class="content">
                    <div class="name-wrapper">
                        <div class="name">
                            {{user.name}}
                        </div>
                        <div class="caption">
                            {{user.function}}
                        </div>
                    </div>
                    <div class="times">
                        <div class="time" [class.downlight]="userTotal.get(user.id) < 1">
                            <div>
                                <span *ngIf="userTotal.has(user.id)">{{userTotal.get(user.id) | minutes}}</span>
                                <i class="fa fa-loader fa-spin" *ngIf="!userTotal.has(user.id)"></i>
                            </div>
                        </div>
                        <ng-container *ngFor="let loopdate of loopDates">
                            <div class="time"
                                 (dblclick)="editHours(loopdate, user.id)"
                                 [class.downlight]="userRealisationMap.get((loopdate | date : 'yyyy-MM-dd') + user.id)?.totaltime < 1"
                                 [class.approved]="!userRealisationMap.get((loopdate | date : 'yyyy-MM-dd') + user.id)?.anyUnapproved">
                                <div [class.night]="userRealisationMap.get((loopdate | date : 'yyyy-MM-dd') + user.id)?.isNight">
                                    <span class="prepend">{{loopdate | date : 'EEE'}}</span>
                                    <span>{{userRealisationMap.get((loopdate | date : 'yyyy-MM-dd') + user.id)?.totaltime | minutes}}</span>
                                </div>
                                <small>{{userRealisationMap.get((loopdate | date : 'yyyy-MM-dd') + user.id)?.projects?.join(', ')}}</small>
                            </div>
                        </ng-container>

                    </div>
                    <div class="buttons d-print-none">
                        <button mat-icon-button
                                color="primary"
                                (click)="editHours(beginDate, user.id)"
                                class="planning-button">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button mat-raised-button color="accent" class="planning-button" routerLink="/dagcontrole/{{beginDate | date : 'yyyy-MM-dd'}}">
                Dagcontrole
            </button>
            <button mat-raised-button color="accent" class="planning-button" routerLink="/">
                Planning
            </button>
            <button mat-raised-button color="primary" class="planning-button" routerLink="/check-hours/{{beginDate | date : 'yyyy-MM-dd'}}">
                Urencontrole
            </button>
        </div>
    </div>
</div>
