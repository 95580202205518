import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {Utils} from '../utils.class';

@Pipe({
    name: 'checkHoursFilter'
})
export class CheckHoursFilterPipe implements PipeTransform {

    transform(values: Realisation[], functions: string[], hourtypes: string[], hideApproved: boolean, forceCheckCounter: number): Realisation[] {
        return Utils.filterRealisations(values, functions, hourtypes, hideApproved);
    }

}
