import {FormControl} from '@angular/forms';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Utils} from '../utils.class';
import {first, map} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {UserPlanningService} from '../services/planning/user-planning.service';
import {PlanningHasService} from '../services/planning/planning-has.service';

export const localUserAvailableValidator = (planning?: PlanningHasEntity,
                                            userPlanningService?: UserPlanningService,
                                            planningHasService?: PlanningHasService,
                                            clearWhenInvalid?: boolean) => {
    return (input: FormControl) => {
        if (planning?.begindate && planning.enddate && input.value) {
            const begindate = Utils.getTimeOrNull(planning?.begindate);
            const enddate = Utils.getTimeOrNull(planning?.enddate);
            let res = false;
            return combineLatest(
                userPlanningService.getFilteredList(Utils.newDate(planning.begindate), Utils.newDate(planning.enddate)),
                planningHasService.getFilteredList(Utils.newDate(planning.begindate), Utils.newDate(planning.enddate))
            ).pipe(first(), map(([userPlannings, planningHass]) => {
                planningHass.forEach(planningHas => {
                    if (planningHas.id !== planning.id) {
                        if (planningHas.driver_user_id === input.value) {
                            if (Utils.getTimeOrNull(planningHas.begindate) < enddate && Utils.getTimeOrNull(planningHas.enddate) > begindate) {
                                res = true;
                            }
                        }
                    }
                });

                userPlannings.forEach(userPlanning => {
                    if (userPlanning.user_id === input.value) {
                        if (Utils.getTimeOrNull(userPlanning.begindate) < enddate && Utils.getTimeOrNull(userPlanning.enddate) > begindate) {
                            res = true;
                        }
                    }
                });
                if (clearWhenInvalid && res) {
                    input.reset();
                    res = false;
                }
                return res ? {notAvailable: res} : null;
            }));
        }
        return of(null);
    };
};
