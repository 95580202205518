import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {formatNumber} from '@angular/common';

import {MatDialog} from '@angular/material/dialog';
import {Settings} from '../settings.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {FileService, FileStorage} from '../services/file.service';

@Component({
    selector: 'app-codalt-upload-image',
    templateUrl: './codalt-upload-image.component.html',
    styleUrls: ['./codalt-upload-image.component.scss']
})
export class CodaltUploadImageComponent implements OnInit {

    @ViewChild('insertLinkTemplate', {static: true}) insertLinkTemplate;
    linkInput: string;

    @Input() fileStorage = FileStorage.TinyImages;
    @Input() width: number;
    @Input() height: number;
    @Input() name: string;
    @Input() resizedSize: number;
    @Input() resizedSizeThumb = 50;
    @Input() fcImage: AbstractControl;
    @Input() fcImageThumb: AbstractControl;
    @Input() fcOffsetX: AbstractControl;
    @Input() fcOffsetY: AbstractControl;
    @Input() fcNoOverScale: AbstractControl;
    @Input() fcMaintainAspectRatio: AbstractControl;
    @Input() urlName: string;
    @Input() outputBase64 = false;
    @Input() hideControls = false;
    @Output() submitted = new EventEmitter<File>();

    offsetXStart = 50;
    offsetYStart = 50;
    loading = false;

    moving = false;

    linkDialog;

    constructor(private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private ngZone: NgZone,
                private fileService: FileService) {
    }

    get fcMaintainAspectRatioControl() {
        return this.fcMaintainAspectRatio as FormControl;
    }

    get fcNoOverScaleControl() {
        return this.fcNoOverScale as FormControl;
    }

    ngOnInit(): void {
    }

    panStart() {
        this.offsetXStart = this.fcOffsetX.value;
        this.offsetYStart = this.fcOffsetY.value;
    }

    labelClick(event: any) {
        if (this.moving) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    panMove(event: any) {
        this.moving = true;
        let newOffsetX = this.offsetXStart + ((100 / this.width) * event.deltaX * -1);
        newOffsetX = Math.min(100, newOffsetX);
        newOffsetX = Math.max(0, newOffsetX);
        this.fcOffsetX.setValue(newOffsetX);

        let newOffsetY = this.offsetYStart + ((100 / this.height) * event.deltaY * -1);
        newOffsetY = Math.min(100, newOffsetY);
        newOffsetY = Math.max(0, newOffsetY);
        this.fcOffsetY.setValue(newOffsetY);
    }

    panEnd() {
        setTimeout(() => {
            this.moving = false;
        }, 300);
    }

    reuseImageAfterConfirm(file: File) {
        this.confirmDialogService.confirm(
            'Afbeelding overnemen',
            `Wil je de afbeelding ook gebruiken voor ${this.name || 'een andere positie'}?`,
            'Overnemen',
            'Niet overnemen').then(
            () => {
                this.uploadImage(file);
            }, () => {
            });
    }

    loadLinkDialog() {
        this.linkDialog = this.matDialog.open(this.insertLinkTemplate);
        const sub = this.linkDialog.afterClosed().subscribe(link => {
            if (link) {
                const imageObj = new Image();
                const c = document.createElement("canvas");
                const ctx = c.getContext("2d");

                if (!imageObj.complete) {
                    this.loading = true;
                }
                imageObj.onload = () => {
                    c.width = imageObj.naturalWidth;
                    c.height = imageObj.naturalHeight;
                    ctx.drawImage(imageObj, 0, 0);
                    const extension = link.split('.').reverse()[0];
                    let mime = 'image/jpeg';
                    if (extension === 'png') {
                        mime = 'image/png';
                    }
                    c.toBlob((blob) => {
                        blob['name'] = 'test';
                        this.uploadImage(blob as File);
                        this.insertLinkTemplate.close();
                    }, mime, 1);
                };
                imageObj.crossOrigin = "";
                imageObj.src = this.linkInput;
            }
            sub.unsubscribe();
        });
    }

    submit(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.srcElement.files[0];
        if (file) {
            this.submitted.emit(file);
            this.uploadImage(file);
        }
    }

    uploadImage(file: File) {
        this.loading = true;
        const maxSizeMB = 90;
        if (file.size > 100 * 1024 * 1024) {
            this.confirmDialogService.confirm(
                'Bestand te groot',
                `Dit bestand is groter als ${formatNumber(maxSizeMB, 'nl')} MB`,
                'Oké',
                null
            ).then(() => {
                this.loading = false;
            });
        } else if (file.type === 'image/svg+xml') {
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = (evt) => {
                if (this.fcImageThumb) {
                    this.fcImageThumb.setValue(`data:image/svg+xml;base64,${window.btoa(evt.target.result + '')}`);
                }
            };
            reader.onerror = (evt) => {
            };

            this.fileService.upload(file).subscribe((fileResponse) => {
                this.ngZone.run(() => {
                    this.fcImage.setValue(Settings.RESOURCES_ENDPOINT + 'article/' + fileResponse.data.file);
                    this.loading = false;
                });
            });
        } else if (['image/jpeg', 'image/png'].indexOf(file.type) !== -1 && file.size) {
            const doResizes = (convertToJpg = false) => {
                if (this.fcImageThumb) {
                    this.makeThumb((base64preview) => {
                        this.fcImageThumb.setValue(base64preview);
                    }, file, this.resizedSizeThumb, false, convertToJpg);
                }
                if (this.outputBase64) {
                    this.makeThumb((resizedBlob) => {
                        this.fcImage.setValue(resizedBlob);
                        this.loading = false;
                    }, file, this.resizedSize, false, convertToJpg);
                } else {
                    this.makeThumb((resizedBlob) => {
                        const getWithJpgExtension = (name: string) => {
                            const splitted = name.split('.');
                            splitted.pop();
                            return splitted.join('-') + '.jpg';
                        };
                        resizedBlob['name'] = convertToJpg ? getWithJpgExtension(file.name) : file.name;
                        this.fileService.upload(resizedBlob as File, this.fileStorage).subscribe((fileResponse) => {
                            this.ngZone.run(() => {
                                this.fcImage.setValue(Settings.RESOURCES_ENDPOINT + this.fileStorage + '/' + fileResponse.data.file);
                                this.loading = false;
                            });
                        });
                    }, file, this.resizedSize, true, convertToJpg);
                }
            };

            if (file.type == 'image/png') {
                this.confirmDialogService.confirm(
                    'PNG geüpload',
                    `Je hebt een PNG geüpload. Kies het gewenste formaat.<br><br>PNG: logo's en abstracte beelden <br>JPG: foto's`,
                    'Converteren naar JPG',
                    'PNG behouden'
                ).then(() => {
                    doResizes(true);
                }, () => {
                    doResizes();
                });
            } else {
                doResizes();
            }
        } else {
            this.confirmDialogService.confirm(
                'Verkeerd bestand',
                'Dit bestandsformaat wordt niet ondersteund',
                'Oké',
                null
            ).then(() => {
                this.loading = false;
            });
        }
    }

    private makeThumb(callback: (file: Blob | string) => void, inpFile: File, size: number, blob = false, convertToJpg = false) {
        let resizedImage;
        const img = new Image();
        img.src = window?.URL.createObjectURL(inpFile);
        img.onload = () => {
            const factor = size / Math.max(img.naturalWidth, img.naturalHeight);
            const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
            const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
            const elem = document.createElement('canvas');
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            if (blob) {
                let blobFile = null;
                ctx.canvas.toBlob((blobFileC) => {
                    blobFile = blobFileC;
                    callback(blobFileC);
                }, 'image/jpeg', .7);
            } else {
                if (inpFile.type === 'image/png' && !convertToJpg) {
                    resizedImage = ctx.canvas.toDataURL('image/png');
                } else {
                    resizedImage = ctx.canvas.toDataURL('image/jpeg', 0.7);
                }
                callback(resizedImage);
            }
        };
    }

}
