<div class="modal-inner">
    <h1 class="mb-2">Wijzigingshistorie urenregel #{{data.realisation.id}}</h1>
    <h2>
        {{data.realisation.hourtype | hourtype}}:
        {{data.realisation.begindate | date : 'EEE d MMMM yyyy HH:mm'}} -
        {{data.realisation.enddate | date : 'EEE HH:mm'}}
    </h2>

    <table class="table table-bordered table-striped table-hover">
        <thead>
        <th>Gewijzigd</th>
        <th>Door</th>
        <th>Duur</th>
        <th>Pauze</th>
        <th>Starttijd</th>
        <th>Eindtijd</th>
        <th>Ingediend</th>
        <th>Goedgekeurd</th>
        <th>Verwijderd</th>
        <th>Toelichting gebr.</th>
        <th>Toelichting uitv.</th>
        </thead>
        <tbody>
        <tr *ngFor="let history of histories">
            <td>{{history.updated_at  | date : 'HH:mm:ss, EEE d MMMM yyyy'}}</td>
            <td>{{history['modified_by_user']}}</td>
            <td class="text-right">{{Utils.minuteDuration(history.enddate, history.begindate) | minutes}}</td>
            <td>{{history.pause | minutes}}</td>
            <td>{{history.begindate  | date : 'EEE HH:mm'}}</td>
            <td>{{history.enddate  | date : 'EEE HH:mm'}}</td>
            <td>{{history.submitted  | date : 'EEE d MMMM yyyy HH:mm'}}</td>
            <td>{{history.approved  | date : 'EEE d MMMM yyyy HH:mm'}}</td>
            <td>{{history.removed  | date : 'EEE d MMMM yyyy HH:mm'}}</td>
            <td>{{history.comment_user}}</td>
            <td>{{history.comment_manager}}</td>
        </tr>
        </tbody>
    </table>
</div>
