import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../codalt.component';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {RealisationHourtype} from '../../classes/realisation';
import {Project} from '../../classes/project.class';
import {Subscription} from 'rxjs';
import {debounceTime, startWith} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {Validators} from '@angular/forms';

@Component({
    selector: 'app-add-leave-name-dialog',
    templateUrl: './add-leave-name-dialog.component.html',
    styleUrls: ['./add-leave-name-dialog.component.scss']
})
export class AddLeaveNameDialogComponent extends CodaltComponent implements OnInit {

    form = new FormGroup({
        comment: new FormControl<string>('Personeelsplanning', Validators.required),
        project: new FormControl<Project>(null)
    });

    projects: Project[];
    fcProjectSearch = new FormControl<string>();
    searching = false;

    constructor(public dialogRef: MatDialogRef< AddLeaveNameDialogComponent, returnData>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    contextComment: string,
                    currentHourType: RealisationHourtype,
                    contextProject: Project,
                    projects: Project[]
                },
                private projectService: ProjectService) {
        super();
        this.projects = data.projects;
        this.form.controls.comment.setValue(data.contextComment);
        if (data.currentHourType === RealisationHourtype.sleep) {
            this.form.controls.project.addValidators(Validators.required);
        }
    }

    ngOnInit(): void {
        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(startWith(), debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            this.searching = true;
            afasSubs = this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
                this.searching = false;
            }, () => {
                this.searching = false;
            });
        }));
    }

    setProject(project: Project) {
        this.form.controls.project.setValue(project);
        this.dialogRef.close(this.form.value);
    }

    readonly RealisationHourtype = RealisationHourtype;
}

export interface returnData {
    comment: string,
    project: Project
}
