import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {map} from 'rxjs/operators';
import {Project} from '../classes/project.class';
import {RealtimeService} from './realtime/realtime.service';
import {RealtimeType} from './realtime/realtime-type.enum';
import {WebsocketService} from './websocket/websocket.service';
import {Utils} from '../utils.class';
import {ProjectComment} from '../classes/project-comment';
import {ProjectUsedAsphalt} from '../classes/project-used-asphalt.class';
import {ProjectCommentHistory} from '../classes/project-comment-history';

@Injectable({
    providedIn: 'root'
})
export class ProjectService extends RealtimeService<Project> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.project, 'afas_project_id');
    }

    saveProject(project: Project) {
        return this.apiService.postCall$<Project>(this.type, project);
    }

    searchProjects(search?: string) {
        let params = {};
        if (search) {
            params = {search};
        }
        return this.apiService.getCall$<Project[]>(`${this.type}/search`, params).pipe(map(projects => {
            if (projects.data?.length) {
                const mainProjects = projects.data.filter(p => !p.parent_id);
                mainProjects.forEach(mainProject => {
                    mainProject.projects = projects.data.filter(p => p.parent_id === mainProject.afas_project_id);
                    mainProject.projects.forEach(project => {
                        project.mainProject = mainProject.afas_project_id + ' - ' + mainProject.name;
                    });
                });

            }
            return projects;
        }));
    }

    getRecentProject(userId: number) {
        return this.apiService.getCall$<Project[]>(`${this.type}/recent`, {userId});
    }

    itemInDaterange(project: Project, fromDate: Date, toDate: Date) {
        // Unable to filter, because we don't have all planning_has_entities and user_plannings available
        return true;
    }

    saveProjectComment(projectComment: ProjectComment) {
        return this.apiService.postCall$<ProjectComment>(`${this.type}/comment`, projectComment);
    }

    saveProjectAsphalt(afasProjectId: string, date: Date, projectAsphalt: ProjectUsedAsphalt[]) {
        return this.apiService.postCall$<{}>(`${this.type}/asphalt`, {
            usedAsphalt: projectAsphalt,
            date,
            afasProjectId
        });
    }

    getProjectAsphalt(afasProjectId: string, date: Date) {
        return this.apiService.getCall$<ProjectUsedAsphalt[]>(`${this.type}/asphalt`, {
            date: Utils.formatDate(date),
            afasProjectId
        });
    }

    getProject(projectId: string) {
        return this.apiService.getCall$<Project>(`${this.type}/${projectId}`);
    }

    getProjectComment(id: number) {
        return this.apiService.getCall$<ProjectComment>(`${this.type}/comment`, {id});
    }

    getProjectCommentHistory(id: number) {
        return this.apiService.getCall$<ProjectCommentHistory[]>(`${this.type}/comment-history`, {id});
    }

    getProjectCommentsWeek(afasProjectId: string, date: Date, includeWeekComments = false) {
        return this.apiService.getCall$<ProjectComment[]>(`${this.type}/comment`, {
            date: Utils.formatDate(date),
            afasProjectId
        }).pipe(map(comments => {
            if (!includeWeekComments) {
                comments.data = comments.data.filter(c => !c.week);
            }
            return comments;
        }));
    }
}
