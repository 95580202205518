import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Toolbox} from '../classes/toolbox';
import {ToolboxService} from '../services/toolbox.service';
import {environment} from '../../environments/environment';
import {LocalStorage} from '../storage.class';
import {ToolboxRead} from '../classes/toolbox-read';

@Component({
    selector: 'app-view-pdf-dialog',
    templateUrl: './view-pdf-dialog.component.html',
    styleUrls: ['./view-pdf-dialog.component.scss']
})
export class ViewPdfDialogComponent extends CodaltComponent implements OnInit {

    pdfUrl: string;
    toolbox: Toolbox;
    read: ToolboxRead;
    showClose = false;
    secToClose = 6;

    constructor(public dialogRef: MatDialogRef<ViewPdfDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Toolbox,
                private toolboxService: ToolboxService) {
        super();
        this.toolbox = data;
        if (data) {
            this.pdfUrl = `${environment.apiEndpoint.replace('/backend', '')}assets/pdfjs/web/viewer.html?file=${environment.apiEndpoint}toolbox/pdf/${data.id}?access_token=${LocalStorage.getUserToken()}`;
        }
    }

    ngOnInit(): void {
        if (!this.toolbox.read) {
            const interval = setInterval(() => {
                this.secToClose--;
            }, 1000);
            setTimeout(() => {
                this.showClose = true;
                this.toolboxService.readToolbox(this.toolbox.id).subscribe(read => {
                    this.read = read.data;
                });
                clearInterval(interval);
            }, this.secToClose * 1000);

        } else {
            this.showClose = true;
        }
    }

    close() {
        this.dialogRef.close(this.read);
    }

    protected readonly history = history;
}
