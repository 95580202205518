import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CodaltComponent} from '../../../codalt.component';
import {Utils} from '../../../utils.class';
import {formatDate} from '@angular/common';
import {Realisation} from '../../../classes/realisation';
import {User} from '../../../classes/user.class';
import {ProjectService} from '../../../services/project.service';
import {ProjectComment} from '../../../classes/project-comment';
import {Project} from '../../../classes/project.class';
import {ProjectUsedAsphalt} from '../../../classes/project-used-asphalt.class';
import {EntityTypeCode} from '../../../services/entities/entity-type.class';

@Component({
    selector: 'app-project-week-report-hours',
    templateUrl: './project-week-report-hours.component.html',
    styleUrls: ['./project-week-report-hours.component.scss']
})
export class ProjectWeekReportHoursComponent extends CodaltComponent implements OnInit, OnChanges {

    @Input() @HostBinding('class.print') print = false;
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() projectId: string;
    @Input() onlyWeekTotals = false;

    year: number;
    period: number;

    @Input() realisations: Realisation[];
    employees: User[];

    @Input() field: 'entity_id' | 'user_id';

    groupTotals: GroupTotals[];
    customDates: { date: Date, abbrev: string; }[];

    @Input() weekComment: ProjectComment;
    @Output() checkChanges = new EventEmitter();

    @Output() readyforpuppeteer = new EventEmitter();

    trigger = 0;

    days: {
        aa: string,
        date: Date,
        asphalt: ProjectUsedAsphalt[],
        adhesive: ProjectUsedAsphalt[],
    }[] = [];
    project: Project;

    functionGroupMap = new Map<string, string>([
        ['', 'Uitvoerders'],
        ['Grondwerker', 'Grondwerkers'],
        ['Machinist Mobiele Kraan', 'Kraanmachinisten'],
        ['Kraanbestuurder', 'Kraanmachinisten'],
        ['Chauffeur', 'Chauffeurs'],
        ['Straatmaker I', 'Stratenmakers'],
        ['Straatmaker II', 'Stratenmakers'],
        ['Vakman Verkeer', 'Verkeersmaatregelen'],
        ['Machinist GWW', 'Frees- en veegmachinisten'],
        ['Machinist Klein Materieel', 'Frees- en veegmachinisten'],
        ['Projectleider', 'Projectleider'],
        ['Uitvoerder', 'Uitvoerders'],
        ['Vrachtwagen', 'Transport'],
        ['Dieplader', 'Transport'],
        ['GM Asfalt overig', 'Asfaltmaterieel'],
        ['GM Veeg-zuig', 'Asfaltmaterieel'],
        ['GM Frees', 'Asfaltmaterieel'],
        ['GM Asfaltmachine', 'Asfaltmaterieel'],
        ['GM Wals', 'Asfaltmaterieel'],
        ['Frezen', 'Frezen'],
        ['GM Kraan', 'Kranen en Shovels'],
        ['GM Overig', 'Div. materieel (aantal per dag)'],
        ['Wieldumper', 'Div. materieel (aantal per dag)'],
        ['Balkman', 'Asfaltploeg'],
        ['Asfalt medewerker', 'Asfaltploeg'],
        ['Balkman', 'Asfaltploeg'],
        ['Machinist asfalt', 'Asfaltploeg'],
        ['Machinist kleefwagen', 'Asfaltploeg'],
        ['Machinist wals', 'Asfaltploeg'],
        ['Walsmachinist asfalt', 'Asfaltploeg']
    ]);


    constructor(private projectService: ProjectService) {
        super();
    }


    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.realisations && this.weekComment) {
            this.realisations.forEach(r => {
                if (r.user?.function && r.user?.function.indexOf('Asfalt') !== -1) {
                    this.functionGroupMap.set(r.user?.function, 'Asfaltploeg');
                }
            });
            this.processData();
        }
    }

    processData() {
        this.groupTotals = [];
        const weekTotals = [] as WeekTotals[];
        const groupedIdentifiers = [...new Set(this.realisations.map(r => r[this.field]) || [])].filter(r => !!r);
        const bookDateCustom = new Date(this.beginDate);
        this.customDates = [];
        while (bookDateCustom.getTime() < this.endDate.getTime()) {
            this.customDates.push({
                date: new Date(bookDateCustom),
                abbrev: Utils.formatDate(bookDateCustom, 'EEE', 'en').toLowerCase()
            });
            bookDateCustom.setDate(bookDateCustom.getDate() + 1);
        }
        groupedIdentifiers.filter(entityId => entityId !== EntityTypeCode.InhuurPersoneel).forEach(identifier => {
            const bookDate = new Date(this.beginDate);
            const weekTotal = new WeekTotals();
            weekTotal.days = [];
            weekTotal.weekTotal = 0;
            const allRealisations = [];
            while (bookDate.getTime() < this.endDate.getTime()) {
                const realisations = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookDate, 'yyyy-MM-dd', 'nl') && r[this.field] === identifier);
                allRealisations.push(...realisations);
                let totalMinutes = 0;
                if (realisations?.length) {
                    realisations.forEach(realisation => {
                        totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                        totalMinutes -= realisation.pause;
                    });

                    if (this.field === 'user_id') {
                        const user = realisations[0].user;
                        weekTotal.name = user?.name;
                        weekTotal.group = this.functionGroupMap.get(user?.function) ?? user?.function;
                        weekTotal.function = user?.function;
                    } else {
                        const entity = realisations[0].entity;
                        weekTotal.name = entity.name;
                        weekTotal.group = this.functionGroupMap.get(entity?.function) ?? entity?.function;
                        weekTotal.function = entity?.function ?? realisations[0]?.hiring_name;
                    }
                }
                weekTotal.days.push({
                    total: totalMinutes,
                    date: new Date(bookDate)
                });
                weekTotal.weekTotal += totalMinutes;
                weekTotal.realisations = allRealisations;
                bookDate.setDate(bookDate.getDate() + 1);
            }
            weekTotals.push(weekTotal);
        });

        const hiringNames = [...new Set(this.realisations.map(r => r.hiring_name) || [])].filter(r => !!r);
        hiringNames.forEach(hiringName => {
            const bookDate = new Date(this.beginDate);
            const hiringTotals = new WeekTotals();
            hiringTotals.days = [];
            hiringTotals.weekTotal = 0;
            const allRealisations = [];
            while (bookDate.getTime() < this.endDate.getTime()) {
                const realisations = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookDate, 'yyyy-MM-dd', 'nl')
                    && r.hiring_name === hiringName
                );
                allRealisations.push(...realisations);
                let totalMinutes = 0;
                if (realisations?.length) {
                    realisations.forEach(realisation => {
                        totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                        totalMinutes -= realisation.pause;
                    });

                    hiringTotals.name = hiringName;
                    hiringTotals.group = realisations[0].entity.name;
                    hiringTotals.function = ' func';

                }
                hiringTotals.days.push({
                    total: totalMinutes,
                    date: new Date(bookDate)
                });
                hiringTotals.weekTotal += totalMinutes;
                hiringTotals.realisations = allRealisations;
                bookDate.setDate(bookDate.getDate() + 1);
            }
            if (hiringTotals.group === 'Inhuur personeel' && this.field === 'user_id') {
                weekTotals.push(hiringTotals);
            }
            if (hiringTotals.group === 'Inhuur materieel' && this.field === 'entity_id') {
                weekTotals.push(hiringTotals);
            }
        });

        const groups = [...new Set(weekTotals.map(r => r?.group) || [])].filter(r => !!r);
        groups.forEach(group => {
            const ugt = new GroupTotals();
            ugt.totals = weekTotals.filter(g => g.group === group);
            ugt.group = group;
            ugt.visible = !this.weekComment?.content.customs[this.field].find(ceh => ceh.group === group);
            this.groupTotals.push(ugt);
        });
        this.groupTotals = this.groupTotals.sort((a, b) => {
            return a.group.localeCompare(b.group);
        });

        setTimeout(() => {
            this.readyforpuppeteer.emit();
        });
    }

    checkChange() {
        this.checkChanges.emit();
    }

    addRow(gr: { rows: {}[] }, element: HTMLElement) {
        gr.rows.push({name: ''});
        setTimeout(() => {
            const employeenames = element.getElementsByClassName('employeename');
            (employeenames[employeenames.length - 1] as HTMLInputElement).focus();
        });
        this.checkChanges.emit();
    }


    removeRow(group: { rows: {}[] }, row: {}) {
        group.rows = group.rows.filter(r => r !== row);
        this.checkChanges.emit();
    }

    removeGroup(group: { group: string; removed: boolean }) {
        group.removed = true;
        this.trigger++;
        this.checkChanges.emit();
    }

    addCustomGroup(groups: any[]) {
        groups.push({
            rows: [],
            group: ''
        });
        this.trigger++;
        this.checkChanges.emit();
    }

    registrationsTomanual(onlyForGroup: string = null) {
        this.groupTotals.filter(ugt => !onlyForGroup || ugt.group === onlyForGroup).forEach(userGroup => {
            userGroup.visible = false;
            const customGroup =
                this.weekComment?.content?.customs[this.field].find(ceh => ceh.group === userGroup.group) ??
                {group: userGroup.group, rows: []} as any;
            if (customGroup.rows.length === 0 && !customGroup.removed) {
                this.weekComment.content.customs[this.field].push(customGroup);
            }

            delete customGroup['removed'];
            userGroup.totals.forEach(userGr => {
                const customGroupRow = {
                    name: userGr.name,
                    user_id: userGr.realisations[0].user_id,
                    entity_id: userGr.realisations[0].entity_id,
                    hiring_name: userGr.realisations[0].hiring_name,
                    mon: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[0].date)).total,
                    tue: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[1].date)).total,
                    wed: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[2].date)).total,
                    thu: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[3].date)).total,
                    fri: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[4].date)).total,
                    sat: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[5].date)).total,
                    sun: userGr.days.find(day => Utils.dateString(day.date) === Utils.dateString(this.customDates[6].date)).total
                };
                const existingRow = customGroup.rows.find(r => r[this.field] === customGroupRow[this.field] && r.hiring_name === customGroupRow.hiring_name);

                if (existingRow) {
                    Object.assign(existingRow, customGroupRow);
                } else {
                    customGroup.rows.push(customGroupRow);
                }
            });
        });
        this.trigger++;
        this.checkChanges.emit();
    }

}

class WeekTotals {
    days: {
        date: Date,
        total: number
    }[];
    weekTotal: number;
    name: string;
    group: string;
    function: string;
    realisations: Realisation[];
}

class GroupTotals {
    days: Date[];
    totals: WeekTotals[];
    group: string;
    visible: boolean;
}
