import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {UserService} from '../../services/user/user.service';
import {EntitiesService} from '../../services/entities/entities.service';
import {debounceTime, mergeAll} from 'rxjs/operators';
import {from, Subscription} from 'rxjs';
import {RealisationService} from '../../services/realisation.service';
import {ProjectService} from '../../services/project.service';
import {Utils} from 'src/app/utils.class';

@Component({
    selector: 'app-add-realisation-dialog',
    templateUrl: './edit-realisation-project-dialog.component.html',
    styleUrls: ['./edit-realisation-project-dialog.component.scss']
})
export class EditRealisationProjectDialogComponent extends CodaltComponent implements OnInit {

    RealisationHourtype = RealisationHourtype;

    projects: {
        projectId: string,
        location: string,
        planningId?: number;
    }[] = [];

    form: FormGroup<ControlsOf<EditRealisationProject>>;


    fcProjectSearch = new FormControl<string>();

    searching = false;

    night = false;

    constructor(public dialogRef: MatDialogRef<EditRealisationProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    realisations: Realisation[],
                    realisation: Realisation,
                    bookdate: Date,
                    userId?: number
                },
                private realisationService: RealisationService,
                private entityService: EntitiesService,
                private userService: UserService,
                private entitiesService: EntitiesService,
                private afprojectServicesService: ProjectService) {
        super();
        this.form = new FormGroup<ControlsOf<EditRealisationProject>>({
            projectId: new FormControl(Utils.realisationAfasProjectId(data.realisation)),
            planningId: new FormControl(data.realisation.planning_id)
        });
        this.setCurrentProjects();
    }

    private setCurrentProjects() {
        this.projects = [];
        const projects = [...new Set(this.data.realisations?.map(r => Utils.realisationAfasProjectId(r)))].filter(p => !!p);
        projects.forEach(project => {
            const realisation = this.data.realisations.find(r => r.planning?.afas_project_id === project);
            this.projects.push({
                projectId: project,
                location: realisation?.planning?.location,
                planningId: realisation?.planning?.id ?? null
            });
        });
    }

    ngOnInit(): void {
        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            if (search) {
                this.searching = true;
                afasSubs = this.afprojectServicesService.searchProjects(search).subscribe((projectResponse) => {
                    this.projects = projectResponse.data?.filter(p => !!p.parent_id).map(p => {
                        return {projectId: p.afas_project_id, location: p.name};
                    });
                    this.searching = false;
                }, () => {
                    this.searching = false;
                });
            } else {
                this.setCurrentProjects();
            }
        }));
    }

    selectProject(projectId, planningId?: number) {
        this.form.controls.projectId.setValue(projectId);
        this.form.controls.planningId.setValue(planningId);

        this.data.realisation.afas_project_id = projectId;
        this.data.realisation.planning_id = planningId;

        // All related realisations, yes also the driving and transport hours from the user
        const relatedRealisations = this.data.realisations
            .filter(er => er.planning_has_entity_id === (this.data.realisation.planning_has_entity_id ?? 'donotmapnull') ||
                er.parent_realisation_id === this.data.realisation.id)
            .filter(er => er.id !== this.data.realisation.id);

        const updateRealisations = [this.data.realisation, ...relatedRealisations];
        updateRealisations.forEach(updateRealisation => {
            updateRealisation.afas_project_id = projectId;
            updateRealisation.planning_id = planningId;
        });

        from(updateRealisations.map(ur => this.realisationService.saveRealisation(ur))).pipe(mergeAll()).subscribe(result => {
            this.dialogRef.close(true);
        });
    }

}

interface EditRealisationProject {
    projectId: string;
    planningId: number;
}
