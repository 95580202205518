import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodaltComponent} from '../../codalt.component';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {Realisation} from '../../classes/realisation';
import {RealisationService} from '../../services/realisation.service';

@Component({
    selector: 'app-history-dialog',
    templateUrl: './history-dialog.component.html',
    styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent extends CodaltComponent implements OnInit {

    form: FormGroup<ControlsOf<{ comment: string }>>;

    histories: Realisation[];

    constructor(public dialogRef: MatDialogRef<HistoryDialogComponent>,
                private realisationService: RealisationService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    realisation: Realisation
                }) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.realisationService.getHistory(this.data.realisation.id).subscribe(history => {
            this.histories = history.data;
        }));
    }

}
