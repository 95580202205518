<div class="flex-fill">
    <p>
        Er kan maximaal 1 toolbox tegelijk getoond worden
    </p>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <input (focus)="pickerStart.open()"
                       [min]="minDate"
                       [matDatepicker]="pickerStart"
                       [formControl]="form.controls.begindate"
                       matInput placeholder="Zichtbaar vanaf">
                <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
                <mat-error [control]="form.controls.begindate"></mat-error>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100">
                <input (focus)="pickerEnd.open()"
                       [min]="form.value.begindate"
                       [matDatepicker]="pickerEnd"
                       [formControl]="form.controls.enddate"
                       matInput placeholder="Zichtbaar tot">
                <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
                <mat-error [control]="form.controls.enddate"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="w-100">
                <input (focus)="pickerBefore.open()"
                       [min]="form.value.begindate"
                       [max]="form.value.enddate"
                       [matDatepicker]="pickerBefore"
                       [formControl]="form.controls.view_before"
                       matInput placeholder="Tonen voor Datum">
                <mat-datepicker-toggle [for]="pickerBefore" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerBefore></mat-datepicker>
                <mat-error [control]="form.controls.view_before"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-auto">
            <app-codalt-upload-file [fcFile]="form.controls.pdf"></app-codalt-upload-file>
            <mat-error *ngIf="form.controls.pdf?.touched" [control]="form.controls.pdf"></mat-error>
        </div>
        <div class="col" *ngIf="form.value.pdf">
            <button mat-raised-button
                    color="accent"
                    (click)="showToolbox()">
                Toon toolbox
            </button>
        </div>
    </div>
</div>
<div class="row justify-content-center pt-5">
    <div class="col-12">
        <div class="d-flex">
            <button mat-raised-button (click)="back.emit()">Terug</button>
            <div class="spacer"></div>
            <button (click)="save()" [disabled]="saving" color="primary" id="btn-save" mat-raised-button>
                Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </button>
        </div>
    </div>
</div>
