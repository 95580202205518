import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';
import {Realisation} from '../classes/realisation';

@Pipe({
    name: 'realisationAfasProjectIdPipe'
})
export class RealisationAfasProjectIdPipe implements PipeTransform {

    transform(r: Realisation): string {
        return Utils.realisationAfasProjectId(r);

    }

}
