import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProjectComment} from '../../../classes/project-comment';
import {CodaltComponent} from '../../../codalt.component';
import {ProjectService} from '../../../services/project.service';
import {ProjectCommentHistory} from '../../../classes/project-comment-history';

@Component({
    selector: 'app-week-comment-history-dialog',
    templateUrl: './week-comment-history-dialog.component.html',
    styleUrls: ['./week-comment-history-dialog.component.scss']
})
export class WeekCommentHistoryDialogComponent extends CodaltComponent implements OnInit {

    comments: ProjectCommentHistory[];
    comment: ProjectComment;
    commentId: number;

    constructor(public dialogRef: MatDialogRef<WeekCommentHistoryDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: {
                    comment: ProjectComment
                },
                private projectService: ProjectService) {
        super();
        this.commentId = data.comment.id;
        this.comment = data.comment;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.projectService.getProjectCommentHistory(this.commentId).subscribe(result => {
            this.comments = result.data;
        }));
    }

}
