import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToolboxService} from '../services/toolbox.service';
import {CodaltComponent} from '../codalt.component';
import {ToolboxRead} from '../classes/toolbox-read';
import {Toolbox} from '../classes/toolbox';

@Component({
    selector: 'app-toolbox-read-dialog',
    templateUrl: './toolbox-read-dialog.component.html',
    styleUrls: ['./toolbox-read-dialog.component.scss']
})
export class ToolboxReadDialogComponent extends CodaltComponent implements OnInit {

    readBy: ToolboxRead[];

    constructor(private toolboxService: ToolboxService,
                @Inject(MAT_DIALOG_DATA) public data: Toolbox,
                public dialogRef: MatDialogRef<ToolboxReadDialogComponent>) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.toolboxService.readBy(this.data.id).subscribe(read => {
            this.readBy = read.data.sort((a, b) => (`${a.user.lastname} ${a.user.firstname}` ?? 'zzz').localeCompare((`${b.user.lastname}${b.user.firstname}` ?? 'zzz')));
        }));
    }

    close() {
        this.dialogRef.close();
    }
}
