<div class="dialog-container">
    <h2 class="condensed dialog-title pb-1 d-flex justify-content-between align-items-center">
        Afbeelding invoegen
        <button (click)="onNoClick()" *ngIf="data.discardButton" mat-icon-button>
            <mat-icon class="fas fa-times"></mat-icon>
        </button>
    </h2>
    <div class="dialog-content">
        <app-codalt-upload-image [fcImageThumb]="form.controls.image_thumb"
                                 [fcImage]="form.controls.image"
                                 [fcMaintainAspectRatio]="form.controls.maintainAspectRatio"
                                 [fcNoOverScale]="form.controls.noOverScale"
                                 [hideControls]="true"
                                 [resizedSize]="755"
                                 class="col mb-4 mx-auto"
        ></app-codalt-upload-image>
    </div>
    <div class="d-flex justify-content-between mt-3">
        <button (click)="onNoClick()" color="primary" mat-stroked-button>Annuleren</button>
        <button (click)="insert()"
                color="primary"
                mat-flat-button>
            Invoegen
        </button>
    </div>
</div>
