import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {Settings} from '../settings.class';

@Pipe({
    name: 'hourEditTimeframe'
})
export class HourEditTimeframePipe implements PipeTransform {

    transform(realisation: Realisation): unknown {
        const now = new Date();
        const enddate = new Date(realisation.enddate);

        const diff = (now.getTime() - enddate.getTime()) / 1000 / 60 / 60;

        return diff < Settings.HOUR_EDIT_ALLOWED_HOURS;
    }

}
