import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class Hiring implements RealtimeInterface {
    id: number;
    entitytypes: EntityType[];
    name: string;
    email: string;
    phone: string;
    order: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
