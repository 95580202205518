import {User} from './user.class';
import {Hourtype} from '../afas-classes/hourtype';
import {Realisation} from './realisation';

export class Settlement {
    id: number;
    hourtype_code: string;
    hourtype: Hourtype;
    user_id: number;
    realisation_id?: number;
    afas_project_id: string;
    realisation?: Realisation;
    afas_employee_id: string;
    bookdate: Date;
    description: string;
    minutes: number;
    amount: string;
    send_to_afas: Date;
    sent_to_afas: Date;
    afas_ptrealization_id: string;
    afas_error: string;
    updated_at: Date;
    created_at: Date;
    user: User;
    missing_values?: boolean;
    sending?: boolean;
    skip_pause?: boolean;
    afas_leave?: boolean;

    // frontend
    bonus: Settlement;
    normal: Settlement;
}

export enum SettlementHourtype {
    frost = 'Vorst',
    illness = 'Z',
    workingHours = 'GU',
    paid_leave = 'V',
    education = 'S',
    travelHours = 'RU',
    short_leave = 'KV',
    sleep = 'SDU',
    sleepWeekend = 'SDW',
    bank_holiday = 'F',
    not_paid = 'OB',
    special_leave = 'BV',
    additional_parental = 'OSV'
}
