<h2>
    Weekrapport {{field === 'user_id' ? 'personeel' : 'materieel'}}
</h2>
<p *ngIf="groupTotals?.length === 0 && !print">
    Er zijn geen uren geboekt in week {{beginDate | date : 'w yyyy'}} op project {{projectId}}.
</p>
<div *ngFor="let userGroupTotal of groupTotals | filter : true : 'visible' : false : trigger" class="user-table">
    <table class="table">
        <tr>
            <th class="name">
                {{userGroupTotal.group}}
            </th>
            <ng-container *ngIf="!onlyWeekTotals">
                <th *ngFor="let day of customDates" class="day"
                    matTooltip="{{day.date | date : 'EEE dd MMM y'}}">
                    {{day.date | date : 'EEE'}}
                </th>
            </ng-container>
            <th class="total">
                Totaal
            </th>
        </tr>
        <tr *ngFor="let userTotals of userGroupTotal.totals;">
            <td class="name">
                {{userTotals.name}}
            </td>
            <ng-container *ngIf="!onlyWeekTotals">
                <td *ngFor="let day of userTotals.days" class="day">
                    <ng-container *ngIf="day.total > 0">{{day.total | minutes}}</ng-container>
                </td>
            </ng-container>
            <td class="total">
                {{userTotals.weekTotal | minutes}}
            </td>
        </tr>
    </table>
</div>

<div *ngFor="let customGroup of weekComment?.content?.customs[field] | filter : true : 'removed' : true : trigger"
     class="user-table">
    <table class="table" #groupTable>
        <tr>
            <th class="name" [class.input]="!print">
                <input [(ngModel)]="customGroup.group"
                       name="groupname"
                       (ngModelChange)="checkChange()"
                       *ngIf="!print"
                       placeholder="Naam van groep"
                       autocomplete="groupname"
                       type="text"/>
                <ng-container *ngIf="print">{{customGroup.group}}</ng-container>
            </th>
            <ng-container *ngIf="!onlyWeekTotals">
                <th *ngFor="let day of customDates" class="day"
                    matTooltip="{{day.date | date : 'EEE dd MMM y'}}">
                    {{day.date | date : 'EEE'}}
                </th>
            </ng-container>
            <th class="total">
                Totaal
            </th>
            <td class="delete" *ngIf="!print" (click)="removeGroup(customGroup)">
                <i class="far fa-trash"></i>
            </td>
        </tr>
        <tr *ngFor="let userTotals of customGroup.rows;">
            <td class="name" [class.input]="!print">
                <input [(ngModel)]="userTotals.name"
                       name="employeename"
                       *ngIf="!print"
                       placeholder="Naam"
                       (ngModelChange)="checkChange()"
                       class="employeename"
                       autocomplete="employeename"
                       type="text"/>
                <ng-container *ngIf="print">{{userTotals.name}}</ng-container>
            </td>
            <ng-container *ngIf="!onlyWeekTotals">
                <td *ngFor="let day of customDates" class="day" [class.input]="!print">
                    <app-minutes-input
                        *ngIf="!print"
                        [(ngModel)]="userTotals[day.abbrev]"
                        (ngModelChange)="checkChange()"
                        [limitHours]="1000"
                        placeholder="-"
                    ></app-minutes-input>
                    <ng-container *ngIf="print && userTotals[day.abbrev] && userTotals[day.abbrev] !== 0">{{userTotals[day.abbrev] | minutes}}</ng-container>
                </td>
            </ng-container>
            <td class="total">
                {{[userTotals] | sum : ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] | minutes}}
            </td>
            <td class="delete" *ngIf="!print" (click)="removeRow(customGroup, userTotals)">
                <i class="far fa-trash"></i>
            </td>
        </tr>
        <tr class="add-row" *ngIf="!print">
            <td [attr.colspan]="onlyWeekTotals ? 3 : 4" class="with-btn">
                <button (click)="addRow(customGroup, groupTable)">Toevoegen</button>
            </td>
            <td class="bg-white"></td>
            <td [attr.colspan]="onlyWeekTotals ? 4 : 5" (click)="registrationsTomanual(customGroup.group)">
                <i class="fa fa-exclamation-triangle" matTooltip="Deze handmatige groep overschrijft de regels uit de urenregistratie"></i>
                Bijwerken
            </td>
        </tr>
    </table>
</div>
<div class="d-flex justify-content-between mb-5" *ngIf="!print">
    <button mat-raised-button color="primary" (click)="addCustomGroup(weekComment?.content?.customs[field])">
        Groep toevoegen
    </button>
    <button mat-raised-button color="primary" (click)="registrationsTomanual()">
        Overnemen registraties
    </button>
</div>
