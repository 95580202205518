import {Entity} from './entity.class';
import {Planning} from './planning.class';
import {Hiring} from './hiring';
import {EntityType} from '../services/entities/entity-type.class';
import {User} from './user.class';

export class PlanningCutter {
    entitytype_id: number;
    entitytype?: EntityType;
    entity_id: number;
    planning_id: number;
    driver_user_id: number;
    truck_entity_id: number;
    lowloader_entity_id: number;
    lowloader_entity?: Entity;
    begindate: Date;
    enddate: Date;
    name: string;
    driver: string;
    location: string;
    tons: number;
    thickness: number;
    trucks: number;
    comment: string;
    created_at: Date;
    updated_at: Date;
    id: number;
    count: number;
    hiring_id: number;
    hiring?: Hiring;
    entity: Entity;
    planning: Planning;
    origin: string;
    destination: string;
    driver_user: User;
    transport_origin: string;
    transport_worksite: string;
    transport_destination: string;
    type?: string;
}
