<div *ngIf="planning" class="container-fluid">
    <div class="row mb-3">
        <div class="col-2">
            <label>Status</label>
            {{(statusList | byId:planning.status_id)?.name}}
        </div>
        <div class="col-2">
            <label>{{planning.afas_project_id ? 'Project' : 'Werknummer'}}</label>
            {{planning.afas_project_id ?? planning.worknumber}}
        </div>
        <div class="col-2">
            <label>Opdrachtgever</label>
            {{planning.contractor}}
        </div>
        <div class="col-2">
            <label>Locatie</label>
            <div class="ellipsis">{{planning.location}}</div>
        </div>
        <div class="col-2">
            <label>Ploeg</label>
            {{(entities | byId:mainPlanning.entity_id)?.name}}
        </div>
        <div class="col-2">
            <label>Dumpers</label>
            <ng-container *ngFor="let dumper of planning.planning_dumpers;">
                {{(entities | byId:dumper.entity_id)?.name}}<br>
            </ng-container>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-2">
            <label>Datum</label>
            {{mainPlanning.begindate | date:'EEE d MMM y H:mm'}}<br>
            {{mainPlanning.enddate | date:'EEE d MMM y H:mm'}}
        </div>
        <div class="col-2">
            <label>Uitvoerder</label>
            {{(users | byId:planning.performer)?.name}}
        </div>
        <div class="col-2">
            <label>Projectleider</label>
            {{(users | byId:planning.projectmanager)?.name}}
        </div>
        <div class="col-2">
            <label>Asfaltuitvoerder</label>
            {{(users | byId:planning.asphalt_performer)?.name}}
        </div>
        <div class="col-3">
            <label>Sets</label>
            <ng-container *ngFor="let set of planning.planning_sets; last as isLast;">
                {{(entities | byId:set.entity_id)?.name}}{{isLast ? '' : ','}}
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <div class="row mb-2">
                <div class="col">
                    <label>Aantal verkeersregelaars</label>
                    {{planning.traffic_controllers || '-'}}
                </div>
                <div class="col">
                    <label>Afgieten</label>
                    {{planning.pouring_off ? 'Ja' : 'Nee'}}
                </div>
                <div class="col">
                    <label>Afstrooien</label>
                    {{planning.scatter ? 'Ja' : 'Nee'}}
                </div>
                <div class="col">
                    <label>Kranen</label>
                    <ng-container *ngFor="let crane of planning.planning_cranes;">
                        {{(entities | byId:crane.entity_id)?.name}}<br>
                    </ng-container>
                </div>
                <div class="col">
                    <label>Walsen</label>
                    <ng-container *ngFor="let roller of planning.planning_rollers;">
                        {{(entities | byId:roller.entity_id)?.name}}<br>
                    </ng-container>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label>Verkeersmaatregelen</label>
                    <div class="ellipsis">{{planning.traffic || '-'}}</div>
                </div>
                <div class="col">
                    <label>Asfaltlocatie</label>
                    {{planning.asphalt_location}}
                </div>
                <div class="col">
                    <label>GM Asfalt overig</label>
                    <ng-container *ngFor="let other of planning.planning_asphalt_others;">
                        {{(entities | byId:other.entity_id)?.name}}<br>
                    </ng-container>
                </div>
                <div class="col">
                    <label>Kleefwagen</label>
                    <ng-container *ngFor="let sticky of planning.planning_sticky_wagons;">
                        {{(entities | byId:sticky.entity_id)?.name}}<br>
                    </ng-container>
                </div>
                <div class="col">
                    <label>Grootte</label>
                    {{planning.size}}
                </div>
                <div class="col">
                    <ng-container *ngIf="!planning?.entity?.use_once">
                        <label>Inhuur mensen</label>
                        {{planning.planning_hire_people | PlanningEntitiesToString : entitiesMap}}
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col">
            <label>Omschrijving</label>
            <div [innerHTML]="planning.description | nl2br"></div>
        </div>
    </div>

    <div *ngIf="viewModel.team"><h5>Asfaltleverantie</h5>
        <table class="table table-center">
            <tr>
                <th>Molen</th>
                <th>Type asfalt</th>
                <th width="130">Code centrale</th>
                <th class="number-input">Ton</th>
                <th class="number-input">Dikte <small>mm</small></th>
                <th>Tijdstip</th>
                <th class="number-input">Vrachtwagens</th>
                <th width="250">Opmerking</th>
            </tr>
            <tr *ngFor="let planningAsphalt of planning.asphalt_list">
                <td>
                    {{(mills | byId:planningAsphalt.asphaltmill_id)?.name}}
                </td>
                <td>{{planningAsphalt.asphaltname}}</td>
                <td>{{planningAsphalt.asphaltcode}}</td>
                <td>{{planningAsphalt.tons}}</td>
                <td>{{planningAsphalt.thickness}}</td>
                <td>{{planningAsphalt.time | date:'H:mm'}}</td>
                <td>{{planningAsphalt.trucks}}</td>
                <td>{{planningAsphalt.comment}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="viewModel.cutterList">
        <h5>
            Frezen
        </h5>
        <table class="table table-center">
            <tr>
                <th>Type</th>
                <th>Ontvangst</th>
                <th class="number-input">Ton</th>
                <th class="number-input">Dikte <small>mm</small></th>
                <th class="number-input">Vrachtwagens</th>
                <th class="tabletime">Tijdsblok</th>
                <th>Opmerking</th>
            </tr>
            <tr *ngFor="let cutter of planning.planning_cutters">
                <td *ngIf="(entities | byId:cutter.entity_id)?.use_once">
                    {{(entities | byId:cutter.entity_id)?.name}}
                </td>
                <td *ngIf="!(entities | byId:cutter.entity_id)?.use_once">
                    {{cutter.name}}
                    <ng-container *ngIf="cutter?.hiring?.name"> ({{cutter?.hiring?.name}})</ng-container>
                </td>
                <td>{{cutter.location}}</td>
                <td>{{cutter.tons}}</td>
                <td>{{cutter.thickness}}</td>
                <td>{{cutter.trucks}}</td>
                <td>{{cutter.begindate | date:'d MMM H:mm'}} - {{cutter.enddate | date:'H:mm'}}</td>
                <td>{{cutter.comment}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="viewModel.wipertruckList">
        <h5>Veeg-/zuigwagens</h5>
        <table class="table table-center mb-0">
            <tr>
                <th class="wiper-selection">Type</th>
                <th class="tabletime">Tijdsblok</th>
                <th>Wegdekreiniger</th>
                <th>Opmerking</th>
            </tr>
            <tr *ngFor="let wipetruck of planning.planning_wipetrucks">
                <td *ngIf="(entities | byId:wipetruck.entity_id)?.use_once">
                    {{(entities | byId:wipetruck.entity_id)?.name}}
                </td>
                <td *ngIf="!(entities | byId:wipetruck.entity_id)?.use_once">
                    {{wipetruck.name}}
                    <ng-container *ngIf="wipetruck.hiring?.name">({{wipetruck?.hiring?.name}})</ng-container>
                </td>
                <td>{{wipetruck.begindate | date:'d MMM H:mm'}} - {{wipetruck.enddate | date:'H:mm'}}</td>
                <td>{{wipetruck.wdr}}</td>
                <td>{{wipetruck.comment}}</td>
            </tr>
        </table>
    </div>
</div>
