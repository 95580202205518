import {ToolboxRead} from './toolbox-read';

export class Toolbox {
    id: number;
    begindate: Date;
    enddate: Date;
    view_before: Date;
    pdf: string;
    read?: ToolboxRead;
    updated_at: Date;

    read_count?: number;
}
