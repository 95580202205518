import {FormControl} from '@angular/forms';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Utils} from '../utils.class';
import {first, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {Entity} from '../classes/entity.class';
import {PlanningHasService} from '../services/planning/planning-has.service';

export const localEntityAvailableValidator = (planning?: PlanningHasEntity,
                                              planningHasService?: PlanningHasService,
                                              entitiesMap?: Map<number, Entity>) => {
    return (input: FormControl) => {
        if (planning?.begindate && planning.enddate && input.value) {
            const entity = entitiesMap.get(input.value);
            if (entity && !entity.use_once) {
                return of(null);
            }

            const begindate = Utils.getTimeOrNull(planning?.begindate);
            const enddate = Utils.getTimeOrNull(planning?.enddate);
            let res = false;
            return planningHasService.getFilteredList(Utils.newDate(planning.begindate), Utils.newDate(planning.enddate)).pipe(
                first(),
                map(planningHass => {
                    planningHass?.forEach(planningHas => {
                        if (planningHas.id !== planning.id) {
                            if ([planningHas.entity_id, planningHas.truck_entity_id, planningHas.lowloader_entity_id].indexOf(input.value) !== -1) {
                                if (Utils.getTimeOrNull(planningHas.begindate) < enddate && Utils.getTimeOrNull(planningHas.enddate) > begindate) {
                                    res = true;
                                }
                            }
                        }
                    });
                    return res ? {notAvailable: res} : null;
                })
            );
        }
        return of(null);
    };
};
