import {EventEmitter, Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {catchError, finalize, map, mergeMap, retryWhen} from 'rxjs/operators';
import {Observable, of, throwError, timer} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {ConfirmDialogService} from '../confirm-dialog-service/confirm-dialog.service';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../../environments/environment';

interface WebsocketMessage<T> {
    type: string;
    data: T;
    success: boolean;
    reinit: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    websocketOpen = new EventEmitter<boolean>();
    private websocket;

    constructor(private confirmDialogService: ConfirmDialogService,
                private authService: AuthService) {

    }

    disconnect() {
        this.websocket?.unsubscribe();
        this.websocket = null;
    }

    public getWebsocket<T>(type: string) {
        return this.getFullWebsocket()
            .pipe(map((data: WebsocketMessage<T>) => {
                    if (typeof data.data !== 'undefined' && (data.data as any as string) === 'Unauthorized.') {
                        this.authService.logout();
                    } else if (data.type === type || !data.success) {
                        return data;
                    }
                }), retryWhen(genericRetryStrategy({
                    scalingDuration: 500,
                    excludedStatusCodes: [500],
                    maxRetryAttempts: 20
                })),
                catchError(error => of(error)));

    }

    public doRequest(type: string, fromDate?: Date, toDate?: Date, singleId?: number | string) {
        this.getFullWebsocket().next({type, fromDate, toDate, singleId, access_token: LocalStorage.getUserToken()});
    }

    private getFullWebsocket(): WebSocketSubject<object> {
        if (!this.websocket) {
            this.websocket = webSocket({
                url: `${environment.wsEndpoint}?access_token=${LocalStorage.getUserToken()}`,
                openObserver: {
                    next: () => {
                        this.websocketOpen.emit(true);
                    }
                },
                closeObserver: {
                    next: () => {
                        this.websocketOpen.emit(false);
                    }
                }
            });
        }
        return this.websocket;
    }
}

export const genericRetryStrategy = ({
                                         maxRetryAttempts = 3,
                                         scalingDuration = 1000,
                                         excludedStatusCodes = []
                                     }: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            // or response is a status code we don't wish to retry, throw error
            if (
                retryAttempt > maxRetryAttempts ||
                excludedStatusCodes.find(e => e === error.status)
            ) {
                console.error('Websocket maximum reconnect tries reached');
                return throwError(error);
            }
            console.warn(
                `Websocket reconnect attempt ${retryAttempt}: retrying in ${retryAttempt *
                scalingDuration}ms`
            );
            return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('End Retry Websocket'))
    );
};
