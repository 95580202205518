<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Planning DvdS'"
                    subTitle="Verlof & verzuim">
        </app-header>
    </div>
    <div class="indicator">

    </div>
    <div class="print-pdf">

    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="container-xl" *ngIf="form">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-label>Soort</mat-label>
                        <mat-select [formControl]="form.controls.hourtype" placeholder="Soort">
                            <mat-option [value]="RealisationHourtype.frost">Vorst</mat-option>
                            <mat-option [value]="RealisationHourtype.time_for_time">Tijd voor tijd</mat-option>
                            <mat-option [value]="RealisationHourtype.paid_leave">Doorbetaald verlof</mat-option>
                            <mat-option [value]="RealisationHourtype.short_absence">Kort verzuim</mat-option>
                            <mat-option [value]="RealisationHourtype.education">Scholing</mat-option>
                            <mat-option [value]="RealisationHourtype.bank_holiday">Feestdag</mat-option>
                            <mat-option [value]="RealisationHourtype.special_leave">Bijzonder verlof</mat-option>
                            <mat-option [value]="RealisationHourtype.additional_parental">Aanv. geb verl. + ouderschapsv</mat-option>
                        </mat-select>
                        <mat-error [control]="form.controls.hourtype"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <mat-checkbox [formControl]="form.controls.common">
                        Bedrijfsbreed
                    </mat-checkbox>
                </div>
                <mat-form-field class="col">
                    <input type="text" [formControl]="form.controls.comment" matInput placeholder="Opmerking">
                    <mat-error [control]="form.controls.comment"></mat-error>
                </mat-form-field>
            </div>


            <div class="row">
                <div class="col">
                    <ng-select *ngIf="!form.getRawValue().common"
                               [formControl]="form.controls.users"
                               [items]="users"
                               [clearable]="true"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               placeholder="Medewerkers"
                               bindLabel="name"
                               bindValue="id">
                    </ng-select>
                    <ng-select *ngIf="form.getRawValue().common"
                               [formControl]="form.controls.excludedUsers"
                               [items]="users"
                               [clearable]="true"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               placeholder="Uitgesloten medewerkers"
                               bindLabel="name"
                               bindValue="id">
                    </ng-select>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4" *ngIf="!form.value.interval">
                    <b>Datum</b>
                    <div>
                        <mat-form-field>
                            <input (focus)="pickerStart.open()"
                                   [min]="minDate"
                                   [matDatepicker]="pickerStart"
                                   [formControl]="form.controls.begindate"
                                   matInput placeholder="Datum">
                            <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                            <mat-error [control]="form.controls.begindate"></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12" *ngIf="form.value.interval">
                    <b>
                        Periode
                        <i class="fa fa-info-circle"
                           matTooltipPosition="above"
                           matTooltip="De periode waarbinnen herhaalt wordt.
                           Als de begintijd van de periode later is dan de begintijd bij 'Tijden', is op de eerste dag de begintijd van de periode leidend. Vice versa voor eindtijd."
                        ></i>
                    </b>
                    <div class="row">
                        <div class="col">
                            <app-datetime-input [formControl]="form.controls.begindate"
                                                [min]="minDate"
                                                [matFormField]="true"
                                                labelDate="Begindatum"
                                                labelTime="tijd"></app-datetime-input>
                            <mat-error [control]="form.controls.begindate"></mat-error>
                        </div>
                        <div class="col">
                            <app-datetime-input [formControl]="form.controls.enddate"
                                                [min]="form.controls.begindate.value"
                                                [max]="maxDate"
                                                [matFormField]="true"
                                                labelDate="Einddatum"
                                                labelTime="tijd"></app-datetime-input>
                            <mat-error [control]="form.controls.enddate"></mat-error>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <b>
                        {{form.value.interval ? 'Tijden' : 'Tijd'}}
                        <i class="fa fa-info-circle"
                           *ngIf="form.value.interval"
                           matTooltipPosition="above"
                           matTooltip="De tijd op de dag zelf.
                           Als de begintijd onder 'Periode' later is is dan deze begintijd, is op de eerste dag de begintijd van de periode leidend. Vice versa voor eindtijd."
                        ></i>
                    </b>
                    <div class="row">
                        <div class="col">
                            <app-datetime-input [formControl]="form.controls.begintime"
                                                [hideDate]="true"
                                                [matFormField]="true"
                                                labelDate="Begindatum"
                                                labelTime="tijd"></app-datetime-input>
                            <mat-error [control]="form.controls.begintime"></mat-error>
                        </div>
                        <div class="col">
                            <app-datetime-input [formControl]="form.controls.endtime"
                                                [hideDate]="true"
                                                [matFormField]="true"
                                                labelDate="Einddatum"
                                                labelTime="tijd"></app-datetime-input>
                            <mat-error [control]="form.controls.endtime"></mat-error>
                        </div>
                    </div>
                </div>
                <div class="col-2 d-flex align-items-center">
                    Duur: {{duration | minutes}} uur
                </div>
            </div>

            <div class="row">
                <div class="col-auto d-flex align-items-center">
                    <mat-checkbox [formControl]="form.controls.interval">
                        Herhalend
                    </mat-checkbox>
                </div>
                <mat-form-field class="col" *ngIf="form.value.interval">
                    <input [formControl]="form.controls.interval_week" appDecimalInput matInput placeholder="Week interval" type="text">
                    <mat-error [control]="form.controls.interval_week"></mat-error>
                </mat-form-field>
            </div>
            <ng-container *ngIf="form.value.interval">

                <div>
                    <mat-checkbox color="primary" [formControl]="form.controls.repeat_monday">Maandag</mat-checkbox>
                    <br>
                    <mat-checkbox color="primary" [formControl]="form.controls.repeat_tuesday">Dinsdag</mat-checkbox>
                    <br>
                    <mat-checkbox color="primary" [formControl]="form.controls.repeat_wednesday">Woensdag</mat-checkbox>
                    <br>
                    <mat-checkbox color="primary" [formControl]="form.controls.repeat_thursday">Donderdag</mat-checkbox>
                    <br>
                    <mat-checkbox color="primary" [formControl]="form.controls.repeat_friday">Vrijdag</mat-checkbox>
                    <br>
                    <mat-checkbox color="warn" [formControl]="form.controls.repeat_saturday">Zaterdag</mat-checkbox>
                    <br>
                    <mat-checkbox color="warn" [formControl]="form.controls.repeat_sunday">Zondag</mat-checkbox>
                </div>
            </ng-container>
        </div>
        <div class="col-md-3 results-table">
            <table class="table">
                <thead>
                <tr>
                    <th>
                        Resulterende datums
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let preview of previewRealisations">
                    <td class="text-right">
                        {{preview.begindate | date :'EEE d MMM yyyy HH:mm'}} tot {{preview.enddate | date :'HH:mm'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container-fluid mt-2"
     *ngIf="previewRealisations && form && (form?.value?.excludedUsers?.length || form?.value?.users?.length)">
    <div class="d-flex justify-content-center">

        <div class="excluded-users">
            <table class="table" (mouseout)="highlight = null">
                <tr (mouseout)="highlight = null">
                    <th class="name-column">
                        <b>Uitsluitingen</b>
                    </th>
                    <th *ngFor="let preview of previewRealisations"
                        class="date"
                        (mouseover)="mouseOverDate(preview.begindate)"
                        (click)="excludeDate(preview.begindate)">
                        <div>
                            {{preview.begindate | date :'EEE d MMM yyyy'}}
                        </div>
                    </th>
                </tr>
                <tr *ngFor="let user of (form.getRawValue()?.common ? (users | whereIn : form?.value?.excludedUsers ?? []) : (users | whereIn : form?.value?.users ?? []))">
                    <td class="name-column"
                        (click)="excludeUser(user.id)"
                        (mouseover)="mouseOverUser(user.id)">
                        {{user.name}}
                    </td>
                    <td *ngFor="let preview of previewRealisations"
                        [class.highlight]="highlight === (preview.begindate | date : 'yyyy-MM-dd') || highlight === user.id"
                        class="check date-{{preview.begindate | date : 'yyyy-MM-dd'}} user-{{user.id}}"
                        (click)="setExclude(user.id, preview.begindate)">
                        <i *ngIf="excluded.has((user.id | excludedKey : preview.begindate))"
                           class="fa-duotone fa-user-slash"></i>
                        <i class="fa-regular fa-user-slash"
                           [matTooltipShowDelay]="0"
                           matTooltip="Geen werkdag voor {{user.name}}"
                           *ngIf="roosterMap.size && !roosterMap.has((user.afas_employee_id | excludedKey : preview.begindate))"></i>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div class="container-xl mt-2" *ngIf="realisations?.length > 0">
    <div class="d-flex justify-content-center">
        <div class="excluded-users">
            <table class="table table-striped table-hover realisations">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Datum</th>
                    <th>Tijd</th>
                    <th>Medewerker</th>
                    <th>Aangepast</th>
                    <th>Ingediend</th>
                    <th>Goedgekeurd</th>
                    <th><i class="fas fa-history"></i></th>
                    <th><i class="fas fa-user-pen"></i></th>
                </tr>
                </thead>
                <tr *ngFor="let realisation of realisations"
                    [class.removed]="realisation.removed">
                    <td>{{realisation.id}}</td>
                    <td>{{realisation.begindate | date : 'EEE d MMM yyyy'}}</td>
                    <td>{{realisation.begindate | date : 'HH:mm'}} - {{realisation.enddate | date : 'HH:mm'}}</td>
                    <td>{{realisation.user?.name}}</td>
                    <td>{{realisation.adjusted | date : 'EEE d MMM yyyy HH:mm'}}</td>
                    <td>{{realisation.submitted | date : 'EEE d MMM yyyy HH:mm'}}</td>
                    <td>{{realisation.approved | date : 'EEE d MMM yyyy HH:mm'}}</td>
                    <td class="text-center">
                        <button (click)="realisationHistory(realisation)"
                                mat-icon-button
                                color="primary"
                                class="planning-button">
                            <i class="fas fa-history"></i>
                        </button>
                    </td>
                    <td>
                        <button class="hour-edit-button"
                                mat-icon-button color="primary"
                                (click)="editHours(realisation.bookdate, realisation.user_id)">
                            <i class="fa-regular fa-user-pen"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container-xl">
        <div class="buttons" *ngIf="form">
            <button mat-raised-button color="accent" routerLink="/leave">
                Naar overzicht
            </button>
            <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.dirty">
                {{saved ? 'Wijzigingen opgeslagen' : form.dirty ? 'Openstaande wijzigingen opslaan' : 'Er zijn geen wijzigingen'}}
                <i class="fas fa-spinner fa-spin" *ngIf="saving"></i>
            </button>
        </div>
    </div>
</div>
