import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Settings} from "../../../settings.class";
import {Utils} from "../../../utils.class";

@Component({
    selector: 'app-date-indicator',
    templateUrl: './date-indicator.component.html',
    styleUrls: ['./date-indicator.component.scss']
})
export class DateIndicatorComponent implements OnInit, OnChanges {

    @Input()
    public fromDate: Date;

    @Input()
    public singleDate: Date;

    @Input()
    public toDate: Date;

    @Input()
    public print: boolean;

    @Input()
    public primaryWeekStart: Date;

    @Input()
    public primaryWeekEnd: Date;

    @Input()
    disableNext = false;

    @Output()
    public next = new EventEmitter<void>();

    @Output()
    public prev = new EventEmitter<void>();

    weekwidth = 0;
    weekright = 0;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.toDate && this.primaryWeekStart) {
            const showMaxDays = 8 - Utils.dutchDayOfWeek(this.primaryWeekStart);
            const daysToLastVisibleDay = Math.ceil((this.toDate.getTime() - this.primaryWeekStart.getTime()) / (1000 * 60 * 60 * 24));

            this.weekwidth = Math.min(showMaxDays, daysToLastVisibleDay) * Settings.DAY_WIDTH;
            this.weekright = (daysToLastVisibleDay - Math.min(showMaxDays, daysToLastVisibleDay)) * Settings.DAY_WIDTH;
        }
    }

}
