import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {CodaltComponent} from '../codalt.component';
import {ProjectService} from '../services/project.service';
import {ProjectComment} from '../classes/project-comment';
import {Utils} from 'src/app/utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Project} from '../classes/project.class';
import {AsphaltUsedListComponent} from './asphalt-used-list/asphalt-used-list.component';
import {combineLatest, Subscription} from 'rxjs';
import {PlanningService} from '../services/planning/planning.service';
import {debounceTime, first} from 'rxjs/operators';
import {EntitiesService} from '../services/entities/entities.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {formatDate} from '@angular/common';
import {LocalStorage} from '../storage.class';
import {UserService, UserType} from '../services/user/user.service';
import {RealisationService} from '../services/realisation.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-project-day-report',
    templateUrl: './project-day-report.component.html',
    styleUrls: ['./project-day-report.component.scss']
})
export class ProjectDayReportComponent extends CodaltComponent implements OnInit {

    projectComments: ProjectComment[];

    form: FormGroup<ControlsOf<{ comment: string }>>;

    saving = false;
    saved = false;

    date = new Date();
    projectId: string;
    project: Project;
    otherProjectsThisDay: Project[];
    notOwnProject = false;
    loadingAsfalt = false;

    @Input() print = false;

    @ViewChild('asphaltUsedList') asphaltUsedListComponent: AsphaltUsedListComponent;
    @ViewChild('asphaltAdhesiveList') asphaltAdhesiveListComponent: AsphaltUsedListComponent;

    @ViewChild('chooseProjectDialog', {static: true}) chooseProjectDialog;
    projectDialog;
    projectDialogProjects: Project[];

    private currentComment: ProjectComment;

    private navigateProjectSubscription = new Subscription();
    private loadDataSubscription = new Subscription();

    constructor(private activatedRoute: ActivatedRoute,
                private planningService: PlanningService,
                private entitiesService: EntitiesService,
                private realisationService: RealisationService,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private router: Router,
                private cdref: ChangeDetectorRef,
                private projectService: ProjectService) {
        super();
    }

    next() {
        this.date.setDate(this.date.getDate() + 1);
        this.date = new Date(this.date);
        this.changeProjectAndNavigate();
    }

    prev() {
        this.date.setDate(this.date.getDate() - 1);
        this.date = new Date(this.date);
        this.changeProjectAndNavigate();
    }

    otherProjectThisDay() {
        this.projectDialogProjects = this.otherProjectsThisDay;
        this.changeProjectDialog();
    }

    private changeProjectAndNavigate() {
        this.navigateProjectSubscription?.unsubscribe();
        this.navigateProjectSubscription = new Subscription();
        if (!UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
            this.navigateProjectSubscription.add(this.realisationService.getDayScheduleSummary(this.date, LocalStorage.user?.id).subscribe(ds => {
                this.projectDialogProjects = ds.data[0]?.projects;
                if (!this.projectDialogProjects?.length || this.projectDialogProjects.map(p => p.afas_project_id).includes(this.projectId)) {
                    this.router.navigateByUrl(`project-dagrapport/${this.projectId}/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
                } else {
                    this.changeProjectDialog();
                }
            }));
        } else {
            this.router.navigateByUrl(`project-dagrapport/${this.projectId}/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
        }
    }

    private changeProjectDialog() {
        this.projectDialog = this.matDialog.open(this.chooseProjectDialog, {
            disableClose: true
        });
        const sub = this.projectDialog.afterClosed().subscribe(project => {
            this.projectId = project;
            this.router.navigateByUrl(`project-dagrapport/${this.projectId}/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
            sub.unsubscribe();
        });
    }

    calculateAdhesives() {
        const list = this.asphaltUsedListComponent.projectUsedAsphaltList;
        const types = [
            {application: 'base', name: 'Modimuls TT'},
            {application: 'bind', name: 'Modimuls TT'},
            {application: 'surf', name: 'Modimuls TT'}
        ];
        types.forEach(type => {
            const typeCount = list.filter(l => l.application === type.application)
                .reduce((sum, current) => sum + current.surface, 0);
            if (typeCount > 0) {
                this.asphaltAdhesiveListComponent.projectUsedAsphaltList.push({
                    afas_project_id: this.projectId,
                    date: this.date,
                    surface: typeCount,
                    asphaltname: type.name,
                    liter: Math.round(100 * typeCount * .2) / 100,
                    application: 'adhesive_' + type.application
                });
            }
        });
    }

    copyAsphalt() {
        this.loadingAsfalt = true;
        const nextDay = Utils.newDate(this.date);
        nextDay.setDate(nextDay.getDate() + 1);
        const entities$ = this.entitiesService.getMap();
        const planning$ = this.planningService.getFilteredList(this.date, nextDay).pipe(debounceTime(150)).pipe(first());
        combineLatest([entities$, planning$]).subscribe(([entitiesMap, plannings]) => {
            const z = plannings.filter(planning => planning.asphalt_list?.length > 0).map(planning => {
                const main = this.planningService.getMainPlanning(planning);
                main.entity = entitiesMap.get(main.entity_id);
                let dateString = formatDate(main.begindate, 'EEE dd MMM yyyy HH:mm', 'nl') + ' - ';
                if (Utils.getDateDate(main.begindate) !== Utils.getDateDate(main.enddate)) {
                    dateString += formatDate(main.enddate, 'EEE dd MMM yyyy', 'nl');
                }
                dateString += formatDate(main.enddate, 'HH:mm', 'nl');
                return {
                    name: planning.afas_project_id + ' - ' + main.entity.name + ' - ' + (planning.asphalt_list?.length ?? 0) + ' asfaltregels',
                    subtext: dateString,
                    planning,
                    main,
                    bgColor: planning.afas_project_id === this.projectId ? '#dedce9' : null
                };
            });
            this.loadingAsfalt = false;
            this.confirmDialogService.confirm('Kies een project', null, 'Sluiten', null, z).then(result => {
                if (result?.planning) {
                    this.asphaltUsedListComponent.projectUsedAsphaltList.push(...result.planning.asphalt_list.map(al => {
                        return {
                            asphaltmill_asphalt_id: al.asphaltmill_asphalt_id,
                            afas_project_id: this.projectId,
                            date: this.date,
                            asphaltmill_id: al.asphaltmill_id,
                            asphaltname: al.asphaltname,
                            asphaltcode: al.asphaltcode,
                            tons: al.tons,
                            surface: null,
                            application: null,
                            thickness: al.thickness,
                            comment: al.comment
                        };
                    }));
                }
            });
        });
    }


    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { project, date }) => {
            this.projectId = params.project;
            this.date = new Date(params.date);
            this.loadData();
        }));
    }

    loadData() {
        this.loadDataSubscription?.unsubscribe();
        this.loadDataSubscription = new Subscription();
        this.loadDataSubscription.add(this.realisationService.getDayScheduleSummary(this.date, LocalStorage.user?.id).subscribe(r => {
            this.otherProjectsThisDay = r.data[0]?.projects.filter(p => p.afas_project_id !== this.projectId);
            this.notOwnProject = this.otherProjectsThisDay?.length === r.data[0]?.projects?.length;
        }));
        this.loadDataSubscription.add(this.projectService.getProject(this.projectId).subscribe(project => {
            this.project = project.data;
        }));

        this.loadDataSubscription.add(this.projectService.getProjectAsphalt(this.projectId, this.date).subscribe(ua => {
            this.asphaltUsedListComponent.projectUsedAsphaltList = ua.data.filter(pal => pal.application?.substring(0, 9) !== 'adhesive_');
            this.asphaltAdhesiveListComponent.projectUsedAsphaltList = ua.data.filter(pal => pal.application?.substring(0, 9) === 'adhesive_');
        }));

        this.loadDataSubscription.add(this.projectService.getProjectCommentsWeek(this.projectId, this.date).subscribe(projectComments => {

            this.currentComment = projectComments.data.find(pc =>
                Utils.dateString(pc.date) === Utils.dateString(this.date) && (pc.user_id === LocalStorage.getUser().id || !pc.user_id) && !pc.week
            ) ?? new ProjectComment();

            this.form = new FormGroup({
                comment: new FormControl(this.currentComment?.comment)
            });
            this.projectComments = projectComments.data.filter(p => p !== this.currentComment);
        }));
    }

    save() {
        Promise.all([
            this.asphaltUsedListComponent.validateForms(),
            this.asphaltAdhesiveListComponent.validateForms()
        ]).then(() => {
            this.saving = true;
            setTimeout(() => {
                this.currentComment.afas_project_id = this.projectId;
                this.currentComment.user_id = LocalStorage.getUser().id;
                this.currentComment.date = this.date;
                this.currentComment.comment = this.form.controls.comment.value;
                this.subscriptions.add(combineLatest([this.projectService.saveProjectAsphalt(
                    this.projectId,
                    this.date,
                    [...this.asphaltUsedListComponent.projectUsedAsphaltList, ...this.asphaltAdhesiveListComponent.projectUsedAsphaltList]),
                    this.projectService.saveProjectComment(this.currentComment)
                ]).subscribe(() => {
                    this.saving = false;
                    this.saved = true;
                    setTimeout(() => {
                        this.saved = false;
                    }, 2000);
                    this.loadData();
                }));
            });
        }, () => {
            this.confirmDialogService.confirm('Fout in formulier',
                'Niet alle velden zijn juist gevuld. Verbeter deze velden en probeer opnieuw op te slaan.',
                'Sluiten', null).then(() => {

            });
        });

    }

}
