import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'allApproved'
})
export class AllApprovedPipe implements PipeTransform {

    transform(realisations: { skip_performer, approved, comment_manager }[]): boolean {
        return realisations?.filter(r => (r.comment_manager && r.skip_performer) || r.approved)?.length === realisations?.length;
    }

}
