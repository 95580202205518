<div *ngIf="!hideControls">
    <mat-checkbox [formControl]="fcMaintainAspectRatioControl" class="pe-3">
        Toon in verhouding
    </mat-checkbox>
    <mat-checkbox [formControl]="fcNoOverScaleControl">
        Niet schalen boven resolutie
    </mat-checkbox>
</div>
<div class="img-input">
    <button mat-icon-button color="primary" class="from-link" (click)="loadLinkDialog()">
        <mat-icon class="fas fa-link"></mat-icon>
    </button>
    <label (click)="labelClick($event);"
           [class.empty]="fcImage?.value?.length < 3"
           [class.loading]="loading"
           [class.movable]="!!fcOffsetX"
           [class.moving]="moving"
           [style.height.px]="height"
           [style.width.px]="width">
        <app-codalt-image *ngIf="!outputBase64"
                          [fullUrl]="fcImage?.value"
                          [maintainAspectRatio]="fcMaintainAspectRatio?.value"
                          [noOverScale]="fcNoOverScale?.value"
                          [offsetPercX]="fcOffsetX?.value"
                          [offsetPercY]="fcOffsetY?.value"
                          [thumb]="fcImage?.value"></app-codalt-image>
        <app-codalt-image *ngIf="outputBase64"></app-codalt-image>
        <input (change)="submit($event);fileInput.value=''" #fileInput hidden type="file">
        <div (click)="$event.stopPropagation();$event.preventDefault();"
             (panend)="panEnd()"
             (panmove)="panMove($event)"
             (panstart)="panStart()"
             *ngIf="fcOffsetX"
             class="move">
        </div>
        <div class="spinner">
            <mat-spinner></mat-spinner>
        </div>
    </label>
</div>
<ng-template #insertLinkTemplate>
    <div class="dialog-container">
        <h2 class="condensed dialog-title pb-1 d-flex justify-content-between align-items-center">
            Afbeelding invoegen
            <button (click)="linkDialog.close()" mat-icon-button>
                <mat-icon class="fas fa-times"></mat-icon>
            </button>
        </h2>
        <div class="dialog-content">
            <mat-form-field appearance="fill" class="col">
                <mat-label>Url</mat-label>
                <input [(ngModel)]="linkInput" matInput/>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-between mt-3">
            <button (click)="linkDialog.close()" color="primary" mat-stroked-button>Annuleren</button>
            <button (click)="linkDialog.close(linkInput)"
                    color="primary"
                    mat-flat-button>
                Toevoegen
            </button>
        </div>
    </div>

</ng-template>
