<div class="modal-inner" *ngIf="comment">
    <h1>Wijzigingshistorie dagrapport {{comment.user.name}} </h1>
    <h2>{{comment.date | date : 'EEEE dd MMMM yyyy'}} - {{comment.afas_project_id}}</h2>
    <div *ngFor="let comment of comments" class="w-100 mb-3">
        <table class="table">
                <tr>
                    <th colspan="2">
                        Aangepast door {{comment.changed_by?.name ?? '-'}} op {{comment.updated_at |  date : 'EEEE dd MMMM yyyy HH:mm'}}
                    </th>
                </tr>
                <tr>
                    <td width="50%">
                        <div [innerHTML]="comment?.old_value"></div>
                    </td>
                    <td width="50%">
                        <div [innerHTML]="comment?.new_value"></div>
                    </td>
                </tr>
        </table>
    </div>

    <div class="buttons">
        <button mat-raised-button
                color="primary"
                class="planning-button"
                mat-dialog-close
                (click)="dialogRef.close()">
            Sluiten
        </button>
    </div>
</div>
