import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {RealisationService} from '../../services/realisation.service';
import {FormControl} from '@ngneat/reactive-forms';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-weekreport-pdf-options-dialog',
    templateUrl: './weekreport-pdf-options-dialog.component.html',
    styleUrls: ['./weekreport-pdf-options-dialog.component.scss']
})
export class WeekreportPdfOptionsDialogComponent extends CodaltComponent implements OnInit {

    downloadingPdf = false;

    fcOnlyWeekTotals = new FormControl(false);
    fcNoHours = new FormControl(false);
    fcNoComments = new FormControl(false);

    constructor(public dialogRef: MatDialogRef<WeekreportPdfOptionsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    projectId, week, year
                },
                private realisationService: RealisationService,
                private confirmDialog: ConfirmDialogService,) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcNoHours.valueChanges.subscribe(noHours => {
            this.fcOnlyWeekTotals.setDisable(noHours, {emitEvent: false});
        }));
        this.subscriptions.add(this.fcOnlyWeekTotals.valueChanges.subscribe(onlyWeekTotals => {
            this.fcNoHours.setDisable(onlyWeekTotals, {emitEvent: false});
        }));
    }

    pdf() {
        this.downloadingPdf = true;
        const filename = `Weekrapport_${this.data.projectId}-week_${this.data.week}-${this.data.year}.pdf`;
        this.subscriptions.add(this.realisationService.getWeekReportPdf(
            this.data.projectId,
            this.data.week,
            this.data.year,
            this.fcOnlyWeekTotals.value,
            this.fcNoHours.value,
            this.fcNoComments.value)
            .subscribe((data: HttpResponse<any>) => {
                saveAs(data, filename);
                this.downloadingPdf = false;
                this.dialogRef.close();
            }, () => {
                this.downloadingPdf = false;
                this.confirmDialog.confirm(
                    'Er is iets foutgegaan',
                    `Fout bij het aanmaken van de pdf`,
                    'Oké', null).then(() => {
                    this.dialogRef.close();
                }, () => {
                });
                this.dialogRef.close();
            }));
    }

}
