import {Entity} from './entity.class';
import {User} from './user.class';
import {Status} from './status.class';
import {PlanningSet} from './planningset.class';
import {PlanningDumper} from './planningdumper.class';
import {PlanningCutter} from './planningcutter.class';
import {PlanningHasEntity} from './planning-has-entity.class';
import {PlanningAsfaltteam} from './planningasfaltteam.class';
import {PlanningAsphalt} from './planningasphalt.class';
import {PlanningHirePeople} from './planninghirepeople.class';
import {PlanningProjectteam} from './planningprojectteam.class';
import {UserPlanning} from './user-planning';
import {PlanningPreparationteam} from './planningpreparationteam.class';
import {Project} from './project.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class Planning implements RealtimeInterface {
    id: number;
    user_id: number;
    entity_id: number;
    status_id: number;
    performer: number;
    performer_user: User;
    contractor: string;
    worknumber: string;
    projectmanager: number;
    projectmanager_user: User;
    location: string;
    asphalt_location: string;
    trucks: any;
    traffic_controllers: any;
    mobile_crane: any;
    scatter: boolean;
    sticky_wagon: string;
    description: string;
    created_at: Date;
    updated_at: Date;
    traffic: string;
    pouring_off: string;
    tokband: any;
    hotbox: boolean;
    asphalt_performer: number;
    asphalt_performer_user: User;
    entity: Entity;
    user: User;
    status: Status;
    planning_sets: PlanningSet[];
    planning_trucks: PlanningHasEntity[];
    planning_dumpers: PlanningDumper[];
    planning_cranes: PlanningHasEntity[];
    planning_sticky_wagons: PlanningHasEntity[];
    planning_asphalt_others: PlanningHasEntity[];
    planning_rollers: PlanningHasEntity[];
    planning_cutters: PlanningCutter[];
    planning_wipetrucks: PlanningHasEntity[];
    planning_asfaltteam: PlanningAsfaltteam;
    planning_preparationteam: PlanningPreparationteam;
    planning_projectteam: PlanningProjectteam;
    planning_has: PlanningHasEntity[];
    planning_has_all: PlanningHasEntity[];
    asphalt_list: PlanningAsphalt[];
    planning_hire_people: PlanningHirePeople[];
    user_planning: UserPlanning[];

    is_transport: boolean;
    afas_project_id?: string;
    project: Project;
    comment: string;
    size: PlanningSize;
}

export enum PlanningSize {
    Hotbox = 'hotbox', Middel = 'middel', Groot = 'groot'
}
