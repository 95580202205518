<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Periode'"
                    subTitle="verrekening">
        </app-header>
    </div>
    <div class="indicator"></div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="content">
    <app-settlements-send [endDate]="endDate"></app-settlements-send>
</div>

