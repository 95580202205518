import {Component, OnInit} from '@angular/core';
import {PlanningHasEntity} from '../../../classes/planning-has-entity.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EntitiesService} from '../../../services/entities/entities.service';
import {PlanningService} from '../../../services/planning/planning.service';
import {entityAvailableValidator} from '../../../validators/entity-available.validator';
import {EntitiesListAbstractComponent} from '../entities-list-abstract.component';
import {EntityUnavailableService} from '../../../services/entities/entity-unavailable.service';
import {RequiredRule} from '../entities-list-abstract-component.required-rules';
import {EntityTypeCode} from '../../../services/entities/entity-type.class';
import {Utils} from '../../../utils.class';
import {debounceTime} from 'rxjs/operators';
import {DateAdapter} from '@angular/material/core';
import {DateSmallAdapter} from '../../../date-adapters/date-small-adapter';
import {HiringService} from '../../../services/hiring.service';
import {disabledWhenFinal} from '../planning-detail-dialog.required-rules';
import {User} from '../../../classes/user.class';
import {UserService, UserType} from '../../../services/user/user.service';
import {localUserAvailableValidator} from '../../../validators/local-user-available.validator';
import {UserPlanningService} from '../../../services/planning/user-planning.service';
import {PlanningHasService} from '../../../services/planning/planning-has.service';

@Component({
    selector: 'app-wipetruck-list',
    templateUrl: './wipetruck-list.component.html',
    styleUrls: ['./wipetruck-list.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateSmallAdapter
    }]
})
export class WipetruckListComponent extends EntitiesListAbstractComponent<PlanningHasEntity> implements OnInit {

    drivers: User[];

    constructor(protected entitiesService: EntitiesService,
                protected entityUnavailableService: EntityUnavailableService,
                private userService: UserService,
                protected hiringService: HiringService,
                protected planningService: PlanningService,
                protected userPlanningService: UserPlanningService,
                protected planningHasService: PlanningHasService) {
        super(
            PlanningHasEntity,
            EntityTypeCode.Wipetruck,
            entityUnavailableService,
            entitiesService,
            hiringService,
            planningService
        );
    }

    ngOnInit() {
        super.ngOnInit();

        if (UserService.userHasRights(UserType.WORKPLANNER)) {
            this.userService.getByType(UserType.TRUCK_DRIVER).subscribe(drivers => {
                this.drivers = drivers;
            });
        }
    }

    protected createForm(planningWipetruck: PlanningHasEntity): FormGroup {
        const fgDriverUserId = new FormControl(
            planningWipetruck.driver_user_id,
            {
                asyncValidators: localUserAvailableValidator(
                    planningWipetruck as PlanningHasEntity,
                    this.userPlanningService,
                    this.planningHasService,
                    !UserService.userHasRights(UserType.WORKPLANNER)
                )
            });

        const formGroup = new FormGroup({
            entity_id: new FormControl({
                value: planningWipetruck.entity_id,
                disabled: disabledWhenFinal(this) && !!planningWipetruck.id
            }, Validators.required),
            hiring_id: new FormControl(planningWipetruck.hiring_id, RequiredRule(this, planningWipetruck)),
            name: new FormControl(planningWipetruck.name),
            driver_user_id: fgDriverUserId,
            wdr: new FormControl(planningWipetruck.wdr, [Validators.maxLength(300)]),
            comment: new FormControl(planningWipetruck.comment, [Validators.maxLength(300)]),
            date: new FormGroup({
                date: new FormControl({
                    value: planningWipetruck.begindate,
                    disabled: disabledWhenFinal(this) && !!planningWipetruck.id
                }, RequiredRule(this)),
                begintime: new FormControl({
                    value: Utils.getTimeOrNull(planningWipetruck.begindate),
                    disabled: disabledWhenFinal(this) && !!planningWipetruck.id
                }, RequiredRule(this)),
                endtime: new FormControl({
                    value: Utils.getTimeOrNull(planningWipetruck.enddate),
                    disabled: disabledWhenFinal(this) && !!planningWipetruck.id
                }, RequiredRule(this))
            }),
            type: new FormControl(planningWipetruck.type, Validators.required)
        }, [], [entityAvailableValidator(this.planningHasService, this.entitiesMap, planningWipetruck)]);
        this.subscriptions.add(formGroup.statusChanges.pipe(debounceTime(25)).subscribe(() => {
            for (const [key, value] of Object.entries(formGroup.value)) {
                if (typeof value === 'object' && value && key === 'date') {
                    this.convertFormDate(planningWipetruck, value);
                } else {
                    if (formGroup.get(key).valid) {
                        planningWipetruck[key] = value;
                    } else {
                        console.error(formGroup.get(key).pending, formGroup.get(key).disabled, planningWipetruck.id, value, key, planningWipetruck[key], planningWipetruck.id);
                    }
                }
            }
            this.checkAddPossibility();
        }));

        this.subscriptions.add(formGroup.get('date').get('date').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            const oldDate = new Date(planningWipetruck.begindate);
            const newDate = new Date(value);
            Utils.setTime(oldDate, 0, 0);
            Utils.setTime(newDate, 0, 0);
            const diffDays = Math.round(((newDate.getTime() - oldDate.getTime()) / 36e5) / 24);
            if (diffDays) {
                const newBeginDate = new Date(planningWipetruck.begindate);
                newBeginDate.setDate(newBeginDate.getDate() + diffDays);
                const newEndDate = new Date(planningWipetruck.enddate);
                newEndDate.setDate(newEndDate.getDate() + diffDays);
                planningWipetruck.begindate = newBeginDate;
                planningWipetruck.enddate = newEndDate;
                formGroup.get('date').get('endtime').setValue(newEndDate.getTime());
                formGroup.get('date').get('begintime').setValue(newBeginDate.getTime());
            }
        }));

        this.subscriptions.add(formGroup.get('date').valueChanges.subscribe(value => {
            this.convertFormDate(planningWipetruck, value);
            this.planningEntitiesList.forEach(planning => {
                this.genStartTimesMap(planning);
                if (planning.begindate) {
                    this.genEndTimesMap(planning);
                }
            });
        }));
        this.subscriptions.add(formGroup.get('entity_id').valueChanges.subscribe(value => {
            planningWipetruck.entity_id = value;
            if (planningWipetruck.entity_id) {
                planningWipetruck.entity = this.entities.filter(p => p.id === planningWipetruck.entity_id)[0];
                if (planningWipetruck?.entity?.use_once && planningWipetruck.hiring_id) {
                    formGroup.get('hiring_id').setValue(null);
                }
            } else {
                planningWipetruck.entity = null;
            }
            this.genStartTimesMap(planningWipetruck);
            if (planningWipetruck.begindate) {
                this.genEndTimesMap(planningWipetruck);
            }
        }));
        if (this.formsDisabled) {
            setTimeout(() => {
                formGroup.disable();
            });
        }
        return formGroup;
    }


    override addEntityPlanning() {
        const planningEntity = super.addEntityPlanning();
        this.genStartTimesMap(planningEntity);
        if (planningEntity.begindate) {
            this.genEndTimesMap(planningEntity);
        }
        return planningEntity;
    }
}


