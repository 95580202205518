import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ConfirmDialogService} from '../confirm-dialog-service/confirm-dialog.service';
import {MatDialog} from '@angular/material/dialog';

export interface ApiResponse<T = any> {
    success: boolean;
    message: string;
    data: T;
}

export interface ErrorData {
    error: string;
    errormessage: string;
    data: any;
}

export class ApiErrorResponse implements ApiResponse<ErrorData> {
    data: ErrorData;
    message: string;
    success: boolean;
}

@Injectable()
export class ApiService {

    response: Subject<object> = new Subject();
    response$: Observable<object>;


    constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private confirmDialogService: ConfirmDialogService) {
        this.response$ = this.response.asObservable();
    }

    public getCallObs<T>(apiUrl: string, params: object = {}): Observable<T> {

        const URLParams = this.buildURLParams(params);

        return this.http.get(`${environment.apiEndpoint}${apiUrl}`, {params: URLParams, headers: this.headers()})
            .pipe(map(response => response as T));
    }

    // get call with blob as return format
    public getBlobCall(apiUrl: string, params: object = {}): Observable<any> {
        const URLParams = this.buildURLParams(params);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + LocalStorage.getUserToken());
        headers = headers.append('Accept', '*');

        return this.http.get(`${environment.apiEndpoint}${apiUrl}`, {params: URLParams, responseType: 'blob', headers});
    }

    public getCall<T>(apiUrl: string, params: object = {}): Promise<T> {

        const URLParams = this.buildURLParams(params);

        return this.http.get(`${environment.apiEndpoint}${apiUrl}`, {params: URLParams, headers: this.headers()})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public postCall<T>(apiUrl: string, params: object = {}): Promise<T> {

        return this.http.post(`${environment.apiEndpoint}${apiUrl}`, params, {headers: this.headers()})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public postCallObserver<T>(apiUrl: string, params: any = {}): Observable<T> {

        return this.http.post(`${environment.apiEndpoint}${apiUrl}`, params, {headers: this.headers()}) as Observable<T>;

    }

    public patchCall<T>(apiUrl: string, params: object = {}): Promise<T> {

        return this.http.patch(`${environment.apiEndpoint}${apiUrl}`, params, {headers: this.headers()})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public putCall<T>(apiUrl: string, params: object = {}): Promise<T> {

        return this.http.put(`${environment.apiEndpoint}${apiUrl}`, params, {headers: this.headers()})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public deleteCall<T>(apiUrl: string, params: object = {}): Promise<T> {

        const URLParams = this.buildURLParams(params);

        return this.http.delete(`${environment.apiEndpoint}${apiUrl}`, {params: URLParams, headers: this.headers()})
            .toPromise()
            .then(response => {
                this.response.next(response);
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public makeFileRequest(url: string, file: File, extraData = {}): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            for (const key in extraData) {
                if (extraData.hasOwnProperty(key)) {
                    formData.append(key, extraData[key]);
                }
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

    public getCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        params = this.buildURLParams(params);

        return this.http.get(
            `${environment.apiEndpoint}${apiUrl}`, {params})
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    public postCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        return this.http.post(`${environment.apiEndpoint}${apiUrl}`, params)
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }

    public deleteCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {
        params = this.buildURLParams(params);

        return this.http.delete(
            `${environment.apiEndpoint}${apiUrl}`, {params})
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }


    /**
     * Make a request for one file
     * @param apiUrl
     * @param file
     * @param params
     */
    makeFileRequest$<T>(apiUrl: string, file: File, params = {}): Observable<ApiResponse<T>> {
        return new Observable(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    formData.append(key, params[key]);
                }
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(this.onError(xhr.response));
                        observer.complete();
                    }
                }
            };

            xhr.open('POST', `${Settings.API_ENDPOINT}${apiUrl}`, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

    private headers(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + LocalStorage.getUserToken());
        headers = headers.append('timeout', '1');
        return headers;
    }

    private buildURLParams(data: object): HttpParams {
        let params = new HttpParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params = params.set(key, data[key]);
            }
        }
        return params;
    }

    private handleError(error: any): Promise<any> {


        console.log('An error occured', error);

        const response = {
            success: false,
            message: error['error'] && typeof error['error']['message'] !== 'undefined' ? error['error']['message'] : '',
            data: {},
            error
        } as ApiResponse;


        if (error.status === 0) {
            response.data['errormessage'] = 'No response from server';
        } else {
            response.data['errormessage'] = error.message;
        }

        if (error.status === 401) { // Unauthorized
            const firstPart = this.router.url.split('/')[1];
            if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                console.log('API Service error, not unauthorized allowed, redirect to login');
                this.router.navigate(['/']);
            }

        }
        console.log(error.status);
        if (error.status === 409) {
            this.dialog.closeAll();
            this.confirmDialogService.confirm(
                'Conflict tijdens opslaan',
                'Dit item is vanaf een ander scherm aangepast sinds jij het hebt geopend. De wijzigingen zijn niet opgeslagen.'
            );
        }

        return Promise.reject(response);
    }

    private onError(error: any): ApiErrorResponse {
        const err = new ApiErrorResponse();
        if (!!error.error) {
            if (!!error?.error?.data) {
                err.data = error.error.data;
            }
            if (!!error?.error?.message) {
                err.message = error.error.message;
            }
            if (!!error?.error?.errormessage) {
                err.message = error.error.errormessage;
            }
        } else {
            if (typeof error === 'string') {
                try {
                    error = JSON.parse(error);
                } catch (e) {

                }
                err.data = error;
            } else {
                err.data = new class implements ErrorData {
                    data = error;
                    error: string;
                    errormessage: string;
                };
            }
            err.message = 'Some server error';

        }
        console.error(err);
        err.success = false;

        if (error.status === 401) { // Unauthorized
            this.router.navigateByUrl('logout');
        } else if (error.status === 403) {
            const firstPart = this.router.url.split('/')[1];
            if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                console.log('API Service error, not unauthorized allowed, redirect to login');

                this.router.navigate([]);
            }
        }

        return err;
    }

}
