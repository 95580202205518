<div class="modal-inner">

    <div class="modal-inner-inner" *ngIf="form">
        <h1>Wijzig projectnummer</h1>
        <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
        <div class="results">
            <div class="result text-center" *ngIf="searching">
                aan het zoeken...
            </div>
            <div class="result text-center" *ngIf="!searching && projects?.length === 0">
                {{fcProjectSearch.value?.length > 0 ? 'project niet gevonden' : 'voer een projectnummer in'}}
            </div>
            <div class="result"
                 [class.active]="project.projectId === form.controls.projectId.value"
                 (click)="selectProject(project.projectId, project.planningId)"
                 *ngFor="let project of projects">
                <strong>{{project.projectId}}</strong> {{project.location}}
                <ng-container *ngIf="project.projectId === form.controls.projectId.value"> (huidig)</ng-container>
            </div>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
