import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {PlanningService, TonsForDate} from '../../../services/planning/planning.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tons',
    templateUrl: './tons.component.html',
    styleUrls: ['./tons.component.scss']
})
export class TonsComponent implements OnInit, OnDestroy {

    public tons: TonsForDate;
    @HostBinding('class.print') @Input() print: boolean;
    @Input()
    private fromDate: Date;
    @Input()
    private toDate: Date;
    @Input()
    private date: Date;
    private tonSubscription: Subscription;

    @HostBinding('class.first') firstDate = false;
    @HostBinding('class.last') lastDate = false;

    constructor(private planningService: PlanningService) {
    }

    ngOnInit() {
        this.firstDate = this.date.getDate() === this.fromDate.getDate();
        const endDateCheck = new Date(this.date);
        endDateCheck.setDate(endDateCheck.getDate() + 1);
        this.lastDate = endDateCheck.getDate() === this.toDate.getDate();
        this.subscribeToTons();
    }

    ngOnDestroy() {
        if (this.tonSubscription) {
            this.tonSubscription.unsubscribe();
        }
    }

    private subscribeToTons() {
        if (this.tonSubscription) {
            this.tonSubscription.unsubscribe();
        }
        this.tonSubscription = this.planningService
            .getTons(this.fromDate, this.toDate, this.date).subscribe(tons => {
                if (this.lastDate) {
                    tons.tons = tons.tons.splice(0, 1);
                }
                this.tons = tons;
            });
    }
}
