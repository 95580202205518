import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {PlanningText} from '../../classes/planningtext.class';

@Injectable({
    providedIn: 'root'
})
export class PlanningTextService extends RealtimeService<PlanningText> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.planningText);
    }

    public itemInDaterange(entity: PlanningText, fromDate: Date, toDate: Date) {
        return true;
    }

    public saveText(id: number, text: string) {
        return this.apiService.postCall('planning/planningtext', {text, id});
    }
}
