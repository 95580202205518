import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

declare var tinymce: any;

@Component({
    selector: 'simple-transport-tiny',
    template: `
        <div id="{{elementId}}{{propertyName}}"></div>`,
    styleUrls: [`simple-transport-tiny.component.scss`]
})
export class SimpleTransportTinyComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @HostBinding('class.ng-touched') ngTouched: boolean = false;
    @HostBinding('class.ng-invalid') ngInvalid: boolean = false;

    @Input() contentCSS;
    @Input() elementId: String;
    @Input() content: any;
    @Input() height: number;
    @Input() propertyName = 'content';
    @Input() valueChanged: number;
    private focusDetectionInterval: any;
    @Output() onEditorKeyup = new EventEmitter<any>();
    hasFocus = false;
    editor;

    subscriptions = new Subscription();

    constructor(private matDialog: MatDialog,
                private renderer2: Renderer2) {
    }

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof FormControl) {


            const processedContent = [];
            const articleHtml = this.renderer2.createElement('article-content');
            articleHtml.innerHTML = editorcontent;

            articleHtml.childNodes.forEach((paragraph) => {
                let html = paragraph.outerHTML;
                if (html) {
                    html = html.replace(/\?access_token=["\S]*"/g, '"');
                    html = html.replace(/height="["\S]*"[ >]/g, '');
                }
                processedContent.push(html);
            });


            if (this.content.value !== processedContent.join('')) {
                this.content.setValue(processedContent.join(''));
            }
            setTimeout(() => {
                this.ngTouched = true;
                this.ngInvalid = this.content.invalid;
            });
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    private setEditorContent(content) {
        if (this.editor.getContent() !== content) {
            this.editor.setContent(content);
        }
    }

    ngOnChanges(changes) {
        if (this.editor) {
            if (!this.content[this.propertyName]) {
                this.content[this.propertyName] = '';
            }
            let content = this.content[this.propertyName];
            if (this.content instanceof FormControl) {
                content = this.content.value || '';
                if (this.content.disabled) {
                    this.editor.mode.set('readonly');
                }

            }
            this.setEditorContent(content);
            this.editor.undoManager.clear();
        }
        if (this.content instanceof FormControl) {
            this.subscriptions.add(this.content.statusChanges.subscribe(() => {
                this.ngInvalid = this.content.invalid;
                this.ngTouched = this.content.touched;
            }));
        }
    }

    ngAfterViewInit() {
        if (this.focusDetectionInterval) {
            clearInterval(this.focusDetectionInterval);
        }
        this.focusDetectionInterval = setInterval(() => {
            if (!document.hasFocus() && this.hasFocus) {
                this.updateview(this.editor);
            }
            this.hasFocus = document.hasFocus();
        }, 500);

        setTimeout(() => {

            tinymce.init({
                promotion: false,
                selector: '#' + this.elementId + this.propertyName,
                base_url: '/tinymce',
                suffix: '.min',
                browser_spellcheck: true,
                contextmenu: false,
                language: 'nl',
                newline_behavior: 'invert',
                menubar: '',
                plugins: ['link', 'fullscreen', 'quickbars', 'charmap', 'code', 'textcolor'].filter(p => !!p),
                autoresize_bottom_margin: 10,
                toolbar1: 'bold italic strikethrough forecolor backcolor',
                toolbar2: '',
                toolbar3: '',
                paste_as_text: true,
                paste_block_drop: true,
                formats: {
                    h2accent: {block: 'h2', classes: 'color-accent'}
                },
                quickbars_selection_toolbar: 'bold italic underline quicklink charmap forecolor backcolor',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                content_css: this.contentCSS ? `/assets/tinycss/${this.contentCSS}` : null,
                relative_urls: false,
                remove_script_host: false,
                min_height: this.height ?? 200,
                init_instance_callback: editor => {
                    if (!this.content[this.propertyName]) {
                        this.content[this.propertyName] = '';
                    }

                    let content = this.content[this.propertyName];
                    if (this.content instanceof FormControl) {
                        content = this.content.value || '';
                        if (this.content.disabled) {
                            this.editor.mode.set('readonly');
                        }
                    }

                    this.setEditorContent(content);
                },
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                    });
                    editor.on('focus', () => {
                        this.hasFocus = true;
                    });
                    editor.on('keyup', () => {
                        if (this.content instanceof FormControl) {
                            this.content.markAllAsTouched();
                        }
                        if (editor.getContent({format: 'html'}).trim().length !== this.content?.value?.length) {
                            this.content.markAsDirty();
                        }
                    });
                    editor.on('change', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                }
            });

        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
        if (this.focusDetectionInterval) {
            clearInterval(this.focusDetectionInterval);
        }
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.elementId += '' + (Math.round(Math.random() * 9999));
    }


}
