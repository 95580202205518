<div>
    <div class="d-flex align-items-center" mat-dialog-title>
        <div class="spacer title">Toolbox</div>
        <div>
            <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
        </div>
    </div>

    <div class="pb-3">

    </div>
</div>
<div class="dialog-content">
    <div class="d-flex justify-content-center align-content-center">
        <h3 class="spacer">
            Laatst gelezen toolbox door
        </h3>
    </div>
    <table class="table table-hover">
        <tr>
            <th>
                Gebruiker
            </th>
            <th>
                Gelezen op
            </th>
            <th>
                Toolbox
            </th>
        </tr>
        <tr *ngFor="let read of readBy; let i = index;">
            <td>
                {{read.user?.name}}
            </td>
            <td class="text-right">
                {{read.created_at | date : 'EEE dd MMMM yyyy HH:mm'}}
            </td>
            <td>
                {{read.toolbox?.begindate | date : 'EEE dd MMMM yyyy'}}
            </td>
        </tr>
    </table>
</div>
