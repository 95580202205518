<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Planning DvdS'"
                    subTitle="Verlof">
        </app-header>
    </div>
    <div class="indicator">

    </div>
    <div class="print-pdf">

    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="container">
    <mat-checkbox [formControl]="fcShowHistoric">Toon historie</mat-checkbox>
    <table class="table table-hover table-last-col">
        <thead>
        <tr>
            <th>
                Soort
            </th>
            <th>
                Opmerking
            </th>
            <th>
                Medewerkers
            </th>
            <th width="270">
                Periode
            </th>
            <th>

            </th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="edit(leave)"
            *ngFor="let leave of leaves" class="curpoint"
            [class.past]="leave.enddate | datetimeBefore : now"
            [class.current]="(leave.begindate | datetimeBefore : now) && !(leave.enddate | datetimeBefore : now)">
            <td width="160">
                {{leave.hourtype | hourtype}}
            </td>
            <td>
                {{leave.comment}}
            </td>
            <td *ngIf="!leave.common">
                <ng-container *ngFor="let user of leave.users; let last = last">
                    {{user.name}}{{last ? '' : ', '}}
                </ng-container>
            </td>
            <td *ngIf="leave.common">
                Bedrijfsbreed
            </td>
            <td>
                {{leave.begindate | date :'EEE d MMM yyyy'}}
                <ng-container *ngIf="(leave.begindate | isOtherDate : leave.enddate)">
                    t/m {{leave.enddate | date :'EEE d MMM yyyy'}}
                </ng-container>
            </td>
            <td>
                <button (click)="delete($event, leave)" color="warn" mat-icon-button>
                    <i class="fas fa-trash"></i></button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<div class="button-wrapper">
    <div class="container-xl">
        <div class="buttons">
            <button mat-raised-button color="primary" (click)="edit()">
                Verlof toevoegen
            </button>
        </div>
    </div>
</div>
