<div *ngIf="firstDate">

</div>
<div *ngFor="let trucksUsed of sizes?.perSize"
     [class.print]="print">
    <div class="count">
        <div class="icon" [class.zero]="trucksUsed.get(PlanningSize.Hotbox) === 0">
            <i class="fa-solid fa-truck-pickup"></i>
        </div>
        <div>
            {{trucksUsed.get(PlanningSize.Hotbox)}}
        </div>
    </div>
    <div class="count">
        <div class="icon" [class.zero]="trucksUsed.get(PlanningSize.Middel) === 0">
            <i class="fa-regular fa-truck"></i>
        </div>
        <div>
            {{trucksUsed.get(PlanningSize.Middel)}}
        </div>
    </div>
    <div class="count">
        <div class="icon large" [class.zero]="trucksUsed.get(PlanningSize.Groot) === 0">
            <i class="fa-solid fa-truck"></i>
        </div>
        <div>
            {{trucksUsed.get(PlanningSize.Groot)}}
        </div>
    </div>
</div>
<div *ngIf="lastDate">

</div>
