import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationService} from '../services/realisation.service';
import {Utils} from '../utils.class';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {debounceTime, first, tap} from 'rxjs/operators';
import {AfasService} from '../services/afas.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {combineLatest, Subscription} from 'rxjs';
import {FormControl} from '@ngneat/reactive-forms';
import {LocalStorage} from '../storage.class';
import {HourtypePipe} from '../pipes/hourtype.pipe';

@Component({
    selector: 'app-week-check-realisations',
    templateUrl: './week-check-realisations.component.html',
    styleUrls: ['./week-check-realisations.component.scss']
})
export class WeekCheckRealisationsComponent extends CodaltComponent implements OnInit {

    date: Date;
    userRealisationMap = new Map<string, UserRealisations>();
    userTotal = new Map<number, number>();
    users: User[];
    show = true;
    beginDate: Date;
    endDate: Date;
    week: number;
    year: number;
    loopDates = [];
    userFunctions: string[];
    weekSubscriptions = new Subscription();
    fcUserFunction = new FormControl<string[]>(LocalStorage.userFunctionFilter);
    fcSort = new FormControl<string>('name');

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private afasService: AfasService,
                private userService: UserService,
                private title: Title,
                private realisationService: RealisationService) {
        super();
        this.title.setTitle('Weekcontrole' + environment.titleAppend);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string }) => {
            const today = new Date();
            const paramDate = params.date;
            this.date = paramDate ? new Date(paramDate) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));

            this.getData();
        }));
        this.subscriptions.add(this.userService.userFunctions(false).subscribe(functions => {
            this.userFunctions = functions.data;
        }));
        this.subscriptions.add(this.fcUserFunction.valueChanges.pipe(debounceTime(1000)).subscribe(functions => {
            LocalStorage.userFunctionFilter = functions;
            this.getData();
        }));
        this.subscriptions.add(this.fcSort.valueChanges.pipe(debounceTime(1000)).subscribe(functions => {
            this.getData();
        }));
    }

    private getUserRealisation(date: Date, userId: number) {
        const user = this.users.find(u => u.id === userId);
        if (user) {
            let ur = this.userRealisationMap.get(Utils.formatDate(date) + userId);
            if (!ur) {
                ur = {
                    totaltime: 0,
                    break: 0,
                    projects: [],
                    isNight: false,
                    anyUnapproved: false
                } as UserRealisations;
                this.userRealisationMap.set(Utils.formatDate(date) + userId, ur);
            }
            return ur;
        }
    }

    private getData() {
        this.weekSubscriptions?.unsubscribe();
        this.weekSubscriptions = new Subscription();
        this.weekSubscriptions.add(this.activatedRoute.params.subscribe((params: { year, week }) => {
            let beginDate = Utils.getMonday(new Date());
            Utils.setTime(beginDate, 0, 0);
            if (params?.year) {
                beginDate.setFullYear(params.year);
                beginDate = Utils.getMonday(beginDate);
            }
            if (params?.week) {
                beginDate.setDate(beginDate.getDate() - ((Utils.getWeek(beginDate) - +params?.week) * 7));
            }
            this.week = Utils.getWeek(beginDate);
            this.beginDate = new Date(beginDate);
            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 7);
            this.year = this.beginDate.getFullYear();
            this.loopDates = [];
            const subs$ = [];
            const loopdate = Utils.newDate(this.beginDate);
            while (loopdate < this.endDate) {
                this.loopDates.push(Utils.newDate(loopdate));
                subs$.push(this.realisationService.getDaySchedule(loopdate).pipe(tap(realisations =>
                    realisations.data
                        .filter(r => !r.removed)
                        .forEach(rea => {
                            const user = this.users.find(u => u.id === rea.user_id);
                            if (user) {
                                const ur = this.getUserRealisation(rea.bookdate, rea.user_id);

                                ur.totaltime += Utils.minuteDuration(rea.enddate, rea.begindate);
                                ur.isNight = ur.isNight || Utils.isNight(rea.begindate, rea.enddate);
                                ur.anyUnapproved = ur.anyUnapproved || !rea.approved;
                                const name = Utils.realisationAfasProjectId(rea) ?? ((rea.leave?.comment ?? '') + ' (' + (new HourtypePipe()).transform(rea.hourtype)) + ')';
                                if (ur.projects.indexOf(name) === -1) {
                                    ur.projects.push(name);
                                }

                                ur.totaltime -= rea.pause;
                                const back24h = new Date();
                                back24h.setDate(back24h.getDate() - 1);
                            }
                        })
                )));
                loopdate.setDate(loopdate.getDate() + 1);
            }
            subs$.push(this.afasService.getAanAfwezigheid(this.beginDate, this.endDate).pipe(tap(aanAfwezigheid => {
                aanAfwezigheid.data?.forEach(aanAfw => {
                    const user = this.users.find(usr => usr.afas_employee_id === aanAfw.employeeId);
                    if (user) {
                        const ur = this.getUserRealisation(aanAfw.startDate, user.id);
                        if (!!aanAfw.typeverzuim) {
                            ur.totaltime += aanAfw.hours;
                        } else {
                            ur.totaltime += aanAfw.hours;
                        }
                        const name = aanAfw.typeverzuim ?? aanAfw.leaveCode;
                        if (ur.projects.indexOf(name) === -1) {
                            ur.projects.push(name);
                        }
                    }
                });
            })));
            this.userRealisationMap = new Map<string, UserRealisations>();
            this.userTotal = new Map<number, number>();
            this.weekSubscriptions.add(this.userService.getList(false, true, false).pipe(debounceTime(500), first()).subscribe(users => {
                this.users = users.filter(user => {
                    return (!this.fcUserFunction.value?.length || this.fcUserFunction.value?.indexOf(user.function) !== -1) && !!user.afas_employee_id;
                });
                this.weekSubscriptions.add(combineLatest(subs$).subscribe(() => {
                    this.users.forEach(user => {
                        const loopDateTotal = Utils.newDate(this.beginDate);
                        while (loopDateTotal < this.endDate) {
                            this.userTotal.set(user.id, (this.userTotal.get(user.id) ?? 0) + this.getUserRealisation(loopDateTotal, user.id).totaltime);
                            loopDateTotal.setDate(loopDateTotal.getDate() + 1);
                        }
                    });
                    this.users = this.users.sort((a, b) => {
                        if (this.fcSort.value === 'name') {
                            return a?.lastname?.localeCompare(b?.lastname);
                        }
                        if (this.fcSort.value === 'hours') {
                            return (this.userTotal.get(a.id) ?? 0) - (this.userTotal.get(b.id) ?? 0);
                        }
                    });
                }));
            }));
        }));
    }

    next() {
        let week = this.week + 1;
        let year = this.year;
        if (week > 52) {
            week = 1;
            year += 1;
        }
        this.router.navigateByUrl(`weekcontrole/${year}/${week}`);
    }

    prev() {
        let week = this.week - 1;
        let year = this.year;
        if (week < 1) {
            week = 52;
            year -= 1;
        }
        this.router.navigateByUrl(`weekcontrole/${year}/${week}`);
    }
    editHours(date: Date, userId: number) {
        this.router.navigate([
            `/hours/${formatDate(date, 'yyyy-MM-dd', 'nl')}/${userId}`,
            {backTo: window.location.pathname}
        ]);
    }
}

interface UserRealisations {
    totaltime: number;
    break: number;
    isNight: boolean;
    pause_adjusted: boolean;
    projects: string[];
    anyUnapproved: boolean;
}
