import {User} from './user.class';
import {Toolbox} from './toolbox';

export class ToolboxRead {
    toolbox_id: number;
    user_id: number;

    user: User;
    toolbox: Toolbox;

    created_at: Date;
    updated_at: Date;
}
