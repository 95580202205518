import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Planning} from '../classes/planning.class';
import {combineLatest, Subscription} from 'rxjs';
import {EntitiesService} from '../services/entities/entities.service';
import {PlanningDay} from '../classes/planning-day.class';
import {PlanningDayItem} from '../classes/planning-day-item.class';
import {Utils} from '../utils.class';
import {Entities} from '../classes/entities';
import {Entity} from '../classes/entity.class';
import {PlanningService} from '../services/planning/planning.service';
import {EntityUnavailableService} from '../services/entities/entity-unavailable.service';
import {PlanningOverviewViewModel} from './planning-overview-view-model';
import {PlanningFixedService} from '../services/planning/planning-fixed.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {SetPlanningFixedDialogComponent} from './set-planning-fixed-dialog/set-planning-fixed-dialog.component';
import {EntityUnavailable} from '../classes/entityunavailable.class';
import {PlanningDetailDialogService} from '../services/dialog/planning-detail-dialog.service';
import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {Settings} from '../settings.class';
import {ActivatedRoute} from '@angular/router';
import {AutocompleteService, AutocompleteType} from '../services/planning/autocomplete.service';
import {UserService} from '../services/user/user.service';
import {environment} from '../../environments/environment';
import {LocalStorage} from '../storage.class';
import {Title} from '@angular/platform-browser';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-planning-overview',
    templateUrl: './planning-overview.component.html',
    styleUrls: ['./planning-overview.component.scss']
})
export class PlanningOverviewComponent implements OnInit, OnDestroy {

    public viewModel = new PlanningOverviewViewModel();
    public fromDate: Date;
    public toDate: Date;
    public fixedUntil: Date;
    public primaryWeekStart: Date;
    public primaryWeekEnd: Date;
    public planningDays: PlanningDay[];
    public planningEntities: Entity[];
    entityTypes: EntityType[];
    public print = false;
    teams = [EntityTypeCode.AsfaltTeam];
    environment = environment;
    private dayWith = Settings.DAY_WIDTH;
    EntityTypeCode = EntityTypeCode;
    Settings = Settings;
    private planningSubscriptions = new Subscription();

    entityIdPlanningCount = new Map<number, number>();

    mobile = false;

    constructor(public dialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private planningDetailDialogService: PlanningDetailDialogService,
                private planningFixedService: PlanningFixedService,
                private entityUnavailableService: EntityUnavailableService,
                private entitiesService: EntitiesService,
                private planningService: PlanningService,
                private activatedRoute: ActivatedRoute,
                private autocompleteService: AutocompleteService,
                private userService: UserService,
                private breakpointObserver: BreakpointObserver,
                private title: Title) {
        this.title.setTitle('Weekplanning' + environment.titleAppend);
    }

    @HostListener('window:resize')
    onResize() {
        const numberOfDays = this.calculateNumberOfDays();
        this.setDateRange(this.fromDate, numberOfDays);
    }

    ngOnInit() {
        this.breakpointObserver.observe('(max-width: 722px)').subscribe(match => {
            this.mobile = match.matches;
        });
        let centerDate = new Date();
        const routeSnapshot = this.activatedRoute.snapshot;
        const routePath = routeSnapshot.url[0]?.path;
        if (routePath === 'print') {
            this.print = true;
            centerDate = new Date(routeSnapshot.params.date);
        }
        this.viewModel.updateViewSate();
        this.setCenterDate(centerDate);

        // Subscribe to enable realtime updates
        this.autocompleteService.getByType(AutocompleteType.contractors).subscribe().unsubscribe();
        this.userService.getMap().subscribe().unsubscribe();
    }

    selectedTeamsChange(typeIds) {
        this.teams = typeIds;
        LocalStorage.PlanningFilter = typeIds;
        this.initPlanningSubscription();
    }

    setCenterDate(centerDate?: Date) {
        if (!centerDate) {
            centerDate = new Date();
        }
        const numberOfDays = this.calculateNumberOfDays();
        const fromDate = Utils.setTime(centerDate, 0, 0);
        // Mon = 1, Sun = 7
        const dayOfWeek = fromDate.getDay() === 0 ? 7 : fromDate.getDay();
        // maxBack = offset till last monday. ( thue = 2, -1 = mon, sun = 7, -6 = mon )
        const maxBack = dayOfWeek - 1;
        const remainingDaysThisWeek = (8 - dayOfWeek);

        const daysTosubtract = Math.min(maxBack, Math.max(numberOfDays - remainingDaysThisWeek, 0));
        fromDate.setDate(fromDate.getDate() - daysTosubtract);
        this.setDateRange(fromDate, numberOfDays);
    }

    calculateNumberOfDays() {
        return Math.max(1, Math.floor((window.innerWidth - 107) / this.dayWith));
    }

    prev() {
        const numberOfDays = this.calculateNumberOfDays();
        const fromDate = new Date(this.fromDate);
        fromDate.setDate(fromDate.getDate() - Math.min(numberOfDays, 7));
        this.setDateRange(fromDate, numberOfDays);
    }

    next() {
        const numberOfDays = this.calculateNumberOfDays();
        const fromDate = new Date(this.fromDate);
        fromDate.setDate(fromDate.getDate() + Math.min(numberOfDays, 7));
        this.setDateRange(fromDate, numberOfDays);
    }

    openPlanning(planning: Planning) {
        this.planningDetailDialogService.openPlanning(planning);
    }

    ngOnDestroy(): void {
        this.planningSubscriptions.unsubscribe();
    }

    public mouseEnterDate(day: PlanningDay) {
        this.planningDays.forEach(planningDay => {
            planningDay.fixed = planningDay.date.getTime() <= new Date(day.date).getTime();
        });
    }

    public mouseLeaveDate(day: PlanningDay) {
        this.planningDays.forEach(planningDay => {
            planningDay.fixed = planningDay.date.getTime() <= new Date(this.fixedUntil).getTime();
        });
    }

    public setFixed(day: PlanningDay) {
        if (this.viewModel.lockPlanning) {
            const ref = this.dialog.open(SetPlanningFixedDialogComponent, {
                data: day
            });
            ref.afterOpened().subscribe(() => {
                this.mouseEnterDate(day);
            });
            const subs = ref.afterClosed().subscribe(() => {
                this.mouseLeaveDate(day);
                subs.unsubscribe();
            });
        }
    }

    private setDateRange(fromDate, numberOfDays) {
        const newToDate = new Date(fromDate);
        newToDate.setDate(newToDate.getDate() + numberOfDays);
        if (!this.fromDate || this.fromDate.getTime() !== fromDate.getTime() ||
            !this.toDate || this.toDate.getTime() !== newToDate.getTime()) {
            this.fromDate = fromDate;
            this.toDate = newToDate;
            this.calculatePrimaryWeek();
            this.initPlanningSubscription();
        }
    }

    private calculatePrimaryWeek() {
        const weeksCount = {}; // The count of days visible per week
        const weekdates = {}; // First date for each week

        const loopDate = new Date(this.fromDate);
        while (loopDate.getTime() < this.toDate.getTime()) {
            const weeknumber = Utils.getWeek(loopDate);
            if (!weeksCount[weeknumber]) {
                weeksCount[weeknumber] = 0;
                weekdates[weeknumber] = new Date(loopDate);
            }
            weeksCount[weeknumber]++;
            loopDate.setDate(loopDate.getDate() + 1);
        }
        const primaryWeek = Object.keys(weeksCount).sort((b, a) => {
            return weeksCount[a] - weeksCount[b];
        })[0];
        this.primaryWeekStart = weekdates[primaryWeek];

        const showMaxDays = 8 - Utils.dutchDayOfWeek(this.primaryWeekStart);
        const daysToLastVisibleDay = Math.ceil((this.toDate.getTime() - this.primaryWeekStart.getTime()) / (1000 * 60 * 60 * 24));

        this.primaryWeekEnd = Utils.newDate(this.primaryWeekStart);
        this.primaryWeekEnd.setDate(this.primaryWeekEnd.getDate() + Math.min(showMaxDays, daysToLastVisibleDay));
    }

    private initPlanningSubscription() {
        this.planningSubscriptions.unsubscribe();
        this.planningSubscriptions = new Subscription();
        let reInitialize = true;
        const entities$ = this.entitiesService.getMap();
        const requestFromDate = new Date(this.fromDate);
        requestFromDate.setDate(requestFromDate.getDate() - 2);
        const requestToDate = new Date(this.toDate);
        requestToDate.setDate(requestToDate.getDate() + 2);
        const planning$ = this.planningService.getFilteredList(requestFromDate, requestToDate);
        const unavailable$ = this.entityUnavailableService.getFilteredList(requestFromDate, requestToDate);
        const fixed$ = this.planningFixedService.getLatestItem();
        const types$ = this.entitiesService.getTypes();
        const entityIdPlanningCount = new Map<number, number>();
        this.planningSubscriptions.add(combineLatest(entities$, planning$, unavailable$, fixed$, types$)
            .subscribe(([entitiesMap, planningList, unavailableList, fixed, types]) => {
                this.entityTypes = types.data;
                this.fixedUntil = new Date(fixed.fixeduntil);
                const planningDaysMap = new Map<string, PlanningDay>();
                const countDate = new Date(this.fromDate);
                const visibleEntityIds = [].concat(...this.teams).concat(...this.entityTypes?.filter(p => !!p.visible_overview).map(p => p.id));
                this.planningEntities = Array.from(entitiesMap.values())
                    .filter(e => e.entitytypes?.filter(et => visibleEntityIds.includes(et.id)).length > 0)
                    .filter(e => e.enddate === null || new Date(e.enddate).getTime() >= this.fromDate.getTime())
                    .sort((a: Entity, b: Entity) => {

                        const aNumber = a.entitytypes[0]?.order ?? a.entitytype_id;
                        const bNumber = b.entitytypes[0]?.order ?? b.entitytype_id;
                        if (aNumber > bNumber) {
                            return 1;
                        }
                        if (aNumber < bNumber) {
                            return -1;
                        }
                        return 0;
                    });
                const entityEndDateUsed = new Map<Entity, boolean>();
                while (countDate.getTime() < this.toDate.getTime()) {
                    const planningDay = new PlanningDay();
                    planningDay.date = Utils.setTime(new Date(countDate), 0, 0);
                    planningDay.fixed = planningDay.date.getTime() <= new Date(fixed.fixeduntil).getTime();
                    this.planningEntities.forEach((entity) => {
                        const planningDayGroup = new Entities();
                        planningDayGroup.entity = entity;
                        if (!entityEndDateUsed.get(entity) && entity.enddate && new Date(entity.enddate).getTime() <= countDate.getTime()) {
                            entityEndDateUsed.set(entity, true);
                            const unav = new EntityUnavailable();
                            unav.begindate = entity.enddate;
                            unav.enddate = this.toDate;
                            unav.entity_id = entity.id;
                            unavailableList.push(unav);
                        }
                        planningDay.entities.push(planningDayGroup);
                    });
                    planningDaysMap.set(Utils.formatDate(countDate), planningDay);
                    countDate.setDate(countDate.getDate() + 1);
                }

                unavailableList.forEach(unavailable => {
                    const beginDate = new Date(unavailable.begindate);
                    const firstDateInside = new Date(this.fromDate);
                    while (firstDateInside.getTime() < beginDate.getTime()) {
                        firstDateInside.setDate(firstDateInside.getDate() + 1);
                    }
                    const pdm = planningDaysMap.get(Utils.formatDate(firstDateInside));
                    if (pdm) {
                        const entityGroup = pdm.entities.find(e => e.entity.id === unavailable.entity_id);
                        if (entityGroup) {
                            const duration = (new Date(unavailable.enddate).getTime() - firstDateInside.getTime());
                            const currentRangeDuration = (this.toDate.getTime() - firstDateInside.getTime());
                            entityGroup.unavailableDays = duration <= currentRangeDuration ? Math.round(duration / 86400000) : Math.round(currentRangeDuration / 86400000);
                        }
                    }
                });
                planningList.forEach(planning => {
                    Utils.planningAllEntities(planning).forEach(plnEntity => {
                        plnEntity.entity = entitiesMap.get(plnEntity.entity_id);
                        let count = entityIdPlanningCount.get(plnEntity.entity_id) || 0;
                        entityIdPlanningCount.set(plnEntity.entity_id, count + 1);
                    });

                    const allEntities = Utils.planningAllEntities(planning).filter(e => visibleEntityIds.includes(e.entitytype_id));
                    allEntities.forEach(planningEntity => {
                        if (planningEntity) {
                            planningEntity.entity = entitiesMap.get(planningEntity.entity_id);
                            const beginDate = new Date(planningEntity.begindate);
                            const endDate = new Date(planningEntity.enddate);
                            const mapDate = Utils.formatDate(beginDate);
                            const planningDay = planningDaysMap.get(mapDate);
                            if (planningDay) {
                                const diff = Math.round(Math.abs((endDate.getTime() - beginDate.getTime()) / 60000));
                                const planningDayItem = new PlanningDayItem();
                                planningDayItem.planning = planning;
                                planningDayItem.planningEntity = planningEntity;
                                planningDayItem.duration = diff;
                                planningDayItem.minutesOffset = (beginDate.getHours() * 60) + beginDate.getMinutes();
                                const entityGroup = planningDay.entities.find(group => group.entity.id === planningEntity.entity_id);
                                if (entityGroup) {
                                    entityGroup.items.push(planningDayItem);
                                    planningDaysMap.set(mapDate, planningDay);
                                } else {
                                    console.warn('Entitygroup not found, planned outside availability time', planningEntity.entity_id, planningEntity.begindate);
                                }
                            }
                        }
                    });
                });
                setTimeout(() => {
                    this.entityIdPlanningCount = entityIdPlanningCount;
                }, 100);
                if (reInitialize) {
                    this.planningDays = Array.from(planningDaysMap.values());
                } else {
                    this.planningDays.forEach(pd => {
                        pd.entities = planningDaysMap.get(Utils.formatDate(pd.date)).entities;
                    });
                }
                reInitialize = false;
            }));
    }
}


