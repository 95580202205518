import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {Utils} from '../../utils.class';

@Injectable({
    providedIn: 'root'
})
export class PlanningHasService extends RealtimeService<PlanningHasEntity> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.planningHas, 'id');
    }

    itemInDaterange(planning: PlanningHasEntity, fromDate: Date, toDate: Date) {
        return Utils.getTimeOrNull(planning.begindate) < Utils.getTimeOrNull(toDate)
            && Utils.getTimeOrNull(planning.enddate) > Utils.getTimeOrNull(fromDate);
    }


}
