<div class="modal-inner">
    <div class="modal-inner-inner w-100 mb-3">
        <h1>Exporteer PDF</h1>
        <div>
            <mat-checkbox [formControl]="fcOnlyWeekTotals">Toon alleen weektotalen</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="fcNoHours">Verberg alle uren</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="fcNoComments">Verberg commentaren</mat-checkbox>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button
                class="planning-button confirm"
                color="primary"
                (click)="pdf()">
            PDF maken
            <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
        </button>
        <button *ngIf="!downloadingPdf" mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
