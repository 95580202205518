import {FormControl, FormGroup} from '@angular/forms';
import {of} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {PlanningService} from '../services/planning/planning.service';
import {PlanningSet} from '../classes/planningset.class';
import {PlanningCutter} from '../classes/planningcutter.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {PlanningAsfaltteam} from '../classes/planningasfaltteam.class';
import {Utils} from '../utils.class';
import {PlanningHasService} from '../services/planning/planning-has.service';
import {Entity} from '../classes/entity.class';

export const entityAvailableValidator = (planningHasService: PlanningHasService,
                                         entitiesMap: Map<number, Entity>,
                                         planning?: (PlanningAsfaltteam | PlanningCutter | PlanningHasEntity | PlanningSet)) => {
    return (input: FormGroup | FormControl) => {
        if (planning?.begindate || !!input.get('begindate')?.value) {
            let begindate = new Date(planning?.begindate);
            let enddate = new Date(planning?.enddate);
            let entityId = input.value['entity_id'] || planning.entity_id;
            const planningId = planning?.planning_id || input.get('planning_id')?.value;
            if (input instanceof FormGroup) {
                if (!!input.get('enddate') && !!input.get('begindate')) {
                    begindate = new Date(input.get('begindate').value);
                    enddate = new Date(input.get('enddate').value);
                } else {
                    const dateFromGroup = typeof input.controls['begintime'] === 'undefined' ? input.controls['date'] as FormGroup : input;
                    if (!dateFromGroup.controls['begintime'].value) {
                        return of({notAvailable: ''});
                    }

                    if (dateFromGroup.controls['begintime'].value && dateFromGroup.controls['begintime'].value.length < 6) {
                        const startTime = (dateFromGroup.controls['begintime'].value).split(':');
                        const endTime = (dateFromGroup.controls['endtime'].value).split(':');
                        begindate.setHours(+startTime[0]);
                        begindate.setMinutes(+startTime[1]);
                        enddate.setHours(+endTime[0]);
                        enddate.setMinutes(+endTime[1]);
                        begindate.setSeconds(0);
                        enddate.setSeconds(0);
                    } else {
                        begindate.setTime(dateFromGroup.controls['begintime'].value);
                        enddate.setTime(dateFromGroup.controls['endtime'].value);
                    }
                }

            } else {
                entityId = input.value;
            }
            if (!entityId || entityId?.length === 0) {
                return of(null);
            }

            const begindateTIME = Utils.getTimeOrNull(begindate);
            const enddateTIME = Utils.getTimeOrNull(enddate);
            let res = false;
            if (!Array.isArray(entityId)) {
                entityId = [entityId];
            }
            entityId = entityId.filter(eid => entitiesMap?.get(eid)?.use_once);
            return planningHasService.getFilteredList(Utils.newDate(begindate), Utils.newDate(enddate)).pipe(
                first(),
                map(planningHass => {
                    const unavailableItems = [];
                    planningHass?.forEach(planningHas => {
                        if (planningHas.planning_id !== planningId) {
                            [planningHas.entity_id, planningHas.truck_entity_id, planningHas.lowloader_entity_id].forEach(pheEntityId => {
                                if (entityId.includes(pheEntityId)) {
                                    if (Utils.getTimeOrNull(planningHas.begindate) < enddateTIME && Utils.getTimeOrNull(planningHas.enddate) > begindateTIME) {
                                        res = true;
                                        unavailableItems.push(pheEntityId);
                                    }
                                }
                            });
                        }
                    });
                    const text = unavailableItems.map(uai => entitiesMap.get(uai)?.name).join(', ') + ' niet beschikbaar';
                    if (!!input.get('conflictText')) {
                        input.get('conflictText').setValue(text, {emitEvent: false, onlySelf: true});
                    }
                    return res ? {notAvailable: text} : null;
                })
            );

        }
        return of(null);
    };
};
