import {User} from '../../classes/user.class';
import {Planning} from '../../classes/planning.class';
import {UserService, UserType} from '../../services/user/user.service';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {PlanningStatus} from '../../planning-status.enum';

export class PlanningDetailViewModel {

    asphalt_location = false;
    traffic_controllers = true;
    traffic = true;
    mobile_crane = true;
    pouring_off = true;
    asphaltOthers = true;

    size = true;
    scatter = true;
    sticky_wagons = true;
    hire_people = true;
    set = true;
    asphalt_performer = true;
    dumpers = true;
    cranes = true;
    rollers = true;
    asfaltteam = true;
    preparationteam = true;
    projectteam = true;
    cutter = true;
    wipetruck = true;
    driver_user_id = false;

    contractor = true;
    performer = true;
    projectmanager = true;
    projectmanagerNotOwn = true;
    location = true;
    worknumber = true;


    cutterList = true;
    wipertruckList = true;

    asphaltFormDisabled = true;
    formDisabled = true;
    statusDisabled = false;
    fixedStatusDisabled = false;


    sleepOrBlock = false;

    updateViewState(entityTypeCode: EntityTypeCode, user: User, planning: Planning, fixed = true) {
        const entityTypeAsfaltTeam = entityTypeCode === EntityTypeCode.AsfaltTeam;
        const entityTypeProjectTeam = entityTypeCode === EntityTypeCode.ProjectTeam;
        const entityTypePreparationTeam = entityTypeCode === EntityTypeCode.PreparationTeam;
        const entityTypeWipetruck = entityTypeCode === EntityTypeCode.Wipetruck;
        const entityTypeCutter = entityTypeCode === EntityTypeCode.Cutter;

        this.sleepOrBlock = [PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(planning.status_id);
        const anyTeam = entityTypeAsfaltTeam || entityTypeProjectTeam || entityTypeProjectTeam;

        this.size = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.set = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.asfaltteam = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.projectteam = entityTypeProjectTeam && !this.sleepOrBlock;
        this.preparationteam = entityTypePreparationTeam && !this.sleepOrBlock;
        this.sticky_wagons = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.hire_people = anyTeam && !this.sleepOrBlock;
        this.pouring_off = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.asphaltOthers = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.asphalt_location = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.scatter = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.cutter = entityTypeCutter && !this.sleepOrBlock;
        this.wipertruckList = (entityTypeCutter || anyTeam) && !this.sleepOrBlock;
        this.cutterList = (entityTypeAsfaltTeam || entityTypePreparationTeam) && !this.sleepOrBlock;
        this.wipetruck = entityTypeWipetruck && !this.sleepOrBlock;
        this.driver_user_id = (entityTypeCutter || entityTypeWipetruck) && !this.sleepOrBlock && UserService.userHasRights(UserType.WORKPLANNER);
        this.asphalt_performer = entityTypeAsfaltTeam && !this.sleepOrBlock;
        this.dumpers = anyTeam && !this.sleepOrBlock;
        this.cranes = anyTeam && !this.sleepOrBlock;
        this.rollers = anyTeam && !this.sleepOrBlock;
        this.traffic_controllers = !this.sleepOrBlock;
        this.traffic = !this.sleepOrBlock;
        this.contractor = planning.status_id !== PlanningStatus.geblokkeerd;
        this.performer = !this.sleepOrBlock;
        this.projectmanager = !this.sleepOrBlock;
        this.projectmanagerNotOwn = UserService.userHasRights(UserType.WORKPLANNER);
        this.location = !this.sleepOrBlock;
        this.worknumber = planning.status_id !== PlanningStatus.geblokkeerd;

        this.asphaltFormDisabled = ((user.id !== planning.projectmanager) && !UserService.userHasRights(UserType.ASPHALTCOORDINATOR) && !UserService.userHasRights(UserType.WORKPLANNER));
        this.formDisabled = ((user.id !== planning.projectmanager || fixed) && !UserService.userHasRights(UserType.WORKPLANNER) && !UserService.userHasRights(UserType.ASPHALTCOORDINATOR));
        this.statusDisabled = fixed && !UserService.userHasRights(UserType.WORKPLANNER);
        this.fixedStatusDisabled = fixed;
    }
}
