import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Planning} from '../../classes/planning.class';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {UserPlanning} from '../../classes/user-planning';
import {UserPlanningService} from '../../services/planning/user-planning.service';
import {Utils} from '../../utils.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Validators} from '@angular/forms';
import {employeeAvailableValidator} from '../../validators/employee-available.validator';
import {AddressDialogComponent} from './address-dialog/address-dialog.component';
import {LocationService} from '../../services/location.service';
import {first} from 'rxjs/operators';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';

@Component({
    selector: 'app-employees-dialog',
    templateUrl: './employees-dialog.component.html',
    styleUrls: ['./employees-dialog.component.scss']
})
export class EmployeesDialogComponent implements OnInit, OnDestroy {

    saving: boolean;
    form: FormArray<ControlsOf<FormGroupUserPlanning>>;
    employees: User[];
    filteredEmployees: User[];
    sums: { function: string; amount: number; }[] = [];
    fcEmployee = new FormControl();
    planning: Planning;
    mainPlanning: PlanningHasEntity;
    private subscriptions = new Subscription();

    constructor(public dialogRef: MatDialogRef<EmployeesDialogComponent>,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                @Inject(MAT_DIALOG_DATA) data: {
                    planning: Planning
                },
                private userPlanningService: UserPlanningService,
                private locationService: LocationService,
                private userService: UserService) {
        this.planning = data.planning;

        this.subscriptions.add(this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                this.close();
            }
        }));
    }

    ngOnInit(): void {
        this.form = new FormArray([]);
        this.subscriptions.add(this.userService.getList(false, false).pipe(first()).subscribe(users => {
            this.employees = users;
            this.planning.user_planning?.forEach(userPlanning => {
                const fg = new FormGroup<ControlsOf<FormGroupUserPlanning>>({
                    id: new FormControl(userPlanning.id),
                    user_id: new FormControl(+userPlanning.user_id),
                    planning_id: new FormControl(userPlanning.planning_id),
                    begindate: new FormControl(userPlanning.begindate),
                    enddate: new FormControl(userPlanning.enddate),
                    conflictText: new FormControl(''),
                    comment: new FormControl(userPlanning.comment),
                    function: new FormControl(this.employees.find(e => e.id === userPlanning.user_id)?.function),
                    employee: new FormControl(this.employees.find(e => e.id === userPlanning.user_id)?.name),
                    updated_at: new FormControl(userPlanning.updated_at),
                    origin: new FormControl(userPlanning.origin),
                    destination: new FormControl(userPlanning.destination),
                    work_begin: new FormControl(userPlanning.work_begin),
                    work_end: new FormControl(userPlanning.work_end)
                }, [], employeeAvailableValidator(this.userPlanningService));
                this.subscriptions.add(fg.controls.begindate.valueChanges.subscribe(() => {
                    fg.controls.enddate.updateValueAndValidity();
                }));
                this.subscriptions.add(fg.controls.enddate.valueChanges.subscribe(enddate => {
                    const begindate = new Date(fg.controls.begindate.value);
                    enddate = new Date(enddate);
                    begindate.setSeconds(0);
                    const endTime = new Date(begindate);
                    endTime.setHours(enddate.getHours());
                    endTime.setMinutes(enddate.getMinutes());

                    if (endTime < begindate && enddate.getDate() !== begindate.getDate() + 1) {
                        enddate.setDate(enddate.getDate() + 1);
                        fg.controls.enddate.setValue(enddate, {emitEvent: false, onlySelf: true});
                    }
                    if (endTime > begindate && enddate.getDate() !== begindate.getDate()) {
                        enddate.setDate(begindate.getDate());
                        fg.controls.enddate.setValue(enddate, {emitEvent: false, onlySelf: true});
                    }
                }));
                this.form.push(fg);
            });
            this.calculateSums();
            setTimeout(() => {
                (document.querySelector('#close-empl') as HTMLElement).focus();
            }, 400);
            this.mainPlanning = Utils.mainPlanning(this.planning);
            // Do not add default employees when planning is filled
            if (this.planning.user_planning?.length === 0) {
                this.filteredEmployees.filter(e => this.mainPlanning?.entity?.default_employees?.includes(e.id)).forEach(user => {
                    this.addEmployee(user.id);
                });
            }
        }));
    }

    selectAddress(control: FormGroup<ControlsOf<FormGroupUserPlanning>>) {
        this.dialog.open(AddressDialogComponent, {
            width: '700px',
            height: '530px',
            maxHeight: '600px',
            data: {
                control,
                user: this.employees.find(e => e.id === control.value.user_id),
                planning: this.planning
            }
        });
    }

    filterEmployees() {
        const currentEmployees = this.form.value.map(e => e.user_id);
        this.filteredEmployees = this.employees.filter(e => currentEmployees.indexOf(e.id) === -1);
    }

    calculateSums() {
        this.filterEmployees();
        this.sums = [];
        this.form.value.forEach(employee => {
            const sum = this.sums.find(p => p.function == employee.function);
            if (sum) {
                sum.amount++;
            } else {
                this.sums.push({
                    function: employee.function,
                    amount: 1
                });
            }
        });
    }

    addEmployee(userId: number) {
        const fg = new FormGroup<ControlsOf<FormGroupUserPlanning>>({
            user_id: new FormControl(userId),
            planning_id: new FormControl(this.planning.id),
            begindate: new FormControl(Utils.mainPlanning(this.planning).begindate, Validators.required),
            enddate: new FormControl(Utils.mainPlanning(this.planning).enddate, Validators.required),
            conflictText: new FormControl(),
            comment: new FormControl(),
            function: new FormControl(this.employees.find(e => e.id === userId)?.function),
            employee: new FormControl(this.employees.find(e => e.id === userId)?.name),
            updated_at: new FormControl(),
            origin: new FormControl(this.locationService.formatAddress(this.employees.find(u => u.id === userId) as any)),
            destination: new FormControl(this.locationService.formatAddress(this.employees.find(u => u.id === userId) as any)),
            work_begin: new FormControl(this.planning.location),
            work_end: new FormControl(this.planning.location)
        }, [], employeeAvailableValidator(this.userPlanningService));
        this.fcEmployee.reset();
        this.form.push(fg);
        this.calculateSums();
    }

    delete(formgroup: FormGroup<ControlsOf<FormGroupUserPlanning>>) {
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u ${formgroup.value.employee} wilt verwijderen van de planning?`, 'Ja').then(() => {
            this.form.controls.splice(this.form.controls.indexOf(formgroup), 1);
            this.calculateSums();
        }, () => {

        });
    }

    save() {
        this.saving = true;
        Utils.triggerValidationP(this.form).then(() => {
            this.userPlanningService.saveUserPlanning(this.planning.id, this.form.value as any as UserPlanning[]).subscribe(() => {
                this.saving = false;
                this.dialogRef.close();
            }, () => {
                this.saving = false;
            });
        }, () => {
            this.saving = false;
        });
    }

    close() {
        if (this.form.dirty) {
            this.confirmDialog.confirm('Niet opgeslagen wijzigingen',
                `Er zijn niet opgeslagen wijzigingen, wilt u deze alsnog opslaan?`, 'Opslaan', 'Niet opslaan').then(() => {
                this.save();
            }, () => {
                this.dialogRef.close();
            });
        } else {
            this.dialogRef.close();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}

export interface FormGroupUserPlanning extends Omit<UserPlanning,
    'begindate' | 'enddate' | 'begintime' | 'endtime' | 'updated_at'> {

    updated_at: FormControl<Date>;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    function: string;
    employee: string;
    conflictText: string;
}
