import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Toolbox} from '../../classes/toolbox';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {CodaltComponent} from '../../codalt.component';
import {ToolboxService} from '../../services/toolbox.service';
import {ViewPdfDialogComponent} from '../../view-pdf-dialog/view-pdf-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ToolboxRead} from '../../classes/toolbox-read';
import {ValidatorFn, Validators} from '@angular/forms';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-toolbox-edit',
    templateUrl: './toolbox-edit.component.html',
    styleUrls: ['./toolbox-edit.component.scss']
})
export class ToolboxEditComponent extends CodaltComponent implements OnInit {

    @Output() back = new EventEmitter<void>();
    @Input() toolbox: Toolbox;
    @Input() toolboxes: Toolbox[];

    minDate: Date;
    maxDate: Date;
    form: FormGroup<ControlsOf<FormGroupToolbox>>;
    saving = false;

    constructor(private toolboxService: ToolboxService,
                public dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.form = new FormGroup<ControlsOf<FormGroupToolbox>>({
            id: new FormControl(this.toolbox?.id),
            begindate: new FormControl(this.toolbox?.begindate, [Validators.required, this.validateDate()]),
            enddate: new FormControl(this.toolbox?.enddate, [Validators.required, this.validateDate()]),
            view_before: new FormControl(this.toolbox?.view_before, Validators.required),
            pdf: new FormControl(this.toolbox?.pdf, Validators.required),
            updated_at: new FormControl(this.toolbox?.updated_at)
        });
        if (!this.form.value.begindate) {
            const begindate = new Date(this.toolboxes.sort((a, b) => Utils.getTimeOrNull(b.enddate) - Utils.getTimeOrNull(a.enddate))[0].enddate);
            begindate.setDate(begindate.getDate() + 1);
            this.form.controls.begindate.setValue(begindate);
        }
    }

    save() {
        Utils.triggerValidationP(this.form).then(() => {
            const tb = new Toolbox();
            Object.assign(tb, this.form.value);
            tb.enddate = new Date(tb.enddate);
            Utils.setTime(tb.enddate, 23, 59, 59);
            this.toolboxService.saveToolbox(tb).subscribe(toolbox => {
                this.back.emit();
            });
        }, () => {

        });
    }

    showToolbox() {
        this.toolbox.read = new ToolboxRead();
        this.dialog.open(ViewPdfDialogComponent, {
            panelClass: 'toolbox-pdf-dialog',
            width: '99vw',
            maxWidth: '1300px',
            height: '99vh',
            minHeight: '500px',
            maxHeight: '99vh',
            disableClose: true,
            data: this.toolbox
        });
    }

    validateDate(): ValidatorFn {
        return (input: FormControl<Date>) => {
            const toolboxes = this.toolboxes.filter(t => t.id !== this.toolbox.id);
            let res = false;
            toolboxes.forEach(tb => {
                if (Utils.getTimeOrNull(input.value) >= Utils.getTimeOrNull(tb.begindate) && Utils.getTimeOrNull(input.value) <= Utils.getTimeOrNull(tb.enddate)) {
                    res = true;
                }
            });

            return res ? {dateNotAvailable: res} : null;
        };
    }

}

export interface FormGroupToolbox extends Omit<Toolbox, 'begindate' | 'enddate' | 'view_before' | 'updated_at'> {
    updated_at: FormControl<Date>;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    view_before: FormControl<Date>;
}
