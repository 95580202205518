<div class="d-flex align-items-center pb-3" mat-dialog-title>
    <div class="spacer title">Toolbox</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="dialog-content" *ngIf="!toolboxEdit">
    <p>
        Er kan maximaal 1 toolbox tegelijk getoond worden
    </p>
    <table class="table table-hover table-last-col table-add-row">
        <thead>
        <tr>
            <th>
                Begin
            </th>
            <th>
                Eind
            </th>
            <th>
                Gelezen
            </th>
            <th>

            </th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="edit(toolbox)" *ngFor="let toolbox of toolboxes" class="curpoint">
            <td>
                {{toolbox.begindate | date : 'EEEE d MMMM yyyy'}}
            </td>
            <td>
                {{toolbox.enddate | date : 'EEEE d MMMM yyyy'}}
            </td>
            <td>
                {{toolbox.read_count}}
            </td>
            <td>
                <div class="d-flex">
                    <button class="mr-2" (click)="readBy($event, toolbox)" color="primary" mat-icon-button>
                        <i class="fa-solid fa-glasses"></i>
                    </button>
                    <button (click)="delete($event, toolbox)" color="warn" mat-icon-button>
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                <button (click)="edit()" class="w-100" color="primary" mat-button>
                    Een toolbox toevoegen <i class="fas fa-plus"></i>
                </button>
            </td>
            <td></td>
        </tr>
        </tbody>
    </table>
</div>

<div class="dialog-content" *ngIf="toolboxEdit">
    <app-toolbox-edit (back)="back()"
                      [toolbox]="toolboxEdit"
                      [toolboxes]="toolboxes">
    </app-toolbox-edit>
</div>
