import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';

@Pipe({
    name: 'excludedKey'
})
export class ExcludedKeyPipe implements PipeTransform {

    transform(user_id: any, date: Date): string {
        return `${user_id}-${formatDate(date, 'yyyy-MM-dd', 'nl')}`;
    }

}
