import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {Entity} from '../../classes/entity.class';
import {EntitiesService} from '../../services/entities/entities.service';
import {debounceTime} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {RealisationService} from '../../services/realisation.service';
import {ProjectService} from '../../services/project.service';
import {Utils} from '../../utils.class';
import {EntityId, EntityTypeCode} from '../../services/entities/entity-type.class';
import {LocalStorage} from '../../storage.class';

@Component({
    selector: 'app-add-realisation-dialog',
    templateUrl: './add-realisation-dialog.component.html',
    styleUrls: ['./add-realisation-dialog.component.scss']
})
export class AddRealisationDialogComponent extends CodaltComponent implements OnInit {

    RealisationHourtype = RealisationHourtype;
    EntityTypeCode = EntityTypeCode;

    projects: {
        projectId: string,
        location: string,
        planningId?: number;
    }[] = [];

    form: FormGroup<ControlsOf<newRealisation>> = new FormGroup<ControlsOf<newRealisation>>({
        entityId: new FormControl(),
        userId: new FormControl(),
        projectId: new FormControl(),
        planningId: new FormControl(),
        type: new FormControl(),
        time: new FormControl(),
        hourtype: new FormControl(),
        hiring_name: new FormControl()
    });

    employees: User[];
    entities: Entity[];

    currentPause: number;
    currentMinutes: number;

    hirings = [];

    fcProjectSearch = new FormControl<string>();
    fcEntities = new FormControl<number[]>();
    itemSearch?: string;
    fcItemSearch = new FormControl<string>();

    previousRealisation: Realisation;
    nextRealisation: Realisation;
    timeEnd: string;

    timeOptions: { begin: Date; end: Date; }[] = [];

    creating = false;

    searching = false;
    selectedIndex = 0;

    activeStep: Step = Step.Type;
    Step = Step;
    night = false;

    otherRealisationsThisDay: {
        realisations: Realisation[],
        team: Entity,
        showAsphaltTeamPerformer?: boolean,
        showProjectTeamPerformer?: boolean
    };

    optionalProject = false;

    constructor(public dialogRef: MatDialogRef<AddRealisationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    realisations: Realisation[],
                    bookdate: Date,
                    userId?: number
                },
                private realisationService: RealisationService,
                private entityService: EntitiesService,
                private userService: UserService,
                private afprojectServicesService: ProjectService) {
        super();
        this.setCurrentProjects();
    }


    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        let list = [];
        switch (this.activeStep) {
            case Step.Employee:
                list = this.employees?.filter(e => !this.fcItemSearch.value || (e.name.toLowerCase().indexOf(this.fcItemSearch.value?.toLowerCase()) !== -1));
                break;
            case Step.Project:
                list = this.projects;
                break;
        }

        if (event.key === 'Enter') {
            switch (this.activeStep) {
                case Step.Employee:
                    this.setUser(list[this.selectedIndex].id);
                    break;
                case Step.Project:
                    this.selectProject(list[this.selectedIndex].projectId, this.projects[this.selectedIndex].planningId);
                    break;
            }
        }

        if (event.key === 'ArrowUp') {
            this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }

        if (event.key === 'ArrowDown') {
            this.selectedIndex++;
            if (this.selectedIndex > (list?.length - 1)) {
                this.selectedIndex = 0;
            }
        }
    }

    private setCurrentProjects() {
        if (this.data.userId) {
            this.subscriptions.add(this.afprojectServicesService.getRecentProject(+this.data.userId).subscribe((projects) => {
                this.projects = projects.data.map(p => {
                    return {projectId: p.afas_project_id, location: p.location ?? p.name};
                });
            }));
            const entities$ = this.entityService.getList();
            const dayEntityIds$ = this.realisationService.getDayEntities(this.data.bookdate, +this.data.userId);

            this.subscriptions.add(combineLatest(entities$, dayEntityIds$).subscribe(([entities, dayEntityIds]) => {
                this.entities = entities.filter(e => !!e.afas_employee_id);
                let defaultEntityIds = dayEntityIds.data;
                defaultEntityIds = this.entities.filter(e => defaultEntityIds.indexOf(e.id) !== -1).map(e => e.id);
                if (defaultEntityIds.length === 0 && !!this.data.userId) {
                    defaultEntityIds = this.entities.filter(e => e.driver_user_id === this.data.userId).map(e => e.id);
                }
                this.fcEntities.setValue(defaultEntityIds);
            }));
        } else {
            this.projects = [];
            const projects = [...new Set(this.data.realisations?.map(r => Utils.realisationAfasProjectId(r)))].filter(p => !!p);
            projects.forEach(project => {
                const realisation = this.data.realisations
                    .filter(p => !p.planning?.is_transport)
                    .sort((a, b) => (a.planning_id ? 0 : 1) - b.planning_id ? 0 : 1)
                    .find(r => r.planning?.afas_project_id === project);
                const afasProject = this.data.realisations.find(r => r.project?.afas_project_id === project);
                this.projects.push({
                    projectId: project,
                    location: realisation?.planning?.location ?? afasProject?.project?.name,
                    planningId: realisation?.planning?.id ?? null
                });
            });
        }
    }

    setActiveStep(step: Step) {
        if (step === Step.Hourtype && (!this.form.value.userId)) {
            return;
        }
        if (step === Step.Project &&
            (((!this.form.value.userId && !this.form.value.entityId)
                    || !this.form.value.type || !this.form.value.hourtype || NON_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1)
                || (this.form.value.type?.substr(0, 6) === 'hiring' && !this.form.value.hiring_name))) {
            return;
        }
        if (step === Step.Employee && !this.form.value.type) {
            return;
        }
        if (step === Step.Vehicle && (!this.form.value.type || !this.form.value.projectId || !this.form.value.hourtype || !this.data.userId)) {
            return;
        }

        this.activeStep = step;
    }

    setType(type: 'entity' | 'user' | 'hiring_material' | 'hiring_user') {
        this.form.controls.type.setValue(type);
        this.form.controls.userId.setValue(this.data.userId);
        this.form.controls.entityId.setValue(null);
        this.form.controls.hiring_name.setValue(null);
        if (type === 'hiring_user') {
            this.form.controls.entityId.setValue(EntityId.HiringEmployee);
        }
        if (type === 'hiring_material') {
            this.form.controls.entityId.setValue(EntityId.HiringMaterials);
        }

        if (type === 'user') {
            if (this.data.userId) {
                this.activeStep = Step.Hourtype;
            } else {
                this.form.controls.hourtype.setValue(null);
                this.activeStep = Step.Employee;
            }
        } else {
            this.setHourtype(RealisationHourtype.worktime);
        }
    }

    setTime(time: number) {
        this.form.controls.time.setValue(time);
        if ([...OPTIONAL_PROJECT_HOURTYPES, ...NON_PROJECT_HOURTYPES].indexOf(this.form.value.hourtype) !== -1) {
            this.createRealisation();
        } else if (this.form.value.userId) {
            this.activeStep = Step.Vehicle;
        } else if ((!this.form.value.hiring_name && [EntityId.HiringMaterials, EntityId.HiringEmployee].indexOf(this.form.value.entityId) !== -1) || (!this.form.value.entityId && !this.form.value.userId)) {
            this.activeStep = Step.Employee;
        } else {
            this.createRealisation();
        }
    }

    setHourtype(hourtype: RealisationHourtype) {
        this.form.controls.hourtype.setValue(hourtype);
        this.optionalProject = OPTIONAL_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1;
        if (NON_PROJECT_HOURTYPES.indexOf(this.form.value.hourtype) !== -1) {
            this.form.controls.projectId.setValue(null);
            this.form.controls.planningId.setValue(null);
            if (this.data.userId) {
                this.form.controls.userId.setValue(this.data.userId);
                this.form.controls.entityId.setValue(null);
            }
            this.setActiveStep(Step.Time);
        } else {
            if (this.data.userId) {
                this.activeStep = Step.Project;
            } else if ((!this.form.value.hiring_name && [EntityId.HiringMaterials, EntityId.HiringEmployee].indexOf(this.form.value.entityId) !== -1) || (!this.form.value.entityId && !this.form.value.userId)) {
                this.activeStep = Step.Employee;
            } else {
                this.activeStep = Step.Project;
            }
        }
        this.createTimeOptions();
    }

    createTimeOptions() {
        this.timeOptions = [];
        this.currentPause = 0;
        this.currentMinutes = 0;
        const addDayNightOptions = (workMinutesToCreate?: number) => {
            const dayBegin = Utils.setTimeNewDate(this.data.bookdate, 7, 0);
            this.timeOptions.push({
                begin: dayBegin,
                end: Utils.addMinutes(dayBegin, workMinutesToCreate ?? (60 * 8))
            });

            const nightBegin = Utils.setTimeNewDate(this.data.bookdate, 20, 30);
            this.timeOptions.push({
                begin: nightBegin,
                end: Utils.addMinutes(nightBegin, workMinutesToCreate ?? (60 * 8))
            });
        };

        if (this.form.value.userId) {
            this.subscriptions.add(this.realisationService.getUserDaySchedule(this.data.bookdate, this.form.value.userId).subscribe(userDaySchedule => {
                this.currentPause = +(userDaySchedule.data.dayTotal?.pause ?? 0);
                this.currentMinutes = +(userDaySchedule.data.dayTotal?.minutes ?? 0);
                const remaining = 480 - (this.currentMinutes - this.currentPause);
                const realisations = userDaySchedule.data.realisations?.filter(r => {
                    return !r.removed
                        && (!NON_CONFLICTING_HOURTYPES.includes(r.hourtype) || Utils.minuteDuration(r.enddate, r.begindate) < (6 * 60))
                        && r.hiring_name === this.form.value.hiring_name;
                });
                const pauseAdjusted = !!userDaySchedule.data.realisations.find(r => !!r.pause_adjusted);

                if (this.form.value.planningId) {
                    const planning = this.data.realisations.find(r => r.planning_id === this.form.value.planningId).planning;
                    const mainPlanning = this.data.realisations.find(r => r.planning?.id === planning.id && r.entity_id === planning.entity_id);
                    if (mainPlanning) {
                        const mainPlanningTimeOption = {
                            begin: new Date(mainPlanning.begindate),
                            end: new Date(mainPlanning.enddate)
                        };

                        // Only keep planning times when > 2 hours
                        if (Utils.minuteDuration(mainPlanningTimeOption.end, mainPlanningTimeOption.begin) > (60 * 2)) {
                            this.timeOptions.push(mainPlanningTimeOption);
                        }
                    }
                }

                let workMinutesToCreate = remaining;
                if (!pauseAdjusted && this.currentPause < 30) {
                    const add = 30 - this.currentPause;
                    workMinutesToCreate += add;
                }

                if (realisations.length > 0) {
                    workMinutesToCreate = Math.max(60, workMinutesToCreate);

                    this.nextRealisation = realisations[0];
                    const beginTimeStart = Utils.addMinutes(this.nextRealisation.begindate, workMinutesToCreate, true);
                    if (beginTimeStart.getDate() === new Date(this.data.bookdate).getDate()) {
                        this.timeOptions.push({
                            begin: beginTimeStart,
                            end: this.nextRealisation.begindate
                        });
                    }

                    this.previousRealisation = realisations[realisations.length - 1];
                    this.timeOptions.push({
                        begin: Utils.newDate(this.previousRealisation.enddate),
                        end: Utils.addMinutes(this.previousRealisation.enddate, workMinutesToCreate)
                    });

                    if (workMinutesToCreate > 90) {
                        this.timeOptions.push({
                            begin: Utils.newDate(this.previousRealisation.enddate),
                            end: Utils.addMinutes(this.previousRealisation.enddate, 60)
                        });
                    }
                }

                addDayNightOptions(workMinutesToCreate);
            }));
        } else {
            addDayNightOptions();
        }
    }

    ngOnInit(): void {
        if (this.data.userId) {
            this.form.controls.userId.setValue(this.data.userId);
            this.setType('user');
            this.definePrevNextRealisation();
            this.createTimeOptions();
        }

        const loadHiringNames = (type: 'entity' | 'user' | 'hiring_material' | 'hiring_user', search: string) => {
            if (type?.substr(0, 6) === 'hiring') {
                this.realisationService.hiringnames(type, search).subscribe(result => {
                    this.hirings = result.data;
                    this.selectedIndex = 0;
                });
            }
        };
        this.subscriptions.add(this.form.controls.type.valueChanges.subscribe(type => {

            this.subscriptions.add(this.entityService.getList().subscribe(entities => {
                const belongsToEntityType = entities.filter(e => {
                    return e.default_employees?.includes(LocalStorage.user.id);
                });
                const entityTypeIds = belongsToEntityType.map(e => e.entitytypes.map(ee => ee.id)).flat();
                this.entities = entities.filter(e => {
                    return !!e.afas_employee_id && (entityTypeIds.length === 0 || e.entitytypes.filter(et => !et.belongs_to || entityTypeIds.includes(et.belongs_to)).length > 0);
                });
            }));
            if (type === 'user') {
                this.subscriptions.add(this.userService.getList(false, false).subscribe(users => {
                    this.employees = users;
                    this.selectedIndex = 0;
                }));
            }
            loadHiringNames(type, this.fcItemSearch.value);
        }));
        this.subscriptions.add(this.fcItemSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            loadHiringNames(this.form.value.type, search);
        }));

        let afasSubs = new Subscription();
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            afasSubs?.unsubscribe();
            if (search) {
                this.searching = true;
                afasSubs = this.afprojectServicesService.searchProjects(search).subscribe((projectResponse) => {
                    this.projects = projectResponse.data?.filter(p => !!p.parent_id).map(p => {
                        return {projectId: p.afas_project_id, location: p.name};
                    });
                    this.selectedIndex = 0;
                    this.searching = false;
                }, () => {
                    this.searching = false;
                });
            } else {
                this.setCurrentProjects();
            }
        }));

        this.subscriptions.add(this.fcEntities.valueChanges.subscribe(() => {
            const getSorter = (entity: Entity) => (this.fcEntities.value.indexOf(entity.id) !== -1 ? 100000 : 0) + entity.id;

            this.entities = this.entities.sort((a, b) => getSorter(b) - getSorter(a));
        }));

        this.subscriptions.add(this.form.controls.userId.valueChanges.subscribe(() => {
            this.definePrevNextRealisation();
        }));
    }

    private definePrevNextRealisation() {
        // Find the previous realisation for the current user on the current bookdate excluding removed realisations
        const matchingRealisations = this.data.realisations?.filter(r => {
            return r.user_id === (this.form.controls.userId.value ?? this.data.userId)
                && Utils.matchesDate(r.bookdate, this.data.bookdate)
                && !r.removed
                && !NON_CONFLICTING_HOURTYPES.includes(r.hourtype)
                && r.hiring_name === this.form.value.hiring_name;
        }).sort((a, b) => Utils.realisationSort(a) - Utils.realisationSort(b));
        if (matchingRealisations?.length > 0) {
            this.nextRealisation = matchingRealisations[0];

            this.previousRealisation = matchingRealisations[matchingRealisations.length - 1];
        } else {
            this.nextRealisation = null;
            this.previousRealisation = null;
        }
    }

    selectProject(projectId, planningId?: number) {
        this.form.controls.projectId.setValue(projectId);
        this.form.controls.planningId.setValue(planningId);
        this.activeStep = Step.Time;
    }

    setEntity(entity: Entity) {
        this.form.controls.entityId.setValue(entity.id);
        this.form.controls.userId.setValue(null);
        this.activeStep = Step.Project;
    }

    setUser(userId: number) {
        this.subscriptions.add(this.realisationService.getUserDay(userId, this.data.bookdate).subscribe(userDay => {
            this.otherRealisationsThisDay = userDay.data;
            this.otherRealisationsThisDay.showAsphaltTeamPerformer = !this.otherRealisationsThisDay.team || !!this.otherRealisationsThisDay?.team?.entitytypes.find(et => et.id === EntityTypeCode.AsfaltTeam);
            this.otherRealisationsThisDay.showProjectTeamPerformer = !this.otherRealisationsThisDay.team || !!this.otherRealisationsThisDay?.team?.entitytypes.find(et => et.id === EntityTypeCode.ProjectTeam);
        }));
        this.form.controls.userId.setValue(userId);
        this.form.controls.entityId.setValue(null);
        if (this.data.userId) {
            this.activeStep = Step.Project;
        } else {
            this.activeStep = Step.Hourtype;
        }
    }

    createRealisation() {
        if (this.creating) {
            return;
        }
        this.creating = true;
        const realisation = new Realisation();
        realisation.user_id = this.form.value.userId;
        realisation.entity_id = this.form.value.entityId;
        realisation.afas_project_id = this.form.value.projectId;
        realisation.hourtype = this.form.value.hourtype ?? RealisationHourtype.worktime;
        realisation.bookdate = this.data.bookdate;
        realisation.hiring_name = this.form.value.hiring_name;

        if (this.form.value.planningId) {
            realisation.planning_id = this.form.value.planningId;
        }
        realisation.begindate = this.timeOptions[this.form.value.time]?.begin;
        realisation.enddate = this.timeOptions[this.form.value.time]?.end;

        this.realisationService.saveRealisation(realisation).subscribe(result => {
            if (realisation.hourtype === RealisationHourtype.worktime && this.fcEntities.value?.length > 0) {
                const realisations = [];
                this.fcEntities.value.forEach(entityId => {
                    const entityRealisation = JSON.parse(JSON.stringify(realisation)) as Realisation;
                    entityRealisation.entity_id = entityId;
                    entityRealisation.user_id = null;
                    entityRealisation.parent_realisation_id = result.data.realisation.id;
                    entityRealisation.hourtype = RealisationHourtype.worktime;
                    realisations.push(entityRealisation);
                });

                const realisations$ = realisations.map(r => this.realisationService.saveRealisation(r));
                combineLatest(realisations$).subscribe(() => {
                    this.dialogRef.close(result.data.realisation);
                });
            } else {
                this.dialogRef.close(result.data.realisation);
            }
        }, () => {
            this.creating = false;
        });
    }

    setHiring(name: string) {
        this.form.controls.hiring_name.setValue(name);
        this.form.controls.userId.setValue(null);
        this.activeStep = Step.Project;
    }
}

interface newRealisation {
    projectId: string;
    userId: number;
    entityId: number;
    planningId: number;
    type: 'entity' | 'user' | 'hiring_material' | 'hiring_user';
    time: number;
    hourtype: RealisationHourtype;
    hiring_name: string;
}

export const NON_PROJECT_HOURTYPES = [
    RealisationHourtype.day_off,
    RealisationHourtype.frost,
    RealisationHourtype.illness,
    RealisationHourtype.short_absence,
    RealisationHourtype.paid_leave,
    RealisationHourtype.special_leave
];
export const OPTIONAL_PROJECT_HOURTYPES = [
    RealisationHourtype.education,
    RealisationHourtype.sleep
];

export const NON_CONFLICTING_HOURTYPES = [
    RealisationHourtype.paid_leave,
    RealisationHourtype.sleep,
    RealisationHourtype.bank_holiday
];

export enum Step {
    Project, Hourtype, Employee, Type, Vehicle, Time
}
