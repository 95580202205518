import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private apiService: ApiService) {
    }

    upload(file: File, storage = FileStorage.TinyImages) {
        return this.apiService.makeFileRequest$<{
            file: string,
            kind: string
        }>('file/upload', file, {
            storage
        });
    }
}

export enum FileStorage {
    TinyImages = 'tinyimages',
    ToolBox = 'toolbox'
}
