import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sum',
    pure: false
})
export class SumPipe implements PipeTransform {

    transform<T>(value: T[], property: string | string[]): number {
        return value.reduce((a, b) => {
            return a + (Array.isArray(property) ? property.map(prop => +(b[prop] ?? 0)).reduce((c, d) => c + d) : +b[property]);
        }, 0);
    }

}
