import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {NgSelectConfig} from '@ng-select/ng-select';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UserService, UserType} from './services/user/user.service';
import {LocalStorage} from './storage.class';
import {AuthService} from './services/auth/auth.service';
import {WebsocketService} from './services/websocket/websocket.service';
import {CodaltComponent} from './codalt.component';
import {ToolboxService} from './services/toolbox.service';
import {Toolbox} from './classes/toolbox';
import {Utils} from './utils.class';
import {MatDialog} from '@angular/material/dialog';
import {ViewPdfDialogComponent} from './view-pdf-dialog/view-pdf-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends CodaltComponent {

    environment = environment;
    tokenExpireTimeout = null;

    toolboxWarn = false;
    showToolbox = false;
    toolbox: Toolbox;
    print = false;

    public constructor(private title: Title,
                       private router: Router,
                       private dialog: MatDialog,
                       private authService: AuthService,
                       private toolboxService: ToolboxService,
                       private breakpointObserver: BreakpointObserver,
                       private websocketService: WebsocketService,
                       private ngSelectConfig: NgSelectConfig) {
        super();
        this.ngSelectConfig.notFoundText = 'Geen items gevonden';
        this.ngSelectConfig.loadingText = 'Aan het laden...';
        this.ngSelectConfig.clearAllText = 'Alles wissen';
        this.ngSelectConfig.typeToSearchText = 'Typ om te zoeken';
    }

    ngOnInit() {
        this.print = location.href.indexOf('print') !== -1;
        this.title.setTitle('Planning ' + environment.titleAppend);
        if (location.pathname === '/' && LocalStorage.getUserToken() && !UserService.userHasRights(UserType.USER)) {
            this.router.navigate(['/weekschedule']);
        }
        if (location.pathname === '/' && LocalStorage.getUserToken() && this.breakpointObserver.isMatched('(max-width: 991px)')) {
            this.router.navigate(['/weekschedule']);
        }

        this.checkAuth();
        if (typeof window !== 'undefined' && window.addEventListener) {

            window.addEventListener('storage', () => {
                this.checkAuth();
            }, false);
        }

        if (!!LocalStorage.getUserToken()) {
            const currentRoles = JSON.stringify(LocalStorage.getUser()?.groups);
            this.authService.getUserDetails().subscribe(user => {
                const newRoles = JSON.stringify(user.data.groups);
                if (currentRoles !== newRoles) {
                    console.log('Roles', currentRoles, newRoles);
                    window.location.reload();
                }
            });
        }
        this.subscriptions.add(this.authService.loggedIn.subscribe(loggedIn => {
            if (loggedIn) {
                this.getToolbox();
            }
        }));
    }

    getToolbox() {
        if (!this.toolbox) {
            this.subscriptions.add(this.toolboxService.getCurrentToolbox().subscribe(toolbox => {
                this.toolbox = toolbox.data;
                if (this.toolbox) {
                    this.toolboxWarn = Utils.getTimeOrNull(this.toolbox.view_before) < Utils.getTimeOrNull(new Date());
                    this.showToolbox = !this.toolbox.read;
                }
            }));
        }
    }

    viewToolbox() {
        this.dialog.open(ViewPdfDialogComponent, {
            panelClass: 'toolbox-pdf-dialog',
            width: '99vw',
            maxWidth: '1300px',
            height: '99vh',
            minHeight: '500px',
            maxHeight: '99vh',
            disableClose: true,
            data: this.toolbox
        }).afterClosed().subscribe(read => {
            this.toolbox.read = read;
            this.showToolbox = !this.toolbox.read;
        });
    }

    close() {
        this.showToolbox = false;
    }

    private checkAuth() {
        if (this.tokenExpireTimeout) {
            clearTimeout(this.tokenExpireTimeout);
        }
        if (LocalStorage.getTokenDate()) {
            this.getToolbox();
            const tokenValidUntil = new Date(LocalStorage.getTokenDate());
            tokenValidUntil.setSeconds(tokenValidUntil.getSeconds() + (+LocalStorage.getExpireTimeToken()));
            // A timeout higher than 24 days is not allowed, limit it to 10 days
            const tenDays = 60 * 60 * 24 * 10;
            const timeToInvalid = Math.min(tenDays, Math.round((tokenValidUntil.getTime() - new Date().getTime()) / 1000));
            const timeBeforeRefresh = 60 * 60 * 24; // one day
            const timeout = Math.max((3 + (Math.random() * 30)), (timeToInvalid - timeBeforeRefresh - Math.round(Math.random() * 30))) * 1000;

            this.tokenExpireTimeout = setTimeout(() => {
                console.log('Refreshing token because it times out', tokenValidUntil);
                this.authService.refresh().subscribe(() => {
                    this.websocketService.disconnect();
                }, () => {
                    this.authService.logout();
                });
            }, timeout);
        }
    }

}
