import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from './storage.class';
import {AuthService} from './services/auth/auth.service';

@Injectable()
export class IsAuthenticated implements CanActivate {
    constructor(private router: Router,
                private authService: AuthService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve, reject) => {
            const params = route.queryParams as { token: string };
            if (params.token) {
                LocalStorage.setUserToken(params.token);
                // Token can be set from params using the is-authenticated CanActivate class, but user details won't be loaded
                if (LocalStorage.getUserToken() && !LocalStorage.getUser()) {
                    this.authService.getUserDetails().subscribe(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            } else if (!LocalStorage.getUserToken()) {
                this.router.navigate(['login']);
                reject();
            } else {
                resolve(true);
            }
        });
    }
}
