<div class="test" *ngIf="environment.test">
    Testomgeving
</div>
<div class="d-flex flex-column" [class.vh-100]="!print">
    <div class="toolbox" [class.warn]="toolboxWarn" *ngIf="showToolbox && toolbox" (click)="viewToolbox()">
        <div *ngIf="!toolboxWarn">
            Bekijk de toolbox van {{toolbox.begindate | date: 'EEE dd MMMM'}} voor {{toolbox.view_before | date: 'EEE dd MMMM'}}.
        </div>
        <div *ngIf="toolboxWarn">
            Bekijk nu de toolbox van {{toolbox.begindate | date: 'EEE dd MMMM'}}.
        </div>
        <button mat-icon-button class="hide" (click)="close()" *ngIf="!toolboxWarn">
            <mat-icon class="fas fa-times"></mat-icon>
        </button>
    </div>
    <div class="flex-fill overflow-auto position-relative" [class.overflow-auto]="!print">
        <router-outlet></router-outlet>
    </div>
</div>
