import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {LeaveService} from '../services/leave.service';
import {Router} from '@angular/router';
import {Leave} from '../classes/leave';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-leave',
    templateUrl: './leave.component.html',
    styleUrls: ['./leave.component.scss']
})
export class LeaveComponent extends CodaltComponent implements OnInit {

    leaves: Leave[];
    now = new Date();
    fcShowHistoric = new FormControl(false);

    constructor(private leaveService: LeaveService,
                private confirmDialog: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.getLeaves();

        this.subscriptions.add(this.fcShowHistoric.valueChanges.subscribe(() => {
            this.getLeaves();
        }));
    }

    private getLeaves() {
        this.subscriptions.add(this.leaveService.getLeaves(this.fcShowHistoric.value).subscribe(leaves => {
            this.leaves = leaves.data;
        }));
    }

    edit(leave?: Leave) {
        this.router.navigateByUrl(`/leave/${leave?.id ?? 'new'}`)
    }

    delete(event, leave?: Leave) {
        event.stopPropagation();


        this.confirmDialog.confirm(
            'Verlof verwijderen',
            `Weet u zeker dat u verlof ${leave.comment} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(confirm => {
            this.leaveService.deleteLeave(leave.id).subscribe(() => {
                this.getLeaves();
            });
        }, () => {

        });
    }
}
