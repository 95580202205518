import {User} from './user.class';
import {LeaveExcluded} from './leave-excluded';
import {RealisationHourtype} from './realisation';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class Leave implements RealtimeInterface {
    id: number;
    hourtype: RealisationHourtype;
    comment: string;
    user_id: number;
    users: User[] = [];
    excluded: LeaveExcluded[] = [];

    common: boolean;
    begindate: Date;
    enddate: Date;
    begintime: string;
    endtime: string;
    interval_week: number;
    repeat_monday: boolean = false;
    repeat_tuesday: boolean = false;
    repeat_wednesday: boolean = false;
    repeat_thursday: boolean = false;
    repeat_friday: boolean = false;
    repeat_saturday: boolean = false;
    repeat_sunday: boolean = false;

    afas_project_id?: string;

    updated_at: Date;
    deleted_at?: Date;
}
