import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {FileService, FileStorage} from '../services/file.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {formatNumber} from '@angular/common';
import {FormControl} from '@ngneat/reactive-forms';

@Component({
    selector: 'app-codalt-upload-file',
    templateUrl: './codalt-upload-file.component.html',
    styleUrls: ['./codalt-upload-file.component.scss']
})
export class CodaltUploadFileComponent implements OnInit {

    @Input() fileStorage = FileStorage.ToolBox;
    @Input() fcFile: FormControl<string>;

    @Output() submitted = new EventEmitter<File>();

    loading = false;

    constructor(private confirmDialogService: ConfirmDialogService,
                private ngZone: NgZone,
                private fileService: FileService) {
    }

    ngOnInit(): void {
    }

    submit(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.srcElement.files[0];
        if (file) {
            this.submitted.emit(file);
            this.uploadImage(file);
        }
    }


    uploadImage(file: File) {
        this.loading = true;
        const maxSizeMB = 90;
        if (file.size > maxSizeMB * 1024 * 1024) {
            this.confirmDialogService.confirm(
                'Bestand te groot',
                `Dit bestand is groter dan ${formatNumber(maxSizeMB, 'nl')} MB`,
                'Oké',
                null
            ).then(() => {
                this.loading = false;
            });
        } else if (file.type === 'application/pdf') {
            this.fileService.upload(file, this.fileStorage).subscribe((fileResponse) => {
                this.ngZone.run(() => {
                    this.fcFile.setValue(fileResponse.data.file);
                    this.loading = false;
                });
            });
        } else {
            this.confirmDialogService.confirm(
                'Verkeerd bestand',
                'Dit bestandsformaat wordt niet ondersteund',
                'Oké',
                null
            ).then(() => {
                this.loading = false;
            });
        }
    }
}
