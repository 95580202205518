import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Toolbox} from '../classes/toolbox';
import {ToolboxService} from '../services/toolbox.service';
import {CodaltComponent} from '../codalt.component';
import {ToolboxReadDialogComponent} from '../toolbox-read-dialog/toolbox-read-dialog.component';
import {DialogService} from '../services/dialog/dialog.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-toolbox',
    templateUrl: './toolbox.component.html',
    styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent extends CodaltComponent implements OnInit {

    toolboxes: Toolbox[];
    toolboxEdit: Toolbox;

    constructor(private confirmDialog: ConfirmDialogService,
                public dialogRef: MatDialogRef<ToolboxComponent>,
                public dialog: MatDialog,
                public dialogService: DialogService,
                public toolboxService: ToolboxService) {
        super();
    }

    ngOnInit(): void {
        this.loadData();
    }

    readBy(event, toolbox: Toolbox) {
        event.stopPropagation();
        this.dialogService.open(ToolboxReadDialogComponent, toolbox);
    }

    edit(toolbox?: Toolbox) {
        if (!toolbox) {
            toolbox = new Toolbox();
        }
        this.toolboxEdit = toolbox;
    }

    delete(event, toolbox: Toolbox) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Toolbox verwijderen',
            'Weet je zeker dat je deze toolbox wilt verwijderen?',
            'Verwijderen', 'Behouden').then(() => {
            this.toolboxService.deleteToolbox(toolbox.id).subscribe(() => {
                this.loadData();
            });
        }, () => {
        });
    }

    close() {
        this.dialogRef.close();
    }

    back() {
        this.toolboxEdit = null;
        this.loadData();
    }

    private loadData() {
        this.subscriptions.add(this.toolboxService.getToolboxes().subscribe(toolboxes => {
            this.toolboxes = toolboxes.data;
        }));
    }
}
