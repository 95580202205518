import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {PlanningService, SizeForDate} from "../../../services/planning/planning.service";
import {Subscription} from "rxjs";
import {PlanningSize} from '../../../classes/planning.class';

@Component({
    selector: 'app-trucks',
    templateUrl: './trucks.component.html',
    styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit, OnDestroy {


    public sizes: SizeForDate;
    @Input() print: boolean;
    @Input() private fromDate: Date;
    @Input() private toDate: Date;
    @Input() private date: Date;
    private subscriptions = new Subscription();

    @HostBinding('class.first') firstDate = false;
    @HostBinding('class.last') lastDate = false;

    constructor(private planningService: PlanningService) {
    }

    ngOnInit() {
        this.firstDate = this.date.getDate() === this.fromDate.getDate();
        const endDateCheck = new Date(this.date);
        endDateCheck.setDate(endDateCheck.getDate() + 1);
        this.lastDate = endDateCheck.getDate() === this.toDate.getDate();
        this.subscriptions.add(this.planningService.getCountOfPlanningSizes(this.fromDate, this.toDate, this.date)
            .subscribe(trucks => {
                if (this.lastDate) {
                    trucks.perSize = trucks.perSize.splice(0, 1);
                }
                this.sizes = trucks;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    readonly PlanningSize = PlanningSize;
}
