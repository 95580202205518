import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'whereIn'
})
export class WhereInPipe implements PipeTransform {

    transform<T>(value: T[], id: number[], notIn = false): T[] {
        if (value && !notIn) {
            return value.filter(p => id.indexOf(p['id']) !== -1);
        }
        if (value && notIn) {
            return value.filter(p => id.indexOf(p['id']) === -1);
        }
        return null;
    }

}
