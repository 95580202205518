import {EventEmitter} from '@angular/core';
import {WebsocketService} from '../websocket/websocket.service';
import {Observable} from 'rxjs';
import {RealtimeType} from './realtime-type.enum';
import {Utils} from '../../utils.class';
import {LocalStorage} from '../../storage.class';
import {RealtimeInterface} from './realtime-interface';

export abstract class RealtimeService<T extends RealtimeInterface> {

    private items: T[] = [];
    private requestedDates: number[] = [];
    private requestedIds = new Map<(string | number), boolean>();
    private itemChanged = new EventEmitter<(number | string)[]>();
    private retryTimeoutPointer;
    private init = false;

    protected constructor(protected websocket: WebsocketService,
                          protected type: RealtimeType,
                          protected identifier = 'id') {
        if (!!LocalStorage.getUserToken()) {
            this.websocketInit();
        }
        this.websocket.websocketOpen.subscribe(isOpen => {
            if (isOpen) {
                this.websocket.doRequest('planning');
            } else {
                this.retryWebsocketInit();
            }
        });
    }

    private websocketInit() {
        this.init = true;
        this.websocket.getWebsocket<T>(this.type).subscribe((items: { data: T[], reinit: boolean }) => {
                if (items?.data && Array.isArray(items?.data)) {
                    items.data.forEach(item => {
                        const currentItem = this.items.find(p => p[this.identifier] === item[this.identifier]);
                        if (item['deleted']) {
                            if (currentItem) {
                                this.items.splice(this.items.indexOf(currentItem), 1);
                            }
                        } else if (currentItem) {
                            Object.assign(currentItem, item);
                        } else {
                            this.items.push(item);
                        }
                    });
                    this.itemChanged.emit(items.data.map(i => isNaN(+i[this.identifier]) ? i[this.identifier] : +i[this.identifier]));
                } else if (!!items?.reinit) {
                    this.retryWebsocketInit();
                }
            },
            () => this.retryWebsocketInit(),
            () => this.retryWebsocketInit()
        );
    }

    private retryWebsocketInit() {
        console.log('retry');
        this.init = false;
        if (this.retryTimeoutPointer) {
            clearTimeout(this.retryTimeoutPointer);
        }
        this.retryTimeoutPointer = setTimeout(() => {
            this.websocketInit();
        }, 1000);
    }

    public getFilteredList(fromDate: Date, toDate: Date): Observable<T[]> {

        let newFromDate = Utils.setTime(new Date(fromDate), 0, 0);
        let newToDate = Utils.setTime(new Date(toDate), 0, 0);
        if (toDate.getTime() > newToDate.getTime()) {
            newToDate.setDate(newToDate.getDate() + 1);
        }

        return new Observable<T[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                observer.next(this.items.filter(item => this.itemInDaterange(item, fromDate, toDate)));
            });

            let loopDate = new Date(newFromDate);
            let allRequested = true;
            while (loopDate.getTime() < newToDate.getTime()) {
                if (this.requestedDates.indexOf(loopDate.getTime()) === -1) {
                    allRequested = false;
                }
                loopDate.setDate(loopDate.getDate() + 1);
            }
            if (allRequested) {
                observer.next(this.items.filter(item => this.itemInDaterange(item, fromDate, toDate)));
            } else {
                let loopDate = new Date(newFromDate);
                let newStart = null;
                let newEnd = null;
                while (loopDate.getTime() < newToDate.getTime()) {
                    if (this.requestedDates.indexOf(loopDate.getTime()) === -1) {
                        if (!newStart) {
                            newStart = new Date(loopDate);
                        }
                        this.requestedDates.push(loopDate.getTime());
                        // This date should be included, to newEnd = today + 1
                        newEnd = new Date(loopDate);
                        newEnd.setDate(newEnd.getDate() + 1);
                    }
                    loopDate.setDate(loopDate.getDate() + 1);
                }
                this.websocket.doRequest(this.type, newStart || newFromDate, newEnd);
            }
            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public getList(): Observable<T[]> {
        return new Observable<T[]>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                observer.next(this.items);
            });

            if (this.requestedIds.has('list')) {
                if (this.items) {
                    observer.next(this.items);
                }
            } else {
                this.websocket.doRequest(this.type);
                this.requestedIds.set('list', true);
            }

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public getLatestItem(): Observable<T> {
        return new Observable<T>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe(() => {
                const item = this.items.length > 0 ? this.items[this.items.length - 1] : null;
                observer.next(item);
            });

            if (this.requestedIds.has('latest')) {
                if (this.items) {
                    const item = this.items.length > 0 ? this.items[this.items.length - 1] : null;
                    if (item) {
                        observer.next(item);
                    }
                }
            } else {
                this.websocket.doRequest(this.type);
                this.requestedIds.set('latest', true);
            }

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public getSingle(id: number | string, skipRequested = false): Observable<T> {
        return new Observable<T>((observer) => {
            const itemChangedSubscription = this.itemChanged.subscribe((ids) => {
                if (ids.indexOf(id) !== -1) {
                    const item = this.items.find(p => p[this.identifier] === id);
                    observer.next(item);
                }
            });

            let item = null;
            if (this.items) {
                item = this.items.find(p => p[this.identifier] === id);
            }

            if (item) {
                observer.next(item);
            } else if (!this.requestedIds.has(id)) {
                this.websocket.doRequest(this.type, null, null, id);
                this.requestedIds.set(id, true);
            }

            return {
                unsubscribe(): void {
                    itemChangedSubscription.unsubscribe();
                }
            };
        });
    }

    public abstract itemInDaterange(planning: T, fromDate: Date, toDate: Date);

}

