import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {PlanningTextService} from '../../services/planning/planning-text.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-transport-planning-text',
    templateUrl: './transport-planning-text.component.html',
    styleUrls: ['./transport-planning-text.component.scss']

})
export class TransportPlanningTextComponent extends CodaltComponent implements OnInit, OnDestroy {

    fcText: FormControl;
    fcTextId: FormControl;
    private savingText: string;
    valueChangedTrigger = 0;


    constructor(private planningTextService: PlanningTextService) {
        super();
    }

    ngOnInit() {
        this.planningTextService.getFilteredList((new Date()), (new Date())).subscribe(planningText => {
            const latestText = planningText[planningText.length - 1];
            setTimeout(() => {
                if (!this.fcText) {
                    this.fcText = new FormControl(latestText.text);
                    this.fcTextId = new FormControl(latestText.id);
                    this.subscriptions.add(this.fcText.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
                        if (!this.savingText) {
                            this.savingText = this.fcText.value;
                            this.fcText.markAsPristine();
                            this.planningTextService.saveText(this.fcTextId.value, this.savingText as any).then((a) => {
                                this.savingText = null;
                            }, () => {
                                this.savingText = null;
                            });
                        }
                    }));
                } else {
                    if (!this.fcText.dirty) {
                        if ((this.fcText.value !== latestText.text) && (latestText.text !== this.savingText)) {
                            this.fcText.setValue(latestText.text, {emitEvent: false});
                            this.valueChangedTrigger++;
                        }
                        this.fcTextId.setValue(latestText.id);
                    }
                }
            });
        });
    }
}
