import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hire-people-week',
  templateUrl: './hire-people-week.component.html',
  styleUrls: ['./hire-people-week.component.scss']
})
export class HirePeopleWeekComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
