import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-settlement-send-all',
    templateUrl: './settlement-send-all.component.html',
    styleUrls: ['./settlement-send-all.component.scss']
})
export class SettlementSendAllComponent extends CodaltComponent implements OnInit {

    endDate: Date;

    constructor(private title: Title) {
        super();
        this.title.setTitle('Versturen' + environment.titleAppend);
    }

    ngOnInit(): void {
        this.endDate = new Date();
    }
}
