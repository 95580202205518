import {Component, OnInit} from '@angular/core';
import {Utils} from '../utils.class';
import {MatDialogRef} from '@angular/material/dialog';
import {combineLatest, Subscription} from 'rxjs';
import {EntitiesService} from '../services/entities/entities.service';
import {UserService} from '../services/user/user.service';
import {PlanningService} from '../services/planning/planning.service';
import {PlanningCutter} from '../classes/planningcutter.class';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {PlanningAsfaltteam} from '../classes/planningasfaltteam.class';
import {PlanningSet} from '../classes/planningset.class';
import {PlanningDumper} from '../classes/planningdumper.class';
import {Hiring} from '../classes/hiring';
import {Planning} from '../classes/planning.class';
import {User} from '../classes/user.class';

@Component({
    selector: 'app-hired-companies-dialog',
    templateUrl: './hired-companies-dialog.component.html',
    styleUrls: ['./hired-companies-dialog.component.scss']
})
export class HiredCompaniesDialogComponent implements OnInit {

    selectedWeek: WeekOption;

    weeks: WeekOption[] = [];
    today = new Date();

    hirePerCompany: {
        company: Hiring,
        hiring: {
            planning: Planning,
            performer: User;
            hired: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper)
        }[]
    }[];

    constructor(public dialogRef: MatDialogRef<HiredCompaniesDialogComponent>,
                private entitiesService: EntitiesService,
                private usersService: UserService,
                private planningService: PlanningService) {
    }

    ngOnInit(): void {
        const monday = new Date();

        // Calculate the monday
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        this.weeks.push({
            startDate: new Date(monday),
            endDate: new Date(sunday)
        });
        for (let i = 1; i < 7; i++) {
            monday.setDate(monday.getDate() + 7);
            sunday.setDate(sunday.getDate() + 7);
            this.weeks.push({
                startDate: new Date(monday),
                endDate: new Date(sunday)
            });
        }
    }

    showHire(week: WeekOption) {
        this.selectedWeek = week;
        const entities$ = this.entitiesService.getMap();
        const planning$ = this.planningService.getFilteredList(week.startDate, week.endDate);
        const users$ = this.usersService.getMap();
        const subs = new Subscription();
        subs.add(combineLatest(entities$, planning$, users$).subscribe(([entitiesMap, planningList, usersMap]) => {
            const hirePerCompany = new Map<number, {
                planning: Planning,
                hired: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper),
                performer: User
            }[]>();
            planningList
                .sort((a, b) => new Date(Utils.mainPlanning(a).begindate).getTime() - new Date(Utils.mainPlanning(b).begindate).getTime())
                .forEach(planning => {
                    const hired = planning.planning_has
                        .filter(p => !!p.hiring_id && !entitiesMap.get(p.entity_id).use_once)
                        .filter(a => Utils.getTimeOrNull(a.begindate) >= week.startDate.getTime() && Utils.getTimeOrNull(a.enddate) <= week.endDate.getTime());

                    hired.forEach(hire => {
                        hire.entity = entitiesMap.get(hire.entity_id);
                        hire.lowloader_entity = entitiesMap.get(hire.lowloader_entity_id);
                        let items = hirePerCompany.get(hire.hiring_id) || [];
                        items.push({
                            hired: hire,
                            planning: planning,
                            performer: usersMap.get(planning.performer)
                        });
                        hirePerCompany.set(hire.hiring_id, items);
                    });
                });
            this.hirePerCompany = [];
            hirePerCompany.forEach((value, key) => {
                this.hirePerCompany.push({
                    company: value[0].hired.hiring,
                    hiring: value
                });

            });
        }));
    }

    close() {
        this.dialogRef.close();
    }

    copyToClipboard(string) {
        Utils.copyToClipboard(string);
    }

}

interface WeekOption {
    startDate: Date;
    endDate: Date;
}
