<div class="d-flex align-items-center" mat-dialog-title>
    <div class="spacer title">Gebruiker {{title}}</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div *ngIf="form" class="row justify-content-center">
    <div class="col-12">
        <form [formGroup]="form">
            <mat-form-field class="w-100" id="name">
                <input formControlName="name" matInput name="name" placeholder="Naam" type="text">
                <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" id="email">
                <input formControlName="email" matInput name="email" placeholder="Email" type="email">
                <mat-error>Vul een correct e-mailadres in</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" id="group">
                <mat-select [multiple]="true" formControlName="groups" placeholder="Gebruikersgroepen">
                    <mat-option *ngFor="let group of groups" [value]="group.id">{{UserGroups.get(group.group)}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="mb-2 font-weight-bold">
                Afas medewerker:
            </div>
            <div *ngIf="employee">
                {{employee.birthName}}, {{employee.firstName}} ({{employee.functionDesc}})
            </div>
            <div *ngIf="!employee">
                <i>Niet gekoppeld aan Afas. Gebruiker aanmaken? Toevoegen in Afas!</i>
            </div>
            <div>
                <div class="mb-2 font-weight-bold">
                    Standaard start- en eindlocatie:
                </div>
                <mat-radio-group formControlName="default_stand">
                    <mat-radio-button color="primary" [value]="DefaultStand.Home" class="mr-2">Thuis</mat-radio-button>
                    <mat-radio-button color="primary" [value]="DefaultStand.Floridadreef" class="mr-2">Floridadreef</mat-radio-button>
                </mat-radio-group>
                <div class="mb-2">
                    <small>Deze instelling bepaald enkel de standaardlocatie bij het inplannen via transportplanning.</small>
                </div>
            </div>
            <div>
                <div class="mb-2 font-weight-bold">
                    Uitbetaling reistijd werkdagen:
                </div>
                <mat-radio-group formControlName="traveltime_workday">
                    <mat-radio-button color="primary" [value]="TraveltimeWorkday.Geen" class="mr-2">{{TraveltimeWorkday.Geen}}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="TraveltimeWorkday.Staffel" class="mr-2">{{TraveltimeWorkday.Staffel}}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="TraveltimeWorkday.Werkelijk" class="mr-2">{{TraveltimeWorkday.Werkelijk}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div>
                <div class="mb-2 font-weight-bold">
                    Uitbetaling reistijd weekend:
                </div>
                <mat-radio-group formControlName="traveltime_weekend">
                    <mat-radio-button color="primary" [value]="TraveltimeWeekend.Geen" class="mr-2">{{TraveltimeWeekend.Geen}}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="TraveltimeWeekend.Werkelijk">{{TraveltimeWeekend.Werkelijk}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div>
                <div class="mb-2 font-weight-bold">
                    Alleen overuren:
                </div>
                <mat-checkbox formControlName="overtime" color="primary">
                    Uren registreren als overuren
                </mat-checkbox>
            </div>
        </form>
    </div>
</div>
<div class="row" *ngIf="showPin">
    <div class="col-12">
        <button mat-raised-button color="primary" *ngIf="!newPin" (click)="getNewPin()">
            Nieuwe Pin maken
        </button>
        <div *ngIf="newPin">
            Pincode: {{newPin}}
        </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-12">
        <div class="d-flex">
            <button (click)="close()" mat-button>Annuleren</button>
            <div class="spacer"></div>
            <button (click)="save()" [disabled]="form.invalid || saving" color="primary" id="btn-save" mat-raised-button>
                Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </button>
        </div>
    </div>
    <div class="col-12 roles text-justify">
        <h3>Beschrijving gebruikersrollen</h3>
        <p>Een gebruiker kan meerdere rollen hebben. De meest uitgebreide rechten zijn van toepassing.</p>
        <b>Admin</b>
        <p>
            Een admin heeft toegang tot alle functionaliteiten en planningen.
        </p>
        <b>Materieelplanner</b>
        <p>
            De materieelplanner kan de planning vastzetten tot een specifieke datum door op die datum te klikken.
            Alleen de Asfaltco&ouml;rdinator en materieelplanner kunnen dan nog planningen in die periode corrigeren.
            Hij mag als enige materieel en gebruikers beheren.
        </p>
        <b>Projectleider</b>
        <p>
            Een projectleider mag planningen waarvan hij projectleider is aanpassen.
            Hij mag een planning ook aan een andere projectleider toewijzen.
            Daarna kan hij deze planning niet meer aanpassen.
        </p>
        <b>Uitvoerder en asfaltuitvoerder</b>
        <p>
            Een uitvoerder mag alleen planningen inzien, en kan niets aanpassen.
            Hij kan worden gekozen als Uitvoerder of Asfaltuitvoerder voor iedere planning.
            Ook kan hij de planning exporteren als PDF.
        </p>
        <b>Asfaltco&ouml;rdinator</b>
        <p>
            De asfaltco&ouml;rdinator mag alle planningen aanpassen, ook als deze al definitief zijn.
            Daarnaast kan hij asfaltsoorten importeren en verwijderen en bestellijsten voor de asfaltcentrales downloaden.
        </p>
        <b>Gebruiker</b>
        <p>
            Een gebruiker mag alleen planningen inzien, en kan niets aanpassen.
            Ook kan hij de planning exporteren als PDF.
        </p>
    </div>
</div>
