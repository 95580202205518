<div class="modal-inner">
    <h1>Selecteer een project</h1>
    <input>
    <div class="results">
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
        <div class="result">
            <strong>2107-001</strong> UTRECHT, Straat 001
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button color="primary" class="planning-button confirm">Toevoegen</button>
        <button mat-raised-button color="basic" class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
