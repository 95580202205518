<div class="schedule-navbar">
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content">
        <p class="large">{{date | date : 'd MMM'}}</p>
        <p class="small">{{date | date : 'EEEE'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="below-navbar container-fluid">
    <h2>
        Asfalt {{projectId}} - {{date | date : 'EEE dd MMMM yyyy'}}
    </h2>
    <div class="d-flex flex-wrap flex-md-nowrap">
        <button (click)="copyAsphalt()" color="primary" class="mb-2 mr-2" mat-raised-button [disabled]="loadingAsfalt">
            {{loadingAsfalt ? 'Laden...' : 'Overnemen planning'}}
        </button>
        <div class="flex-fill"></div>
        <button mat-raised-button color="accent" class="mr-2 mb-2" (click)="otherProjectThisDay()" *ngIf="otherProjectsThisDay?.length">
            Andere projecten
        </button>
        <button routerLink="/project-rapport/{{this.projectId}}/{{this.date | date : 'w' }}/{{this.date | date : 'yyyy' }}/false/false/false"
                class="mb-2"
                color="primary"
                mat-raised-button>
            Weekrapport openen
        </button>
    </div>
    <app-asphalt-used-list #asphaltUsedList [project]="project" [date]="date" [print]="print"></app-asphalt-used-list>

    <div [class.d-none]="!(asphaltUsedList?.projectUsedAsphaltList?.length > 0)">
        <h2>
            Leveranties
            <button (click)="calculateAdhesives()" color="primary" mat-raised-button>Bereken kleeflagen</button>
        </h2>
        <app-asphalt-used-list #asphaltAdhesiveList [project]="project" [date]="date" [isAdhesive]="true" [print]="print"></app-asphalt-used-list>
    </div>


    <ng-container *ngIf="false">
        <h2>Derden / overig</h2>
        <table class="table table-center table-add-row table-last-col w-100 demo">
            <tr>
                <th width="500">Post</th>
                <th>Aantal</th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td>Verkeersregelaar
                </td>
                <td class="number-input">8:00</td>
                <td class="number-input">uur</td>
                <td>
                    <button
                        class="delete-button"
                        color="warn"
                        mat-icon-button
                        matTooltip="Verwijder"
                        matTooltipClass="warn-background"
                        matTooltipPosition="right"
                        tabindex="-1">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <td>Stort teerhoudend asfalt

                </td>
                <td class="number-input">8:00</td>
                <td class="number-input">ton</td>
                <td>
                    <button
                        class="delete-button"
                        color="warn"
                        mat-icon-button
                        matTooltip="Verwijder"
                        matTooltipClass="warn-background"
                        matTooltipPosition="right"
                        tabindex="-1">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <td>Stort schoon asfalt 
                </td>
                <td class="number-input">8:00</td>
                <td class="number-input">ton</td>
                <td>
                    <button
                        class="delete-button"
                        color="warn"
                        mat-icon-button
                        matTooltip="Verwijder"
                        matTooltipClass="warn-background"
                        matTooltipPosition="right"
                        tabindex="-1">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>

            <tr>
                <td colspan="3">
                    <button class="w-100" color="primary" mat-button>
                        Toevoegen <i class="fas fa-plus"></i>
                    </button>
                </td>
                <td></td>
            </tr>
        </table>
    </ng-container>

    <h2>Opmerkingen {{projectId}} - {{date | date : 'EEE dd MMMM yyyy'}}</h2>
    <div class="tiny-content" *ngIf="form">
        <simple-tiny
            class="w-100 mb-3"
            height="600"
            [showMenuBar]="false"
            [content]="form.controls.comment">
        </simple-tiny>
        <div class="history">
            <h1 *ngIf="projectComments?.length === 0" class="pb-3">
                Er zijn deze week nog geen andere dagrapporten ingevoerd
            </h1>
            <h1 *ngIf="projectComments?.length > 0">
                Andere toelichtingen deze week:
            </h1>
            <div class="item" *ngFor="let comment of projectComments">
                <h1 class="mb-0">
                    {{comment.date | date : 'EEEE d MMMM yyyy'}}
                </h1>
                <div class="mb-3">
                    <small>{{comment?.user?.name ?? 'Opmerkingen'}}</small>
                </div>
                <div [innerHTML]="comment.comment">

                </div>
            </div>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button mat-raised-button color="accent" class="planning-button" routerLink="/check-hours/{{date | date : 'yyyy-MM-dd'}}">
                Urencontrole
            </button>
            <button mat-raised-button color="accent" class="planning-button" routerLink="/project-rapport/{{this.projectId}}/{{this.date | date : 'w' }}/{{this.date | date : 'yyyy' }}/false">
                Weekrapport
            </button>
            <button mat-raised-button color="primary" class="planning-button" (click)="save()" [disabled]="form?.invalid || saving">
                {{saving ? 'Aan het opslaan...' : saved ? 'Wijzigingen opgeslagen' : 'Opslaan'}}
            </button>
        </div>
    </div>
</div>


<ng-template #chooseProjectDialog>
    <div class="dialog-container">
        <h2 class="condensed dialog-title pb-1 d-flex justify-content-between align-items-center">
            Kies een project
        </h2>
        <div class="dialog-content">
            <div *ngIf="notOwnProject">
                Kies een project waarvoor je op deze dag uitvoeder bent.
            </div>
            <div class="projects-dialog pt-2 pb-2">
                <div *ngFor="let project of projectDialogProjects" (click)="projectDialog.close(project.afas_project_id)">
                    <b>{{project.afas_project_id}}</b> {{project.name}}
                </div>
                <div (click)="projectDialog.close(projectId)">
                    Huidige project <b>{{projectId}}</b>
                </div>
            </div>
        </div>
    </div>
</ng-template>
