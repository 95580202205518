import {environment} from '../environments/environment';

export class Settings {

    public static readonly BING_MAPS_API_KEY = 'AnmAfGIkRSxoUh0TMvW6z4hLEOkVLc5V4MNzFVg_-eM4HylbpB2UVjx7koWoN3B6';

    public static readonly unauthorizedAllowedUrls = [
        'wachtwoord-vergeten',
        'restore-password',
        'forgot-password',
        'invite',
        'login',
        'register',
        'asphalt-order-list'
    ];
    public static readonly pouring_off_defaults = ['Ja', 'Ja, TOK', 'Nee', 'Kleef leislag'];

    public static readonly DAYS_SUBTRACT_SUBPLANNING = 14;
    public static readonly ASPHALT_SUBSTRACT_HOURS = 2;
    public static readonly ASPHALT_INTERVAL_MINUTES = 30;
    public static readonly MAX_TIMESPAN_HOURS = 12;
    public static readonly DAY_WIDTH = 240;
    public static readonly DEFAULT_DURATION_TEAM = 8.5;
    public static readonly DEFAULT_DURATION_OTHER = 8.5;
    public static readonly DEFAULT_DURATION_SLEEP = 8;
    public static readonly DEFAULT_DURATION_BLOCK = 24;
    public static readonly DEFAULT_START = 7;
    public static readonly DEFAULT_START_NIGHT = 20.5;
    public static readonly HOUR_EDIT_ALLOWED_HOURS = 48;


    static get API_ENDPOINT() {
        return environment.apiEndpoint + '';
    }

    static get RESOURCES_ENDPOINT() {
        return environment.apiEndpoint + 'file/';
    }
}
