import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Toolbox} from '../classes/toolbox';
import {ToolboxRead} from '../classes/toolbox-read';

@Injectable({
    providedIn: 'root'
})
export class ToolboxService {

    constructor(private apiService: ApiService) {
    }

    getToolboxes() {
        return this.apiService.getCall$<Toolbox[]>('toolbox');
    }

    getToolbox(id: number) {
        return this.apiService.getCall$<Toolbox>(`toolbox/${id}`);
    }

    deleteToolbox(id: number) {
        return this.apiService.deleteCall$(`toolbox/${id}`);
    }

    saveToolbox(toolbox: Toolbox) {
        return this.apiService.postCall$<Toolbox>('toolbox', toolbox);
    }

    getCurrentToolbox() {
        return this.apiService.getCall$<Toolbox>('toolbox/current');
    }

    readToolbox(toolboxId) {
        return this.apiService.getCall$<ToolboxRead>(`toolbox/read/${toolboxId}`);
    }

    readBy(toolboxId) {
        return this.apiService.getCall$<ToolboxRead[]>(`toolbox/read-by/${toolboxId}`);
    }
}
