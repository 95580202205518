import {Pipe, PipeTransform} from '@angular/core';
import {Planning} from './classes/planning.class';
import {PlanningAsfaltteam} from './classes/planningasfaltteam.class';
import {PlanningCutter} from './classes/planningcutter.class';
import {PlanningHasEntity} from './classes/planning-has-entity.class';
import {Utils} from './utils.class';
import {PlanningSet} from './classes/planningset.class';
import {PlanningDumper} from './classes/planningdumper.class';

@Pipe({
    name: 'mainPlanning'
})
export class MainPlanningPipe implements PipeTransform {

    transform(planning: Planning): PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper {
        return Utils.mainPlanning(planning);
    }

}
