import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Leave} from '../classes/leave';
import {formatDate} from '@angular/common';
import {Utils} from '../utils.class';
import {Settings} from '../settings.class';
import {RealtimeService} from './realtime/realtime.service';
import {RealtimeType} from './realtime/realtime-type.enum';
import {WebsocketService} from './websocket/websocket.service';
import {Realisation} from '../classes/realisation';

@Injectable({
    providedIn: 'root'
})
export class LeaveService extends RealtimeService<Leave> {


    constructor(public apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.leave);
    }

    itemInDaterange(leave: Leave, fromDate: Date, toDate: Date) {
        return Utils.getTimeOrNull(leave.begindate) <= Utils.getTimeOrNull(toDate)
            && Utils.getTimeOrNull(leave.enddate) >= Utils.getTimeOrNull(fromDate);
    }

    getLeaves(history = false) {
        return this.apiService.getCall$<Leave[]>('leave', {history});
    }

    getLeave(id: number) {
        return this.apiService.getCall$<Leave>(`leave/${id}`);
    }

    getLeaveRealisations(id: number) {
        return this.apiService.getCall$<Realisation[]>(`leave/realisations/${id}`);
    }

    saveLeave(leave: Leave) {
        return this.apiService.postCall$<Leave>('leave', leave);
    }

    deleteLeave(id) {
        return this.apiService.deleteCall$<Leave>(`leave/${id}`);
    }

    generateLeaveDates(leave: Leave): { beginDate, endDate }[] {
        const begintime = new Date();
        if (!leave.begintime) {
            Utils.setTime(begintime, Settings.DEFAULT_START, 0, 0);
        } else {
            const time = leave.begintime.split(':');
            Utils.setTime(begintime, +time[0], +time[1], 0);
        }
        const endtime = new Date();
        if (!leave.endtime) {
            Utils.setTime(endtime, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
        } else {
            const time = leave.endtime.split(':');
            Utils.setTime(endtime, +time[0], +time[1], 0);
        }

        const dates = [];
        const loopDate = new Date(leave.begindate);
        const lastEndDate = new Date(leave.enddate);
        const firstWeek = +formatDate(loopDate, 'w', 'nl');
        const startTime = new Date(leave.begindate);
        Utils.setTime(startTime, begintime.getHours(), begintime.getMinutes());
        const endTime = new Date(leave.enddate);
        Utils.setTime(endTime, endtime.getHours(), endtime.getMinutes());

        const loopEndDate = new Date(leave.enddate);
        while (loopDate.getTime() < loopEndDate.getTime()) {

            let generateThisDay = !(leave.interval_week > 0);
            if (leave.interval_week > 0) {
                const week = +formatDate(loopDate, 'w', 'nl');
                const thisWeek = ((week - firstWeek) % leave.interval_week) === 0;
                const weekDayName = formatDate(loopDate, 'EEEE', 'en').toLowerCase();
                const thisDay = leave[`repeat_${weekDayName}`];
                generateThisDay = thisWeek && thisDay;
            }

            if (generateThisDay) {
                const beginDate = new Date(loopDate);
                if (beginDate.getTime() === Utils.getTimeOrNull(leave.begindate) && beginDate.getTime() > startTime.getTime()) {
                    Utils.setTime(beginDate, (new Date(leave.begindate)).getHours(), (new Date(leave.begindate)).getMinutes(), 0);
                } else {
                    Utils.setTime(beginDate, startTime.getHours(), startTime.getMinutes(), 0);
                }

                const endDate = new Date(loopDate);
                Utils.setTime(endDate, (new Date(leave.enddate)).getHours(), (new Date(leave.enddate)).getMinutes(), 0);

                if (endDate.getTime() !== lastEndDate.getTime() || lastEndDate.getTime() > endTime.getTime()) {
                    Utils.setTime(endDate, endTime.getHours(), endTime.getMinutes(), 0);
                }

                const realisation = {beginDate, endDate};
                dates.push(realisation);
            }
            loopDate.setDate(loopDate.getDate() + 1);
        }
        return dates;
    }

}
