import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {UserPlanning} from '../../classes/user-planning';
import {Entity} from '../../classes/entity.class';
import {Planning} from '../../classes/planning.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {RealtimeService} from '../realtime/realtime.service';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {Utils} from '../../utils.class';

@Injectable({
    providedIn: 'root'
})
export class UserPlanningService extends RealtimeService<UserPlanning> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.userPlanning, 'id');
    }

    saveUserPlanning(planningId: number, userPlanning: UserPlanning[]) {
        return this.apiService.postCall$('user-planning', {
            planningId,
            userPlanning
        });
    }

    itemInDaterange(planning: UserPlanning, fromDate: Date, toDate: Date) {
        return Utils.getTimeOrNull(planning.begindate) < Utils.getTimeOrNull(toDate)
            && Utils.getTimeOrNull(planning.enddate) > Utils.getTimeOrNull(fromDate);
    }

    saveUserPlanningProject(userPlanning: UserPlanning[], planningHas: PlanningHasEntity[]) {
        return this.apiService.postCall$('user-planning/project', {
            userPlanning,
            planningHas
        });
    }

    deleteUserPlanning(id: number) {
        return this.apiService.deleteCall$(`user-planning/${id}`);
    }

    checkAvailability(begindate, enddate, user_id, planning_id) {
        return this.apiService.postCall$<{
            begindate: Date,
            enddate: Date,
            afas_project_id?: string,
            entity?: Entity,
            planning?: Planning
        }[]>('user-planning/check', {
            begindate,
            enddate,
            user_id,
            planning_id
        });
    }

}
