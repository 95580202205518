import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {UserPlanning} from '../../classes/user-planning';
import {Leave} from '../../classes/leave';
import {Project} from '../../classes/project.class';
import {Planning} from '../../classes/planning.class';
import {RealisationService} from '../../services/realisation.service';
import {PlanningDetailDialogService} from '../../services/dialog/planning-detail-dialog.service';
import {User} from '../../classes/user.class';
import {PlanningService} from '../../services/planning/planning.service';
import {UserPlanningService} from '../../services/planning/user-planning.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {LeaveService} from '../../services/leave.service';

@Component({
    selector: 'app-employee-day-info-dialog',
    templateUrl: './employee-day-info-dialog.component.html',
    styleUrls: ['./employee-day-info-dialog.component.scss']
})
export class EmployeeDayInfoDialogComponent extends CodaltComponent implements OnInit {

    hourtypeIconMap = RealisationService.hourtypeIconMap;

    constructor(public dialogRef: MatDialogRef<EmployeeDayInfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    planningHas: PlanningHasEntity[],
                    userPlanning: UserPlanning[],
                    leave: Leave,
                    afasLeave: { icon: string; description: string; startDate: Date; endDate: Date; },
                    projects: Project[],
                    planningList: Planning[],
                    user: User,
                    day: Date
                },
                public planningDetailDialogService: PlanningDetailDialogService,
                private userPlanningService: UserPlanningService,
                private planningService: PlanningService,
                private leaveService: LeaveService,
                private confirmDialogService: ConfirmDialogService,) {
        super();
        data.planningHas.forEach(ph => {
            ph.planning = data.planningList.find(p => p.id === ph.planning_id);
            if (ph.planning) {
                ph.planning.project = data.projects.find(p => p.afas_project_id === ph.planning.afas_project_id);
            }
            ph.project = data.projects.find(p => p.afas_project_id === ph.afas_project_id);
        });
        data.userPlanning.forEach(up => {
            up.planning = data.planningList.find(p => p.id === up.planning_id);
            if (up.planning) {
                up.planning.project = data.projects.find(p => p.afas_project_id === up.planning.afas_project_id);
            }
            up.project = data.projects.find(p => p.afas_project_id === up.afas_project_id);
        });
    }

    ngOnInit(): void {
    }

    deleteUserPlanning(userPlanning: UserPlanning) {
        this.confirmDialogService.confirm(
            'Planning verwijderen',
            'Weet u zeker dat u deze planning wilt verwijderen?',
            'Verwijderen',
            'Annuleren').then(() => {
            this.subscriptions.add(this.userPlanningService.deleteUserPlanning(userPlanning.id).subscribe(() => {
                this.data.userPlanning.splice(this.data.userPlanning.indexOf(userPlanning), 1);
            }, () => {

            }));
        }, () => {

        });
    }

    deletePlanningHas(planningHas: PlanningHasEntity) {
        this.confirmDialogService.confirm(
            'Planning verwijderen',
            'Weet u zeker dat u deze planning wilt verwijderen?',
            'Verwijderen',
            'Annuleren').then(() => {
            this.planningService.deleteSingle(planningHas).then(has => {
                this.data.planningHas.splice(this.data.planningHas.indexOf(planningHas), 1);
            }, () => {

            });
        }, () => {

        });
    }

    deleteLeave(leave: Leave) {
        this.confirmDialogService.confirm(
            'Verlof verwijderen',
            'Weet u zeker dat u dit verlof wilt verwijderen?',
            'Verwijderen',
            'Annuleren').then(() => {
            if (leave?.common) {
                leave.excluded.push({user_id: this.data.user.id, date: this.data.day});
            } else {
                if (leave.users.find(u => u.id === this.data.user.id)) {
                    leave.users.splice(leave.users.findIndex(u => u.id === this.data.user.id), 1);
                }
            }
            leave.users = leave.users?.map(u => u.id) as any[];
            this.subscriptions.add(this.leaveService.saveLeave(leave).subscribe(() => {
                this.data.leave = null;
            }));
        }, () => {

        });
    }
}
