import {EventEmitter} from '@angular/core';
import {User} from './classes/user.class';

class StorageInterface {
    public static user: User;
}

export class LocalStorage implements StorageInterface {

    public static customerAreaChange: EventEmitter<any> = new EventEmitter();
    public static user: User;
    static version: { datetime: string };

    public static setUserToken(token) {
        localStorage.setItem('access_token', token);
    }

    public static setUser(user: User) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    public static getUser(): User {
        if (this.user) {
            return this.user;
        } else {
            if (localStorage.getItem('user') !== 'undefined') {
                this.user = JSON.parse(localStorage.getItem('user'));
                return JSON.parse(localStorage.getItem('user'));
            }
            return null;
        }
    }

    public static logoutUser() {
        delete this.user;
        localStorage.clear();
    }

    public static getUserToken() {
        return localStorage.getItem('access_token');
    }

    public static setLastActivity(date) {
        localStorage.setItem('lastActivity', date);
    }

    public static getLastActivity() {
        return localStorage.getItem('lastActivity');
    }

    public static set hourTypeFilter(functions: string[]) {
        localStorage.setItem('hourTypeFilter', functions.join(','));
    }

    public static get hourTypeFilter() {
        return localStorage.getItem('hourTypeFilter')?.split(',').map(p => p).filter(p => p?.length > 0);
    }

    public static set userFunctionFilter(functions: string[]) {
        localStorage.setItem('userFunctionFilter', functions.join(','));
    }

    public static get userFunctionFilter() {
        return localStorage.getItem('userFunctionFilter')?.split(',').map(p => p).filter(p => p?.length > 0);
    }

    public static set PlanningFilter(ids: number[]) {
        localStorage.setItem('planningFilter', ids.join(','));
    }

    public static get PlanningFilter() {
        return localStorage.getItem('planningFilter')?.split(',').map(p => +p);
    }

    public static set ShowInUse(show: boolean) {
        localStorage.setItem('showInUse', show ? '1' : '0');
    }

    public static get ShowInUse() {
        return !!+localStorage.getItem('showInUse');
    }

    public static set ShowComments(show: boolean) {
        localStorage.setItem('showComments', show ? '1' : '0');
    }

    public static get ShowComments() {
        return !!+localStorage.getItem('showComments');
    }

    public static set ShowSmallMaterials(show: boolean) {
        localStorage.setItem('showSmallMaterials', show ? '1' : '0');
    }

    public static get ShowSmallMaterials() {
        return !!+localStorage.getItem('showSmallMaterials');
    }

    public static saveSettings(settings: object) {
        localStorage.setItem('settings', JSON.stringify(settings));
    }

    public static getSettings() {
        const settings = JSON.parse(localStorage.getItem('settings'));
        return settings ? settings : {};
    }

    public static websocketRetryIncrement() {
        let cntr = +localStorage.getItem('websocketRetry');
        cntr = cntr ? +cntr : 1;
        cntr++;
        localStorage.setItem('websocketRetry', `${cntr}`);
        return cntr;
    }

    public static websocketResetRetry() {
        localStorage.setItem('websocketRetry', '0');
    }

    public static setExpireTimeToken(token) {
        localStorage.setItem('token_date', new Date().toISOString());
        localStorage.setItem('expire_access_token', token);
    }

    public static getExpireTimeToken() {
        return localStorage.getItem('expire_access_token');
    }

    public static getMail(): string {
        return (localStorage.getItem('mail') || '');
    }

    public static setMail(mail: string) {
        localStorage.setItem('mail', mail);
    }


    public static getTokenDate() {
        return localStorage.getItem('token_date');
    }

}
