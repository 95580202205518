import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {TinyInsertImageComponent} from './tiny-insert-image/tiny-insert-image.component';
import {LocalStorage} from '../storage.class';
import {Settings} from '../settings.class';

declare var tinymce: any;

@Component({
    selector: 'simple-tiny',
    template: `
        <div id="{{elementId}}{{propertyName}}"></div>`,
    styleUrls: [`simple-tiny.component.scss`]
})
export class SimpleTinyComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @HostBinding('class.ng-touched') ngTouched: boolean = false;
    @HostBinding('class.ng-invalid') ngInvalid: boolean = false;

    @Input() contentCSS;
    @Input() showMenuBar = true;
    @Input() elementId: String;
    @Input() content: any;
    @Input() height: number;
    @Input() propertyName = 'content';
    @Input() colorSetName = 'common';
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;
    private colorSet = {
        common: [
            '0f0064', 'blauw',
            '85D1E4', 'lichtblauw',
            '000000', 'zwart',
            '666666', 'grijs',
            'ffffff', 'wit']
    };

    subscriptions = new Subscription();

    private currentColorSet = this.colorSet.common;

    constructor(private matDialog: MatDialog,
                private renderer2: Renderer2) {
    }

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof FormControl) {


            const processedContent = [];
            const articleHtml = this.renderer2.createElement('article-content');
            articleHtml.innerHTML = editorcontent;

            articleHtml.childNodes.forEach((paragraph) => {
                let html = paragraph.outerHTML;
                if (html) {
                    html = html.replace(/\?access_token=["\S]*"/g, '"');
                    html = html.replace(/height="["\S]*"[ >]/g, '');
                }
                processedContent.push(html);
            });


            this.content.setValue(processedContent.join(''));
            setTimeout(() => {
                this.ngTouched = true;
                this.ngInvalid = this.content.invalid;
            });
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    private setEditorContent(content) {
        const processedContent = [];
        const articleHtml = this.renderer2.createElement('article-content');
        articleHtml.innerHTML = content;

        articleHtml.childNodes.forEach((paragraph) => {
            let html = paragraph.outerHTML;
            if (html) {
                const m = html.match(/src="["\S]*"[ >]/g);
                if (m && m[0].substring(5, 5 + Settings.RESOURCES_ENDPOINT.length) === Settings.RESOURCES_ENDPOINT) {
                    html = html.replace(m[0], m[0].replace(/"[ >]/g, `?access_token=${LocalStorage.getUserToken()}"`));
                }
            }
            processedContent.push(html);
        });

        this.editor.setContent(processedContent.join(''));
    }

    ngOnChanges(changes) {
        if (this.editor) {
            if (!this.content[this.propertyName]) {
                this.content[this.propertyName] = '';
            }
            let content = this.content[this.propertyName];
            if (this.content instanceof FormControl) {
                content = this.content.value || '';
                if (this.content.disabled) {
                    this.editor.mode.set('readonly');
                }

            }
            this.setEditorContent(content);
            this.editor.undoManager.clear();
        }
        if (this.content instanceof FormControl) {
            this.subscriptions.add(this.content.statusChanges.subscribe(() => {
                this.ngInvalid = this.content.invalid;
                this.ngTouched = this.content.touched;
            }));
        }
    }

    ngAfterViewInit() {
        this.setColorSet();
        setTimeout(() => {

            tinymce.init({
                promotion: false,
                selector: '#' + this.elementId + this.propertyName,
                base_url: '/tinymce',
                suffix: '.min',
                browser_spellcheck: true,
                contextmenu: false,
                language: 'nl',
                newline_behavior: 'invert',
                menubar: this.showMenuBar ? 'insert edit format table view' : '',
                menu: {
                    insert: {title: 'Invoegen', items: 'charmap | insertImage link media | tempalte hr charmap | quote | shy | clear | inbetweenad '},
                    edit: {title: 'Bewerken', items: 'undo redo | cut copy paste pastetext | selectall'},
                    format: {title: 'Stijl', items: ' bold italic underline | align | removeformat  | styleselect'},
                    table: {title: 'Tabel', items: 'inserttable tableprops | cell row column'},
                    view: {title: 'Weergeven', items: 'fullscreen code preview'}
                },
                plugins: ['link', 'media', 'table', 'lists', 'advlist', 'fullscreen', 'quickbars', 'charmap', 'code', 'autoresize'],
                autoresize_bottom_margin: 10,
                toolbar1: 'undo redo | bold italic | bullist numlist | insertImage',
                toolbar2: '',
                toolbar3: '',
                paste_as_text: true,
                paste_block_drop: true,
                color_map: this.currentColorSet,
                formats: {
                    h2accent: {block: 'h2', classes: 'color-accent'}
                },
                block_formats: 'Paragraaf=p; Kop 2=h2; Kop 2 rood=h2accent; Header 3=h3',
                quickbars_selection_toolbar: 'bold italic underline quicklink h2 h3 charmap forecolor',
                quickbars_insert_toolbar: 'insertImage',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                extended_valid_elements: 'iframe[src|title|frameborder|allowfullscreen]',
                content_css: this.contentCSS ? `/assets/tinycss/${this.contentCSS}` : null,
                style_formats: [
                    {title: 'Kop 1', block: 'h1'},
                    {title: 'Kop 2', block: 'h2'},
                    {title: 'Kop 3', block: 'h3'},
                    {title: 'Kleiner', inline: 'span', classes: 'textsmall'},
                    {title: 'Groter', inline: 'span', classes: 'textlarge'}
                ],
                relative_urls: false,
                remove_script_host: false,
                min_height: this.height ?? 200,
                init_instance_callback: editor => {
                    if (!this.content[this.propertyName]) {
                        this.content[this.propertyName] = '';
                    }

                    let content = this.content[this.propertyName];
                    if (this.content instanceof FormControl) {
                        content = this.content.value || '';
                        if (this.content.disabled) {
                            this.editor.mode.set('readonly');
                        }
                    }

                    this.setEditorContent(content);
                },
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                    });

                    editor.on('change', () => {
                        editor.save();
                        if (this.content instanceof FormControl) {
                            this.content.markAllAsTouched();
                        }
                        if (editor.getContent({format: 'html'}).trim().length !== this.content?.value?.length) {
                            this.content.markAsDirty();
                        }
                        this.updateview(editor);

                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                    editor.ui.registry.addButton('insertImage', {
                        tooltip: 'Afbeelding invoegen',
                        icon: 'image',
                        onAction: () => {
                            const dialogRef = this.matDialog.open(TinyInsertImageComponent, {
                                width: '450px',
                                hasBackdrop: true,
                                data: {}
                            });
                            const sub = dialogRef.afterClosed().subscribe(result => {
                                if (result) {
                                    editor.insertContent(result);
                                }
                                sub.unsubscribe();
                            });
                        }
                    });

                }
            });

        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.elementId += '' + (Math.round(Math.random() * 9999));
    }

    private setColorSet(): void {
        this.currentColorSet = this.colorSet[this.colorSetName];
    }

}
