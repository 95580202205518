<div class="modal-inner" *ngIf="comment">
    <h1>Aanpassen dagrapport {{comment.user?.name}}</h1>
    <h2>{{comment.date | date : 'EEEE dd MMMM yyyy'}} - {{comment.afas_project_id}}</h2>
    <div *ngIf="form" class="w-100 mb-3">
        <simple-tiny
            class="w-100 mb-3"
            height="300"
            [showMenuBar]="false"
            [content]="form.controls.comment">
        </simple-tiny>
    </div>
    <mat-error [control]="form.controls.comment"></mat-error>
    <div class="buttons">
        <button (click)="save()"
                mat-raised-button
                color="primary"
                class="planning-button confirm">
            Opslaan
        </button>
        <button mat-raised-button
                color="basic"
                class="planning-button cancel"
                mat-dialog-close
                (click)="dialogRef.close()">
            Annuleren
        </button>
    </div>
</div>
