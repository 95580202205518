import {formatDate} from '@angular/common';
import {Planning, PlanningSize} from './classes/planning.class';
import {PlanningCutter} from './classes/planningcutter.class';
import {PlanningHasEntity} from './classes/planning-has-entity.class';
import {PlanningAsfaltteam} from './classes/planningasfaltteam.class';
import {EntityType, EntityTypeCode} from './services/entities/entity-type.class';
import {EntityUnavailable} from './classes/entityunavailable.class';
import {DayTimeOptions} from './classes/day-time-options';
import {TimeOption} from './classes/time-option';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {PlanningAsphalt} from './classes/planningasphalt.class';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {PlanningSet} from './classes/planningset.class';
import {PlanningDumper} from './classes/planningdumper.class';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {isArray} from 'rxjs/internal-compatibility';
import {Realisation, RealisationHourtype} from './classes/realisation';
import {UserService, UserType} from './services/user/user.service';
import {LocalStorage} from './storage.class';

export class Utils {

    constructor() {

    }

    static pheSame(pheOne: PlanningHasEntity, pheTwo: PlanningHasEntity) {
        return pheOne && pheTwo &&
            (Utils.getTimeOrNull(pheOne.begindate) === Utils.getTimeOrNull(pheTwo.begindate)) &&
            (Utils.getTimeOrNull(pheOne.enddate) === Utils.getTimeOrNull(pheTwo.enddate)) &&
            (pheOne.entity_id === pheTwo.entity_id);
    }

    static isIOS() {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    }

    static getMonday(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    public static setDate(from, to): Date {
        if (!to) {
            to = new Date();
        } else {
            to = new Date(to);
        }
        to.setDate(from.getDate());
        to.setMonth(from.getMonth());
        to.setFullYear(from.getFullYear());
        return to;
    }

    public static formatDate(date: Date, format = 'yyyy-MM-dd', locale = 'nl') {
        return formatDate(date, format, locale);
    }

    public static setTimeByString(date: Date, time: string) {
        const splitedTime = time.split(':');
        date.setHours(+splitedTime[0]);
        date.setMinutes(+splitedTime[1]);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    public static setTime(date: Date, hours: number, minutes: number, seconds = 0) {
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        date.setMilliseconds(0);
        return date;
    }

    public static setTimeNewDate(date: Date, hours: number, minutes: number, seconds = 0) {
        const newDate = Utils.newDate(date);
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        newDate.setSeconds(seconds);
        newDate.setMilliseconds(0);
        return newDate;
    }

    public static minutes(value: number) {
        if (value && typeof value === 'number') {
            let calcVal = value;
            let remain = 0;
            if (value >= 1440) {
                remain = value - (value % 1440);
                calcVal = (value % 1440);
            }
            const date = Utils.setTime(new Date(), 0, 0).setMinutes(Math.abs(calcVal));
            const time = formatDate(date, 'H:mm', 'nl').split(':');
            return `${calcVal < 0 ? '-' : ''}${+time[0] + (remain / 60)}:${time[1]}`;
        }
        return '0:00';
    }

    public static createTimeRange(fromDate, toDate, interval): DayTimeOptions[] {
        const times: Date[] = [];
        const countDate = new Date(fromDate);
        while (countDate.getTime() <= toDate.getTime()) {
            times.push(new Date(countDate));
            countDate.setUTCMinutes(countDate.getUTCMinutes() + interval);
        }
        const dayOptionsList: DayTimeOptions[] = [];
        let dayOptions = new DayTimeOptions();
        times.forEach((time, i) => {
            if (!dayOptions.datetime || dayOptions.datetime.getDay() !== time.getDay() || i === times.length - 1) {
                if (dayOptions.datetime) {
                    dayOptionsList.push(dayOptions);
                }
                dayOptions = new DayTimeOptions();
                dayOptions.datetime = time;
            }
            dayOptions.options.push(new TimeOption(time));
        });
        return dayOptionsList;
    }

    public static entitytypeProperty(entitytypes: EntityType[], property: keyof EntityType) {
        if (!entitytypes || !isArray(entitytypes)) {
            return false;
        }
        return [...entitytypes.map(t => t[property])].filter(t => !!t).length > 0;
    }

    public static getWipetruckTrucks(allEntities: (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper)[], filterDate?: Date) {
        return allEntities.filter(e => e['trucks'] > 0 && (!filterDate || (Utils.getDateOrNull(e.begindate) === filterDate.getDate())))
            .reduce((partialSum, a) => partialSum + a['trucks'], 0) ?? 0;
    }

    public static getAsphaltTrucks(planning: Planning, filterDate?: Date) {
        const timesCountMap = new Map<number, number>();
        const timesTotalCountMap = new Map<number, number>();
        planning.asphalt_list?.filter(e => !filterDate || (Utils.getDateOrNull(e.time) === filterDate.getDate()))
            .sort((a, b) => Utils.getTimeOrNull(a.time) - Utils.getTimeOrNull(b.time)).forEach(asphalt => {
            const time = Utils.getTimeOrNull(asphalt.time);
            timesTotalCountMap.set(time, (timesTotalCountMap.get(time) || 0) + asphalt.trucks);
        });
        const maxOnAnyTime = Array.from(timesTotalCountMap.values()).sort((a, b) => b - a)[0] ?? 0;
        let trucksLeft = maxOnAnyTime;
        timesTotalCountMap.forEach((count, time) => {
            const trucksCount = (count - (maxOnAnyTime - trucksLeft));
            timesCountMap.set(time, Math.min(trucksLeft, trucksCount > 0 ? trucksCount : 0));
            const trucksToSubtract = (trucksLeft - (trucksCount > 0 ? trucksCount : 0));
            trucksLeft = trucksToSubtract > 0 ? trucksToSubtract : 0;
        });
        return {timesCountMap, maxOnAnyTime};
    }

    public static planningAllEntities(planning: Planning): (PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper)[] {
        return [
            ...[planning.planning_projectteam] || [],
            ...[planning.planning_preparationteam] || [],
            ...[planning.planning_asfaltteam] || [],
            ...planning.planning_sets || [],
            ...planning.planning_trucks || [],
            ...planning.planning_cutters || [],
            ...planning.planning_wipetrucks || [],
            ...planning.planning_dumpers || [],
            ...planning.planning_cranes || [],
            ...planning.planning_rollers || [],
            ...planning.planning_asphalt_others?.map(p => p as PlanningHasEntity) || []
        ].filter(p => !!p);
    }

    public static mainPlanning(planning: Planning): PlanningHasEntity {
        const planningItems = [...planning.planning_has || [], ...Utils.planningAllEntities(planning)];

        let mainPlanning = planningItems.find(item => {
            return item.entity_id === planning?.entity_id;
        }) as PlanningHasEntity;

        if (!mainPlanning) {
            mainPlanning = planningItems[0] as PlanningHasEntity;
        }

        return mainPlanning;
    }

    public static planningInDaterange(planning: Planning, fromDate: Date, toDate: Date) {
        const entities = [...planning.planning_has, ...Utils.planningAllEntities(planning)];
        const fromTime = fromDate.getTime();
        const toTime = toDate.getTime();

        return !!entities.find(entity => {
            return new Date(entity.begindate).getTime() < toTime && new Date(entity.enddate).getTime() >= fromTime;
        });
    }

    public static newDate(date: Date | number) {
        if (date) {
            return new Date(date);
        }
        return null;
    }

    public static addMinutes(date: Date | number, minutes: number, substract = false) {
        const dateNew = Utils.newDate(date);
        if (substract) {
            dateNew.setUTCMinutes(dateNew.getUTCMinutes() - minutes);
        } else {
            dateNew.setUTCMinutes(dateNew.getUTCMinutes() + minutes);
        }

        return dateNew;
    }

    public static isSubsequent(date: Date) {
        if (date) {
            return date.getUTCMinutes() % 15 !== 0;
        }
        return false;
    }

    public static subsequentOrigDate(date: Date) {
        if (date) {
            date.setUTCMinutes(date.getUTCMinutes() - (date.getUTCMinutes() % 15));
            return date;
        }
        return null;
    }

    public static matchesDate(dateOne: Date, dateTwo: Date) {
        return dateOne && dateTwo && this.formatDate(new Date(dateOne)) === this.formatDate(new Date(dateTwo));
    }

    public static realisationAfasProjectId(r: Realisation) {
        return r.afas_project_id ?? r.planning?.afas_project_id ?? r.user_planning?.afas_project_id ?? r.planning_has?.afas_project_id ?? null;
    }

    public static realisationSort(realisation: Realisation) {
        if (!realisation) {
            return null;
        }

        const hourTypeOrderMap = {
            [RealisationHourtype.travel_to]: 0,
            [RealisationHourtype.driving_to]: 1,
            [RealisationHourtype.worktime]: 2,
            [RealisationHourtype.driving_back]: 3,
            [RealisationHourtype.travel_back]: 4
        };

        return Utils.getTimeOrNull(realisation.begindate) + (hourTypeOrderMap[realisation.hourtype] ?? 0);
    }

    static isWorkingday(date: Date) {
        return !Utils.isHoliday(date) && date.getDay() !== 0 && date.getDay() !== 6;
    }

    static isHoliday(date: Date) {
        const holidays = [];
        const christmas = new Date(date);
        christmas.setMonth(11);
        christmas.setDate(25);
        holidays.push(this.formatDate(christmas));
        christmas.setDate(26);
        holidays.push(this.formatDate(christmas));

        const easter = this.easter(date.getFullYear());
        holidays.push(this.formatDate(easter)); // first day
        easter.setDate(easter.getDate() + 1);
        holidays.push(this.formatDate(easter)); // second day
        easter.setDate(easter.getDate() - 3);
        holidays.push(this.formatDate(easter)); // g friday

        const ascensionDay = this.easter(date.getFullYear());
        ascensionDay.setDate(ascensionDay.getDate() + 39);
        holidays.push(this.formatDate(ascensionDay));

        const pentecost = this.easter(date.getFullYear());
        pentecost.setDate(pentecost.getDate() + 49);
        holidays.push(this.formatDate(pentecost));
        pentecost.setDate(pentecost.getDate() + 1);
        holidays.push(this.formatDate(pentecost));

        const willyday = new Date(date);
        willyday.setMonth(3);
        willyday.setDate(27);
        holidays.push(this.formatDate(willyday));

        if (date.getFullYear() % 5 === 0) {
            const fday = new Date(date);
            fday.setMonth(4);
            fday.setDate(5);
            holidays.push(this.formatDate(fday));
        }

        const newYear = new Date(date);
        newYear.setMonth(0);
        newYear.setDate(1);
        holidays.push(this.formatDate(newYear));
        newYear.setFullYear(newYear.getFullYear() + 1);
        holidays.push(this.formatDate(newYear));

        return holidays.indexOf(this.formatDate(date)) !== -1;
    }

    private static easter(Y) {
        const C = Math.floor(Y / 100);
        const N = Y - 19 * Math.floor(Y / 19);
        const K = Math.floor((C - 17) / 25);
        let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
        I = I - 30 * Math.floor((I / 30));
        I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
        let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
        J = J - 7 * Math.floor(J / 7);
        const L = I - J;
        const M = 3 + Math.floor((L + 40) / 44);
        const D = L + 28 - 31 * Math.floor(M / 4);

        const padout = (number => {
            return (number < 10) ? '0' + number : number;
        });

        return new Date(Y + '-' + padout(M) + '-' + padout(D));
    }

    public static getTimeOrNull(date: Date) {
        if (date !== null && date !== undefined) {
            return (new Date(date)).getTime();
        }
        return null;
    }

    public static getDateOrNull(date: Date) {
        if (date !== null && date !== undefined) {
            return (new Date(date)).getDate();
        }
        return null;
    }

    public static getDeliveryTons(planning: Planning[], date: Date, startHour, endHour: number) {
        const beginDate = Utils.setTime(new Date(date), startHour, 0);
        const endDate = Utils.setTime(new Date(date), endHour, 0);
        if (startHour > endHour) {
            endDate.setDate(endDate.getDate() + 1);
        }

        let tons = 0;
        planning.filter(item => Utils.entitytypeProperty(item.entity?.entitytypes, 'is_team')).forEach(item => {
            const filteredItems = item.asphalt_list?.filter(asphalt => !!asphalt.time)
                .map(asphalt => {
                    asphalt.time = new Date(asphalt.time);
                    return asphalt;
                })
                .filter(asphalt => new Date(asphalt.time).getTime() <= endDate.getTime() && new Date(asphalt.time).getTime() >= beginDate.getTime());
            tons += filteredItems.map(asphalt => asphalt.tons).reduce((sum, current) => sum + current, 0);
        });
        return tons;
    }

    public static isNight(startDate, endDate) {
        let start = new Date(startDate);
        let end = new Date(endDate);
        return start.getHours() < 6 || start.getHours() > 17 || end.getHours() > 17 || end.getHours() < 6;
    }

    public static getReturnTonsForTime(planning: Planning[], date: Date, startHour, endHour: number) {
        const beginDate = Utils.setTime(new Date(date), startHour, 0);
        const endDate = Utils.setTime(new Date(date), endHour, 0);
        return Utils.getReturnTons(planning, beginDate, endDate);
    }

    public static getReturnTons(planning: Planning[], fromDate: Date, toDate: Date) {
        const tons = new Map<number, number>();

        planning.filter(p => !!p.planning_cutters).forEach(item => {
            const filteredItems = item.planning_cutters
                .filter(cutter => new Date(cutter.begindate).getTime() < toDate.getTime() && new Date(cutter.enddate).getTime() >= fromDate.getTime());
            filteredItems.forEach(cut => {
                let cutend = new Date(cut.enddate);
                let cutbeg = new Date(cut.begindate);
                const diffInHours = (cutend.getTime() - cutbeg.getTime()) / 36e5;
                const tonsPerHour = cut.tons / diffInHours;
                const loopDate = new Date(Math.max(cutbeg.getTime(), fromDate.getTime()));
                const loopEndTime = Math.min(cutend.getTime(), toDate.getTime());
                while (loopDate.getTime() < loopEndTime) {
                    if (tons.has(loopDate.getUTCHours())) {
                        tons.set(loopDate.getUTCHours(), tons.get(loopDate.getUTCHours()) + tonsPerHour);
                    } else {
                        tons.set(loopDate.getUTCHours(), tonsPerHour);
                    }
                    loopDate.setUTCHours(loopDate.getUTCHours() + 1);
                }
            });
        });

        const loopDate = new Date(fromDate);
        let totalTons = 0;
        while (loopDate.getTime() < toDate.getTime()) {
            if (tons.has(loopDate.getUTCHours())) {
                totalTons += tons.get(loopDate.getUTCHours());
            }
            loopDate.setUTCHours(loopDate.getUTCHours() + 1);
        }
        return totalTons;
    }

    public static getCountOfUsedTrucksForTime(planning: Planning[], date: Date, startHour, endHour: number) {
        const beginDate = Utils.setTime(new Date(date), startHour, 0);
        const endDate = Utils.setTime(new Date(date), endHour, 0);
        return Utils.getCountOfUsedTrucks(planning, beginDate, endDate);
    }

    public static getCountOfPlanningSizesForTime(plannings: Planning[], date: Date, startHour, endHour: number) {
        const beginDate = Utils.setTime(new Date(date), startHour, 0);
        const endDate = Utils.setTime(new Date(date), endHour, 0);
        if (startHour > endHour) {
            endDate.setDate(endDate.getDate() + 1);
        }
        const planning = plannings.filter(p => Utils.planningInDaterange(p, beginDate, endDate));
        const perSize = new Map<PlanningSize, number>();
        Object.entries(PlanningSize).forEach(size => {
            perSize.set(size[1], planning.filter(p => p.size === size[1]).length);
        });
        return perSize;
    }

    public static getCountOfUsedTrucks(planning: Planning[], beginDate: Date, endDate: Date, skipPlanningId?: number, appendPlanning?: Planning) {
        const startTime = beginDate.getTime();
        const endTime = endDate.getTime();
        const times = new Map<number, number>();

        let planningAsfaltteams = planning.filter(item => item.entity?.entitytypes?.map(e => e.id).includes(EntityTypeCode.AsfaltTeam))
            .filter(planningItem => planningItem.id !== skipPlanningId);
        if (appendPlanning) {
            planningAsfaltteams = planningAsfaltteams.concat(appendPlanning);
        }

        const planningCutters = planning.filter(item => item.entity?.entitytypes?.map(e => e.id).includes(EntityTypeCode.Cutter))
            .filter(planningItem => planningItem.id !== skipPlanningId);

        // First we take all trucks from the asphaltlist which will be booked from its own time untill the next asphaltlist item, or the end of the mainitem
        let allItems = [];
        planningAsfaltteams.forEach(item => {
            const main = Utils.planningAllEntities(item).find(p => p.entity_id === item.entity_id);
            if (main && item.asphalt_list) {
                let endTime = new Date(main.enddate);
                const reverseArray = (JSON.parse(JSON.stringify(item.asphalt_list)) as PlanningAsphalt[])
                    .filter(g => !!g.time)
                    .sort((a, b) => {
                        return (new Date(b.time)).getTime() - (new Date(a.time)).getTime();
                    });
                reverseArray.forEach(asphalt => {
                    if (endTime.getTime() === (new Date(asphalt.time).getTime())) {
                        endTime = allItems[allItems.length - 1]?.endDate;
                    }
                    allItems.push({
                        beginDate: new Date(asphalt.time),
                        endDate: new Date(endTime),
                        trucks: asphalt.trucks
                    });
                    endTime = new Date(asphalt.time);
                });
            }
        });

        // Then we add the trucks related to a cutter, which are booked the time of the cutter
        planningAsfaltteams.filter(p => !!p.planning_cutters).forEach(item => {
            item.planning_cutters.forEach(a => {
                allItems.push({
                    beginDate: new Date(a.begindate),
                    endDate: new Date(a.enddate),
                    trucks: a.trucks
                });
            });
        });

        // Finally we add the single booked cutters
        allItems = allItems.concat(planningCutters.map(item => {
            const main = Utils.planningAllEntities(item).find(p => p.entity_id === item.entity_id) as PlanningCutter;
            return {
                beginDate: new Date(main?.begindate),
                endDate: new Date(main?.enddate),
                trucks: main?.trucks
            };
        }));

        allItems.filter(a => a.beginDate.getTime() < endTime && a.endDate.getTime() > startTime).filter(a => !!a.trucks)
            .forEach(item => {
                // When an item start before our itemstarttime, we are not interested in that part of the truck reservation
                let loopDate = new Date(Math.max(item.beginDate.getTime(), startTime));
                let loopEndTime = Math.min(item.endDate.getTime(), endTime);

                while (loopDate.getTime() < loopEndTime) {
                    if (loopDate.getTime() >= item.beginDate.getTime() && loopDate.getTime() < item.endDate.getTime()) {
                        if (times.has(loopDate.getTime())) {
                            times.set(loopDate.getTime(), times.get(loopDate.getTime()) + item.trucks);
                        } else {
                            times.set(loopDate.getTime(), item.trucks);
                        }
                    }
                    loopDate.setUTCMinutes(loopDate.getUTCMinutes() + 30);
                }
            });
        const maxTrucksForTime = Array.from(times.values());
        if (maxTrucksForTime.length > 0) {
            return Math.max(...maxTrucksForTime);
        }
        return 0;
    }

    public static addTimeOption(newTimeOption: Date, asdf: DayTimeOptions[]) {
        asdf.forEach(a => {
            if (a.datetime.getDate() === newTimeOption.getDate()) {
                a.options.push(new TimeOption(newTimeOption));
            }
        });
        return asdf;
    }

    public static genTimesMapOneEntity(fromDate: Date,
                                       toDate: Date,
                                       entityPlanning: PlanningHasEntity,
                                       planningId: number,
                                       planningList: Planning[],
                                       unavailableList: EntityUnavailable[],
                                       isEndTime = false,
                                       otherSameEntityPlannings: PlanningHasEntity[] | PlanningCutter[] | PlanningSet[] | PlanningDumper[] = [],
                                       skipEntity = false): DayTimeOptions[] {
        if (!entityPlanning && !skipEntity) {
            return [];
        }
        const times: Date[] = [];
        const countDate = new Date(fromDate);
        while (countDate.getTime() <= toDate.getTime()) {
            times.push(new Date(countDate));
            countDate.setUTCMinutes(countDate.getUTCMinutes() + 30);
        }
        const allEntityPlannings: PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper[] = [];
        if (entityPlanning) {
            planningList.forEach(planning => {
                allEntityPlannings.push(...planning.planning_has.filter(e => !!e.entity_id && e.entity_id === entityPlanning.entity_id));
            });
        }

        let itemsToCheck: PlanningHasEntity[] = [];
        itemsToCheck.push(...allEntityPlannings.filter(ep => ep.entity_id === entityPlanning.entity_id && ep.id !== entityPlanning.id) as PlanningHasEntity[]);

        // When otherSameEntityPlannings is NULL ( not an empty array ) the call to this fn comes from an immediately saved screen
        // So we can just use the allEntityPlannings array
        if (otherSameEntityPlannings !== null) {
            itemsToCheck = itemsToCheck.filter(itc => itc.planning_id !== planningId);
            itemsToCheck.push(...otherSameEntityPlannings as PlanningHasEntity[]);
        }

        const dayOptionsList: DayTimeOptions[] = [];
        let dayOptions = new DayTimeOptions();
        let setNextToNotAvailable = false;
        times.forEach((time, i) => {
            if (!dayOptions.datetime || dayOptions.datetime.getDay() !== time.getDay() || i === times.length - 1) {
                if (dayOptions.datetime) {
                    dayOptionsList.push(dayOptions);
                }
                dayOptions = new DayTimeOptions();
                dayOptions.datetime = time;
            }
            const timeOption = new TimeOption(time);
            if (entityPlanning?.entity?.use_once) {

                itemsToCheck.forEach(ePlanning => {
                    if (isEndTime) {
                        if ((time.getTime() > new Date(ePlanning.begindate).getTime()
                            && time.getTime() <= new Date(ePlanning.enddate).getTime())) {
                            timeOption.notAvailable = true;
                            setNextToNotAvailable = true;
                        }
                    } else {
                        if ((time.getTime() >= new Date(ePlanning.begindate).getTime()
                            && time.getTime() < new Date(ePlanning.enddate).getTime())) {
                            timeOption.notAvailable = true;
                        }
                    }
                });
            }
            unavailableList.filter(ep => ep.entity_id === entityPlanning?.id).forEach(unavailable => {
                if ((time.getTime() > new Date(unavailable.begindate).getTime()
                    && time.getTime() < new Date(unavailable.enddate).getTime())) {
                    timeOption.notAvailable = true;
                    setNextToNotAvailable = isEndTime;
                }
            });

            if (entityPlanning?.entity?.enddate && time.getTime() >= new Date(entityPlanning.entity.enddate).getTime()) {
                timeOption.notAvailable = true;
            }

            if (entityPlanning.entity && time.getTime() < new Date(entityPlanning.entity.begindate).getTime()) {
                timeOption.notAvailable = true;
            }

            if (setNextToNotAvailable) {
                timeOption.notAvailable = true;
            }

            dayOptions.options.push(timeOption);
        });
        return dayOptionsList;
    }

    public static urlTitle(title: string) {
        return title.replace(/ /g, '-').replace(/[^a-zA-Z0-9\-]/g, '');
    }

    public static handleError(error, confirmDialogService: ConfirmDialogService) {
        if (typeof error['message'] !== 'undefined') {
            if (!Array.isArray(error['message'])) {
                error['message'] = [error['message']];
            }
            const errorList = error['message'].reduce((accumulator, message) => {
                return accumulator + `<li>${message}</li>`;
            }, '');
            confirmDialogService.confirm(
                'Fout bij het opslaan',
                `Het opslaan is mislukt om de volgende reden:<ul>${errorList}</ul>`,
                'Oké',
                null
            );
        } else {
            confirmDialogService.confirm(
                'Fout bij het opslaan',
                `Er is een onverwachte fout opgetreden bij het opslaan`,
                'Oké',
                null
            );
        }
    }

    static triggerValidationP(control: AbstractControl, source: AbstractControl[] = []): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if ((control as FormArray)?.controls?.length === 0) {
                resolve(true);
            }
            const sub = control.statusChanges.pipe(debounceTime(5)).pipe(distinctUntilChanged()).subscribe(status => {
                if (status === 'VALID') {
                    resolve(true);
                    sub.unsubscribe();
                }
                if (status === 'INVALID' || status === 'DISABLED') {
                    reject();
                    sub.unsubscribe();
                }
            });
            Utils.triggerValidation(control, source);
        });
    }

    static triggerValidation(control: AbstractControl, source: AbstractControl[] = []) {
        if (control instanceof FormGroup) {
            const group = (control as FormGroup);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else if (control instanceof FormArray) {
            const group = (control as FormArray);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else {
            control.markAllAsTouched();
            control.updateValueAndValidity({emitEvent: source.indexOf(control) === -1});
        }
    }

    static hourDiff(pheOne: PlanningHasEntity, pheTwo: PlanningHasEntity) {
        if (!pheOne.begindate || !pheOne.enddate || !pheTwo.begindate || !pheTwo.enddate) {
            return 999999;
        }
        let dateOneCheck = null;
        let dateTwoCheck = null;
        if (Utils.getTimeOrNull(pheOne.begindate) >= Utils.getTimeOrNull(pheTwo.enddate)) { // pheOne is AFTER pheTwo
            dateOneCheck = Utils.newDate(pheOne.begindate);
            dateTwoCheck = Utils.newDate(pheTwo.enddate);
        } else if (Utils.getTimeOrNull(pheOne.enddate) <= Utils.getTimeOrNull(pheTwo.begindate)) { // pheOne is BEFORE pheTwo
            dateOneCheck = Utils.newDate(pheTwo.begindate);
            dateTwoCheck = Utils.newDate(pheOne.enddate);
        } else { // pheOne and pheTwo OVERLAP
            return 0;
        }

        return (dateOneCheck.getTime() - dateTwoCheck.getTime()) / 1000 / 60 / 60;
    }

    static getWeek(date: Date) {
        var handleDate = new Date(date.getTime());
        handleDate.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        handleDate.setDate(handleDate.getDate() + 3 - (handleDate.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(handleDate.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((handleDate.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    static dutchDayOfWeek(date: Date) {
        if (!date) {
            return;
        }
        let dayOfWeek = date.getDay();
        if (dayOfWeek == 0) {
            dayOfWeek = 7;
        }
        return dayOfWeek;
    }

    static copyToClipboard(str) {
        function listener(e) {
            e.clipboardData.setData('text/html', str);
            e.clipboardData.setData('text/plain', str);
            e.preventDefault();
        }

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    };

    static doesNotMatchDriver(realisation: Realisation, realisations: Realisation[], check: 'begindate' | 'enddate'): boolean {
        if ([RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(realisation.hourtype)) {
            return false;
        }
        if (!realisation?.planning_has && !realisation.parent_realisation_id) {
            return false;
        }
        if (!realisation.entity_id) {
            return false;
        }

        const driverRealisation = realisations
            .filter(r =>
                (!!r.parent_realisation_id && (r.parent_realisation_id === realisation.parent_realisation_id)) ||
                (!!r.planning_has_entity_id && (r.planning_has_entity_id === realisation.planning_has_entity_id))
            )
            .find(r => r.hourtype === realisation.hourtype && !!r.user_id);

        if (!driverRealisation) {
            return false;
        }

        return Utils.getTimeOrNull(realisation[check]) !== Utils.getTimeOrNull(driverRealisation[check]);
    }

    static dateString(date: Date) {
        return Utils.newDate(date)?.toDateString();
    }

    static dateFormatEquals(dateOne: Date | number, dateTwo: Date | number, format: string) {
        return formatDate(Utils.newDate(dateOne), format, 'nl') === formatDate(Utils.newDate(dateTwo), format, 'nl');
    }

    static displayOnDate(ae: PlanningHasEntity, planning: Planning, currentDate: Date): boolean {
        const beginTime = Utils.getTimeOrNull(ae.begindate);
        const endTime = Utils.getTimeOrNull(ae.enddate);

        if ([EntityTypeCode.CutterTruck, EntityTypeCode.Wipetruck].indexOf(ae.entitytype_id) !== -1) {
            let visibleByCutter = false;
            let inTimerangeCutter = false;
            planning.planning_cutters.forEach(cutter => {
                const cutterBeginTime = Utils.getTimeOrNull(cutter.begindate);
                const cutterEndTime = Utils.getTimeOrNull(cutter.enddate);
                if (endTime >= cutterBeginTime && beginTime <= cutterEndTime) {
                    inTimerangeCutter = true;
                    visibleByCutter = visibleByCutter || (Utils.dateString(cutter.begindate) === Utils.dateString(currentDate));
                }
            });
            if (inTimerangeCutter) {
                return visibleByCutter;
            }
        }

        const mainPlanning = Utils.mainPlanning(planning);
        if (mainPlanning) {
            const mpBeginTime = Utils.getTimeOrNull(mainPlanning.begindate);
            const mpEndTime = Utils.getTimeOrNull(mainPlanning.enddate);
            if (endTime >= mpBeginTime && beginTime <= mpEndTime) {
                return Utils.dateString(mainPlanning.begindate) === Utils.dateString(currentDate);
            }
        }
        return Utils.dateString(ae.begindate) === Utils.dateString(currentDate);
    }

    static minuteDuration(dateOne: Date, dateTwo: Date) {
        return (Utils.getTimeOrNull(dateOne) - Utils.getTimeOrNull(dateTwo)) / 1000 / 60;
    }

    static getDateDate(date: Date) {
        if (!date) {
            return date;
        }
        return (new Date(date)).getDate();
    }

    static filterRealisations(values: Realisation[], functions: string[], hourtypes: string[], hideApproved: boolean) {
        let resultValues = values;
        if (hideApproved) {
            resultValues = resultValues.filter(v => {
                if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
                    return !v.approved || (v.comment_user_approved && !v.comment_user_approved_handled);
                }
                return !v.approved && !v.removed;
            });
            const entityTypesToShow = [430001, 430002, 430015, 430014];
            resultValues = resultValues.filter(r => !r.entity_id || !!r.planning_has?.hiring_id || entityTypesToShow.includes(r.planning_has?.entitytype_id));
        }
        if (hourtypes?.length) {
            resultValues = resultValues.filter(v => hourtypes.map(ht => ht.split('|')).flat().includes(v.hourtype));
        }

        if (functions?.length) {
            resultValues = resultValues.filter(p => {
                if (LocalStorage.getUser().id === p.user_id) {
                    return true;
                }
                return functions.indexOf(p?.user?.function ?? p?.planning_has?.driver_user?.function) !== -1 ||
                    // Always show when it's an entity without a driver with a function
                    (!!p?.entity_id && !p?.planning_has?.driver_user?.function)
            });
        }
        return resultValues;
    }

    public static isToday(date: Date) {
        const testDate = new Date(date);
        const today = new Date();
        return testDate.getDate() === today.getDate() &&
            testDate.getMonth() === today.getMonth() &&
            testDate.getFullYear() === today.getFullYear();
    }

    public static isPast(date: Date) {
        const today = Utils.getTimeOrNull(new Date());
        const testDate = new Date(date);
        return testDate.getTime() < today;
    }

    public static parentRealisations(realisation: Realisation, realisations: Realisation[]) {
        return realisations.filter(r => {
            const matchParent = !!r.parent_realisation_id && r.parent_realisation_id === realisation.id;
            const matchPlanningHas = !!r.planning_has_entity_id && r.planning_has_entity_id === realisation.planning_has_entity_id && r.id !== realisation.id;
            const matchHourtype = realisation.hourtype === r.hourtype;
            return (matchParent || matchPlanningHas) && matchHourtype;
        });
    }
}
