import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {MatDialog} from '@angular/material/dialog';
import {User} from '../classes/user.class';
import {bufferCount, concatMap} from 'rxjs/operators';
import {combineLatest, forkJoin, from, Observable, Subscription} from 'rxjs';
import {SettlementsService} from '../services/settlements.service';
import {Settlement} from '../classes/settlement.class';
import {Hourtype} from '../afas-classes/hourtype';
import {FormControl} from '@angular/forms';
import {EditSettlementDialogComponent} from './edit-settlement-dialog/edit-settlement-dialog.component';
import {Utils} from '../utils.class';

@Component({
    selector: 'app-settlements-send',
    templateUrl: './settlements-send.component.html',
    styleUrls: ['./settlements-send.component.scss']
})
export class SettlementsSendComponent extends CodaltComponent implements OnChanges, OnInit {

    @Input() hideDateSelection = false;
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() user: User;
    allSettlements: Settlement[];
    hourtypes: Hourtype[];
    fcHideSent = new FormControl(false);
    fcShowOnlyMissing = new FormControl(false);
    fcEndDate = new FormControl();
    itemsToSend = 0;
    sentCount = 0;
    correctCount = 0;
    settlementsSubscription$: Subscription;
    excludedHourCodes = [
        'UITV_MIN2'
    ];

    constructor(private dialog: MatDialog,
                private settlementsService: SettlementsService) {
        super();
    }

    ngOnInit() {
        this.fcEndDate.valueChanges.subscribe(endDate => {
            this.endDate = Utils.setTime(endDate, 23, 59);
            this.getData();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.fcEndDate.value) {
            this.fcEndDate.setValue(this.endDate);
        }
        this.getData();
    }

    private getData() {
        this.allSettlements = [];

        const settlements$ = this.user ? this.settlementsService.getUserSettlements(this.beginDate, this.endDate, this.user.id) : this.settlementsService.getSettlementsTill(this.endDate);
        const hourtypes$ = this.settlementsService.getHourtypes();
        this.subscriptions.add(combineLatest([settlements$, hourtypes$]).subscribe(([settlements, hourtypes]) => {
            this.hourtypes = hourtypes.data;
            settlements.data
                .forEach(settlement => {
                    settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                    settlement.missing_values = !(settlement.afas_employee_id ?? settlement.realisation?.user?.afas_employee_id ?? settlement.user?.afas_employee_id);
                });
            this.allSettlements = settlements.data
                .filter(s =>  !this.excludedHourCodes.includes(s.hourtype_code))
                .sort((a, b) => (a.bookdate + a.description + a.id).localeCompare(b.bookdate + b.description + b.id));
            this.calculateItemsToSend();
        }));
    }

    calculateItemsToSend() {
        this.itemsToSend = this.allSettlements.filter(s => !s.missing_values && !s.afas_ptrealization_id && s.minutes !== 0).length;
        this.sentCount = this.allSettlements.filter(s => !!s.afas_ptrealization_id).length;
        this.correctCount = this.allSettlements.filter(s => !s.missing_values).length;
    }

    editSettlement(settlement: Settlement, editOnly: 'project' | 'hourtype') {
        if ((settlement.afas_error || !settlement.send_to_afas) && !settlement.afas_ptrealization_id) {
            const ref = this.dialog.open(EditSettlementDialogComponent, {
                maxWidth: '500px',
                width: '100vw',
                maxHeight: '100%',
                disableClose: false,
                panelClass: 'comment-edit-dialog',
                data: {
                    settlement,
                    bookdate: this.beginDate,
                    editOnly
                }
            });
            ref.afterClosed().subscribe(() => {
                this.getData();
            });
        }
    }

    sendAllToAfas() {
        const allSett$ = [];
        this.settlementsSubscription$ = new Subscription();
        this.allSettlements.forEach(settlement => {
            if (!settlement.missing_values && !settlement.afas_ptrealization_id) {
                const myObservable = new Observable((observer) => {
                    settlement.afas_error = null;
                    settlement.afas_project_id = settlement.afas_project_id ?? settlement.realisation?.afas_project_id ?? settlement.realisation?.planning?.afas_project_id ?? settlement.realisation?.user_planning?.afas_project_id;
                    settlement.afas_employee_id = settlement.afas_employee_id ?? settlement.user?.afas_employee_id;
                    settlement.sent_to_afas = null;
                    settlement.send_to_afas = new Date();

                    this.settlementsSubscription$.add(this.settlementsService.sendSingle(settlement).subscribe(response => {
                        Object.assign(settlement, response.data);
                        this.calculateItemsToSend();
                        observer.complete();
                    }, () => {
                    }));
                });
                allSett$.push(myObservable);
            }
        });
        this.settlementsSubscription$.add(from(allSett$).pipe(bufferCount(2), concatMap(b => forkJoin(b))).subscribe());
    }

    cancelSendToAfas() {
        this.settlementsSubscription$.unsubscribe();
        this.settlementsSubscription$ = null;
        this.getData();
    }

    sendToAfas(settlement: Settlement) {
        settlement.afas_error = null;
        settlement.afas_project_id = settlement.afas_project_id ?? settlement.realisation?.afas_project_id ?? settlement.realisation?.planning?.afas_project_id ?? settlement.realisation?.user_planning?.afas_project_id;
        settlement.afas_employee_id = settlement.afas_employee_id ?? settlement.user?.afas_employee_id;
        settlement.sent_to_afas = null;
        settlement.send_to_afas = new Date();
        this.settlementsService.sendSingle(settlement).subscribe(response => {
            Object.assign(settlement, response.data);
            this.calculateItemsToSend();
        });
    }
}

