import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';

@Pipe({
    name: 'isToday'
})
export class IsTodayPipe implements PipeTransform {

    transform(date: Date): unknown {
        return Utils.isToday(date);
    }

}
