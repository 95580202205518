<div class="schedule-navbar">
    <div>
        <button mat-icon-button (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content">
        <p class="large">
            <ng-container *ngFor="let realisation of realisations; let last = last">
                {{realisation.planning?.afas_project_id ?? realisation.project?.afas_project_id ?? realisation.afas_project_id}} {{last ? '' : ' / '}}
            </ng-container>
            <ng-container *ngFor="let realisation of notWorkRealisations; let last = last">
                {{realisation.hourtype | hourtype}} {{last ? '' : ' / '}}
            </ng-container>
            <ng-container *ngIf="!realisations?.length && !notWorkRealisations?.length">
                niet ingepland
            </ng-container>
        </p>
        <p class="small">{{date | date : 'EEEE d MMMM yyyy'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()" [disabled]="nextDate >= maxDate">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="content details">
    <div class="head section bg-white" *ngIf="!realisations?.length && !notWorkRealisations?.length">
        <div class="container">
            <h1>
                {{date > maxDate ? 'nog niet beschikbaar' : 'niet ingepland'}}
            </h1>
            <div class="data">
                <div class="data-row">
                    <div class="data-cell">Datum</div>
                    <div class="data-cell strong">{{date | date : 'd MMMM yyyy'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="head section bg-white" *ngFor="let realisation of notWorkRealisations">
        <div class="container">
            <h1>
                {{realisation.hourtype | hourtype}}
            </h1>
            <div class="data">
                <div class="data-row">
                    <div class="data-cell">Datum</div>
                    <div class="data-cell strong">{{date | date : 'd MMMM yyyy'}}</div>
                </div>
                <div class="data-row">
                    <div class="data-cell">Tijd</div>
                    <div class="data-cell strong">{{realisation.begindate | date : 'HH:mm'}} - {{realisation.enddate | date : 'HH:mm'}}</div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let realisation of realisations">
        <div class="head section bg-white">
            <div class="container">
                <h1>
                    Project
                    <strong>{{realisation.planning?.afas_project_id ?? realisation.afas_project_id ?? realisation?.user_planning?.project?.afas_project_id}}</strong>
                </h1>
                <h2>{{realisation.project?.name ?? realisation.planning?.project?.name ?? realisation?.user_planning?.project?.name}}</h2>
                <div class="data">
                    <div class="data-row">
                        <div class="data-cell">Datum</div>
                        <div class="data-cell strong">{{date | date : 'd MMMM yyyy'}}</div>
                    </div>
                    <div class="data-row">
                        <div class="data-cell">Tijd</div>
                        <div class="data-cell strong">{{realisation.begindate | date : 'HH:mm'}} - {{realisation.enddate | date : 'HH:mm'}}</div>
                    </div>
                    <div class="data-row">
                        <div class="data-cell">Locatie</div>
                        <div
                            class="data-cell strong">{{realisation?.project?.location ?? realisation?.user_planning?.project?.location ?? realisation?.planning?.location ?? '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section bg-light" *ngIf="!realisation?.planning?.is_transport">
            <div class="container">
                <div class="data">
                    <div class="data-row">
                        <div class="data-cell">Projectleider</div>
                        <div
                            class="data-cell strong">{{realisation?.planning?.projectmanager_user?.name ?? realisation?.project?.projectmanager?.name ?? realisation?.user_planning?.project?.projectmanager?.name ?? '-'}}</div>
                    </div>
                    <div class="data-row">
                        <div class="data-cell">Uitvoerder</div>
                        <div
                            class="data-cell strong">{{realisation?.planning?.performer_user?.name ?? realisation?.project?.performer?.name ?? realisation?.user_planning?.project?.performer?.name ?? '-'}}</div>
                    </div>
                    <div class="data-row"
                         *ngIf="realisation?.planning?.asphalt_performer_user?.name || realisation?.project?.asphalt_performer?.name || realisation?.user_planning?.project?.asphalt_performer?.name">
                        <div class="data-cell">Asfalt uitvoerder</div>
                        <div
                            class="data-cell strong">{{realisation?.planning?.asphalt_performer_user?.name ?? realisation?.project?.asphalt_performer?.name ?? realisation?.user_planning?.project?.asphalt_performer?.name ?? '-'}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section bg-white" *ngIf="!realisation?.planning?.is_transport">
            <div class="container">
                <h2>Omschrijving project</h2>
                <div>{{realisation?.planning?.description ?? '-'}}</div>
            </div>
        </div>
        <div class="section bg-light" *ngIf="realisation.planning_has?.transport_destination">
            <div class="container">
                <h2>Locatie transport</h2>
                <ng-container *ngIf="realisation.planning_has.transport_origin !== 'none'">
                    Start: {{realisation.planning_has.transport_origin ?? '-' | replaceIf : 'next' : 'Zie vorige regel'}}<br>
                </ng-container>
                <span *ngIf="realisation.planning_has.transport_worksite?.length > 0">
                    Werk: {{realisation.planning_has.transport_worksite ?? '-'}}<br>
                </span>
                <ng-container *ngIf="realisation.planning_has.transport_destination !== 'none'">
                    Einde: {{realisation.planning_has.transport_destination ?? '-' | replaceIf : 'next' : 'Zie volgende regel'}}
                </ng-container>
            </div>
        </div>
        <div class="section bg-white" *ngIf="realisation?.planning_has?.comment?.length > 0">
            <div class="container">
                <h2>Omschrijving transportbeweging</h2>
                <h3>{{realisation.planning_has.entity?.name}}</h3>
                <div>{{realisation.planning_has.comment}}</div>
            </div>
        </div>

        <div class="section bg-light" *ngIf="realisation?.planning?.planning_has_all?.length > 1">
            <div class="container">
                <h2>Met wie werk ik?</h2>
                <p *ngIf="!showWorkWith">
                    Zichtbaar vanaf {{showWorkWithFrom | date : 'EEEE d MMMM yyyy'}}
                </p>
                <table *ngIf="showWorkWith">
                    <tr>
                        <th>
                            Naam <span class="d-sm-none"> & materieel</span>
                        </th>
                        <th class="d-none d-sm-block">
                            Materieel
                        </th>
                        <th>
                            Werktijd
                        </th>
                        <th class="d-none d-sm-block">
                            Uitvoerder
                        </th>
                    </tr>
                    <ng-container *ngFor="let planning of realisation?.planning?.planning_has_all">
                        <ng-container *ngIf="Utils.dateString(planning?.begindate) === dateString">
                            <tr *ngIf="planning?.driver_user?.name">
                                <td>
                                    {{planning?.driver_user?.name}}
                                    <div class="d-md-none">
                                        <i>{{planning?.entity?.name}}</i><br>
                                        <b>Uitvoerder:</b> {{planning.performer?.name ?? '-'}}
                                    </div>
                                </td>
                                <td class="d-none d-md-block">
                                    {{planning?.entity?.name}}
                                </td>
                                <td class="time">
                                    {{planning?.begindate | date : 'HH:mm'}} - {{planning?.enddate | date : 'HH:mm'}}
                                </td>
                                <td class="d-none d-md-block">
                                    {{planning.performer?.name ?? '-'}}
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let userPlanning of realisation?.planning?.user_planning">
                        <ng-container *ngIf="Utils.dateString(userPlanning?.begindate) === dateString">
                            <tr *ngIf="userPlanning?.user?.name">
                                <td>
                                    {{userPlanning?.user?.name}}
                                </td>
                                <td class="d-none d-md-block">

                                </td>
                                <td class="time">
                                    {{userPlanning?.begindate | date : 'HH:mm'}} - {{userPlanning?.enddate | date : 'HH:mm'}}
                                </td>
                                <td class="d-none d-md-block">
                                    {{realisation.planning.performer_user?.name ?? (realisation?.project || realisation?.user_planning?.project)?.performer?.name ?? '-'}}
                                    <ng-container *ngIf="realisation?.planning?.asphalt_performer_user">
                                        / {{realisation?.planning?.asphalt_performer_user.name}}
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
    </ng-container>
</div>
<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button mat-raised-button color="primary" routerLink="/weekschedule" class="planning-button">
                Terug
            </button>
            <button mat-raised-button color="accent"
                    *ngIf="date < maxDate && (UserType.HOUR_REGISTER | hasUserGroup)"
                    routerLink="/hours/{{date | date : 'yyyy-MM-dd'}}"
                    class="planning-button ml-2">
                Urenregistratie
            </button>
        </div>
    </div>
</div>
